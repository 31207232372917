import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query'
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from './contexts/AppContext';
import './index.css';

import {theme} from "./theme/theme";
import {ThemeProvider} from "@mui/material";

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <App/>
            </AppProvider>
        </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals(console.log);
