import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  Box,
  CircularProgress,
  Typography,
  Snackbar,
  Modal,
} from "@mui/material";

import moment from 'moment'

import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";
import Status from "../../components/Status/Status";

import {
  deleteTargets,
  getTarget,
  editTargets,
} from "../../services/DataService";

import style from "./styles.module.css";

import { Alert } from "../../utils/alert";

import SaveTargetModal from "./SaveTargetModal";
import {useTargetsAPI} from "../../hooks/useTargetsAPI";

const TargetsPage = () => {
  const {targetsData: {data, isLoading, isError}} = useTargetsAPI();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Storage created successfully",
  });

  const queryClient = useQueryClient();

  const Delete = useMutation(deleteTargets, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Targets deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const edit = useMutation(editTargets, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Target edited successfully");
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleChangeActive = (id, active) => {
    edit.mutate({ data: { active: !active }, id: id });
  };

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const handleOpenDeleteModal = (target) => {
    setDeleteId({ id: target._id, name: target.name });
    setShowDeleteModal(true);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const redirectIdTarget = async (target) => {
    const data = await getTarget(target._id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  const handleDeleteTargets = (target) => {
    handleCloseDeleteModal();
    Delete.mutate(deleteId.id);
  };

  const setRequestType = (type) => {
    if (type === "POST") {
      return style.requestType_post;
    } else if (type === "PUT") {
      return style.requestType_put;
    } else if (type === "PATCH") {
      return style.requestType_patch;
    } else {
      return null;
    }
  };
  const headers = [
    {
      lable: "Target",
      align: "left",
    },
    {
      lable: "Request Type",
      align: "left",
    },
    {
      lable: "Endpoint",
      align: "left",
    },
    {
      lable: "Status",
      align: "left",
    },
    {
      lable: "Created at",
      align: "left",
    },
    {
      lable: " ",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, target) => {},
      align: "left",
    },
    {
      accessor: (element) => (
        <Typography variant={'subtitle1'} className={setRequestType(element["requestType"])}>
          {element["requestType"]}
        </Typography>
      ),
      Cell: (event, target) => {},
      align: "left",
    },
    {
      accessor: (element) => element["endpoint"],
      Cell: (event, target) => {},
      align: "left",
    },
    {
      accessor: (element) => <Status status={element.active} />,
      Cell: (event, target) => {
        event.stopPropagation();
        handleChangeActive(target._id, target.active);
      },
      align: "left",
    },
    {
      accessor: (element) => moment(element.createdAt).format('YYYY-MM-DD HH:MM'),
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return (
    <>
      <Box>
        <Box sx={{ m: "20px", textAlign: "end" }}>
          <AddNewButton redirectAddNew={redirectAddNew} text={"Target"} />
        </Box>
        <Box className="wrapper-source-list" sx={{ padding: "24px" }}>
          <ListTable
            headers={headers}
            data={data}
            handleDelete={handleOpenDeleteModal}
            redirectId={redirectIdTarget}
            columns={columns}
          />
        </Box>
        <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
      <Modal
        open={showSaveModal}
        onClose={handleCloseSaveModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <SaveTargetModal
          handleCloseSaveModal={handleCloseSaveModal}
          defaultValues={defaultValues}
        />
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <DeleteModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDeleteTargets}
          text={"Target"}
          name={deleteId?.name}
        />
      </Modal>
    </>
  );
};

export default TargetsPage;
