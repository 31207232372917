import React from "react";
import { Button } from "@mui/material";

const CancelButton = ({ handleCloseSaveModal }) => {
  return (
    <Button
      onClick={handleCloseSaveModal}
      variant="import"
      size="large"
    >
      Back
    </Button>
  );
};
export default CancelButton;
