import React, {useEffect, useMemo, useRef, useState} from "react";
import {Box, CircularProgress, FormControl, MenuItem, Select, TextField, Typography,} from "@mui/material";
import {useMutation, useQueryClient} from "react-query";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TablePagination from "@mui/material/TablePagination";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import ListTable from "../../components/ListTable/ListTabel";
import {useAppState} from "../../contexts/AppContext";
import {getLogs} from "../../services/DataService";
import {convertTimestamp, timeZoneChanger} from "../../utils/constants";
import style from "./styles.module.css";

const WorkflowsLogs = ({workflows}) => {

    const yesterday = new Date();
    yesterday.setMinutes(yesterday.getMinutes() - 5);

    const lastTwoWeek = new Date();
    lastTwoWeek.setDate(lastTwoWeek.getDate() - 14);

    const [dateFrom, setDateFrom] = useState(yesterday);
    const [dateTo, setDateTo] = useState(new Date());
    const [selectedWorkflow, setSelectedWorkflow] = useState([" "]);
    const [logsData, setLogsData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [level, setLevel] = useState([" "]);
    const [state, setState] = useState([" "]);
    const firstRun = useRef(true);
    const firstTime = useRef(true);

    const {config} = useAppState();
    const levels = useMemo(() => config?.config?.log?.level, [config]);
    const states = useMemo(() => config?.config?.log?.state, [config]);

    const queryClient = useQueryClient();
    const getWorkflowLogs = useMutation(getLogs, {
        onSuccess: (data) => {
            setLogsData(data);
        },
        onError: (err) => {
            console.log(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });
    useEffect(() => {
        if (firstRun.current) {
            getWorkflowLogs.mutate({
                parentIds: selectedWorkflow[0] === " " ? [] : selectedWorkflow,
                entityType: "workflowStep",
                page: 1,
                limit: rowsPerPage,
                from: Math.floor(+dateFrom / 1000),
                to: Math.floor(+dateTo / 1000),
                filters: {
                    level: level.includes(" ") ? Object.entries(levels)?.map(([key, value]) => value.value) : level,
                    state: state.includes(" ") ? Object.entries(states)?.map(([key, value]) => value.value) : state,
                },
            });
            firstRun.current = false;
        }
        // eslint-disable-next-line
    }, []);
    const handleSearch = () => {
        getWorkflowLogs.mutate({
            parentIds: selectedWorkflow[0] === " " ? [] : selectedWorkflow,
            entityType: "workflowStep",
            page: 1,
            limit: rowsPerPage,
            from: Math.floor(+dateFrom / 1000),
            to: Math.floor(+dateTo / 1000),
            filters: {
                level: level.includes(" ") ? Object.entries(levels)?.map(([key, value]) => value.value) : level,
                state: state.includes(" ") ? Object.entries(states)?.map(([key, value]) => value.value) : state,
            },
        });
        setPage(0);
    };
    const handleChangeSelectedWorkflow = (event) => {
        if (selectedWorkflow.includes(" ") && event.target.value.length > 1 && event.target.value.includes(" ")) {
            setSelectedWorkflow(event.target.value.filter((e) => e !== " "));
        } else if (!selectedWorkflow.includes(" ") && event.target.value.includes(" ")) {
            setSelectedWorkflow([" "]);
        } else if (workflows.length === event.target.value.length) {
            setSelectedWorkflow([" "]);
        } else {
            setSelectedWorkflow(event.target.value)
        }
    };
    const handleChangeLevel = (event) => {
        if (level.includes(" ") && event.target.value.length > 1 && event.target.value.includes(" ")) {
            setLevel(event.target.value.filter((e) => e !== " "));
        } else if (!level.includes(" ") && event.target.value.includes(" ")) {
            setLevel([" "]);
        } else if (Object.entries(levels).length === event.target.value.length) {
            setLevel([" "]);
        } else {
            setLevel(event.target.value)
        }
    };
    const handleChangeState = (event) => {
        if (state.includes(" ") && event.target.value.length > 1 && event.target.value.includes(" ")) {
            setState(event.target.value.filter((e) => e !== " "));
        } else if (!state.includes(" ") && event.target.value.includes(" ")) {
            setState([" "]);
        } else if (Object.entries(states).length === event.target.value.length) {
            setState([" "]);
        } else {
            setState(event.target.value)
        }
    };
    const setRequestType = (level) => {
        if (level === "error") {
            return style.level_error;
        } else if (level === "warn") {
            return style.level_warn;
        } else {
            return style.level_info;
        }
    };
    const headers = [
        {
            lable: "Timestamp",
            align: "left",
            style: {
                width: "180px",
            },
            icon: true
        },
        {
            lable: "Level",
            align: "left",
            style: {
                width: "100px",
            },
        },
        {
            lable: "In/Out",
            align: "left",
            style: {
                width: "100px",
            },
        },
        {
            lable: "Message",
            align: "left",
        },
    ];
    const columns = [
        {
            accessor: (element) => convertTimestamp(element.time),
            Cell: (event, source) => {
            },
            align: "left",
            style: {
                padding: "10px 16px 10px 16px",
            },
        },
        {
            accessor: (element) => (
                <Typography className={setRequestType(element.level)}>
                    {element.level}
                </Typography>
            ),
            Cell: (event, source) => {
            },
            align: "left",
            style: {
                padding: "10px 16px 10px 16px",
            },
        },
        {
            accessor: (element) => element.state,
            Cell: (event, source) => {
            },
            align: "left",
            style: {
                padding: "10px 16px 10px 16px",
            },
        },
        {
            accessor: (element) => JSON.stringify(element.message),
            Cell: (event, source) => {
            },
            align: "left",
            style: {
                padding: "10px 16px 10px 16px",
            },
        },
    ];
    const handleChangePage = (event, newPage) => {
        getWorkflowLogs.mutate({
            parentIds: selectedWorkflow[0] === " " ? [] : selectedWorkflow,
            entityType: "workflowStep",
            page: newPage + 1,
            limit: rowsPerPage,
            from: Math.floor(+dateFrom / 1000),
            to: Math.floor(+dateTo / 1000),
            filters: {
                level: level.includes(" ") ? Object.entries(levels)?.map(([key, value]) => value.value) : level,
                state: state.includes(" ") ? Object.entries(states)?.map(([key, value]) => value.value) : state,
            },
        });
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        getWorkflowLogs.mutate({
            parentIds: selectedWorkflow[0] === " " ? [] : selectedWorkflow,
            page: 1,
            entityType: "workflowStep",
            limit: event.target.value,
            from: Math.floor(+dateFrom / 1000),
            to: Math.floor(+dateTo / 1000),
            filters: {
                level: level.includes(" ") ? Object.entries(levels)?.map(([key, value]) => value.value) : level,
                state: state.includes(" ") ? Object.entries(states)?.map(([key, value]) => value.value) : state,
            },
        });
        setPage(0);
    };

    if (logsData.length === 0) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                <Box className={style.create_source_item}>
                    <Typography variant={'subtitle2'}>Date From</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            placeholder="Date From"
                            value={firstTime.current ? timeZoneChanger(dateFrom) : dateFrom}
                            minDate={timeZoneChanger(lastTwoWeek)}
                            maxDate={timeZoneChanger(yesterday)}
                            onChange={(newValue) => {
                                setDateFrom(newValue);
                                firstTime.current = false;
                            }}
                            renderInput={(params) => (
                                <TextField  {...params} />
                            )}
                            disabled={page >= 1}
                        />
                    </LocalizationProvider>
                </Box>
                <Box className={style.create_source_item}>
                    <Typography variant={'subtitle2'}>Date To</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            placeholder="Date To"
                            value={firstTime.current ? timeZoneChanger(dateTo) : dateTo}
                            minDate={timeZoneChanger(lastTwoWeek)}
                            maxDate={timeZoneChanger(new Date())}
                            onChange={(newValue) => {
                                setDateTo(newValue);
                                firstTime.current = false;
                            }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            disabled={page >= 1}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    sx={{maxWidth: "200px", width: "100%"}}
                    className={style.create_source_item}
                >
                    <Typography variant={'subtitle2'}>Workflow</Typography>
                    <Box className={style.text}>
                        <FormControl fullWidth>
                            <Select
                                value={selectedWorkflow}
                                onChange={handleChangeSelectedWorkflow}
                                label="Selected Source"
                                multiple
                                disabled={page >= 1}
                            >
                                <MenuItem value={" "}>All Workflows</MenuItem>
                                {workflows.map((e) => {
                                    return (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    sx={{maxWidth: "100px", width: "100%"}}
                    className={style.create_source_item}
                >
                    <Typography variant={'subtitle2'}>Level</Typography>
                    <Box className={style.text}>
                        <FormControl fullWidth>
                            <Select
                                value={level}
                                onChange={handleChangeLevel}
                                label="Selected Source"
                                multiple
                                disabled={page >= 1}
                            >
                                <MenuItem value={" "}>All</MenuItem>
                                {levels &&
                                    Object.entries(levels)?.map(([key, value]) => (
                                        <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    sx={{maxWidth: "100px", width: "100%"}}
                    className={style.create_source_item}
                >
                    <Typography variant={'subtitle2'}>State</Typography>
                    <Box className={style.text}>
                        <FormControl fullWidth>
                            <Select
                                value={state}
                                onChange={handleChangeState}
                                label="Selected Source"
                                multiple
                                disabled={page >= 1}
                            >
                                <MenuItem value={" "}>All</MenuItem>
                                {states &&
                                    Object.entries(states)?.map(([key, value]) => (
                                        <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{padding: "16px 0 0 0"}}>
                    <SearchButton handleSearch={handleSearch}/>
                </Box>
            </Box>
            <ListTable headers={headers} data={logsData.logs} columns={columns}/>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={logsData.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};
export default WorkflowsLogs;
