import React, { useRef, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import CancelButton from "../../SaveModalButtons/CancelButton";
import SaveButton from "../../SaveModalButtons/SaveButton";
import style from "../../../pages/WorkflowsPage/styles.module.css";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSourcesAPI } from "../../../hooks/useSourcesAPI";


export const AddSourceModal = ({
  openModalAddSources,
  handleClickBack,
  copyToClipboard,
  saveSource,
  sourceName,
  setSourceName,
  apiKey,
  setApiKey
}) => {
  const firstRun = useRef(true);

  const { generateKey } = useSourcesAPI(setApiKey);

  useEffect(() => {
    if (firstRun.current && openModalAddSources === true) {
      generateKey.mutate();
      firstRun.current = false;
    }
    // eslint-disable-next-line
  }, [generateKey,openModalAddSources]);

  const validationStep = () => {
    return !(sourceName.length > 1);
  };
  const handleChangeName = (event) => {
    setSourceName(event.target.value);
  };
  const handleChangeApiKey = (event) => {
    setApiKey(event.target.value);
  };


  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h2"} className={style.modal_title}>
          Add Source
        </Typography>
        <Box className={style.create_source_item} sx={{ width: "100%" }}>
          <Typography variant={"subtitle2"}>Name</Typography>
          <Box>
            <TextField
              placeholder={"Enter name"}
              value={sourceName}
              onChange={handleChangeName}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box style={{marginTop: 10}}>
            <Typography variant={"subtitle2"}>API Key</Typography>
            <TextField
              InputLabelProps={{ shrink: false }}
              value={apiKey}
              fullWidth
              placeholder={"API Key"}
              variant="outlined"
              onChange={handleChangeApiKey}
              disabled={validationStep}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton
                    sx={{ paddingRight: 0 }}
                    onClick={() => copyToClipboard(apiKey)}
                    aria-label="copy"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                ),
              }}
            />

          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
          <CancelButton handleCloseSaveModal={handleClickBack} />
          <SaveButton handlePost={saveSource} validation={validationStep} />
        </Box>
      </Box>
    </>
  );
};
