import React, { useState, useEffect } from "react";
import style from "./styles.module.css";
import {
  Box,
  TableContainer,
  Paper,
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
} from "@mui/material";
import { formatDateTime, isISODate } from "../../utils/functions";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getMasterDatabaseByHash } from "../../services/DataService";
import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import { borderStyles, borderStylesLeft } from "../../theme/theme";

const priorityKeys = [
  "last_name",
  "first_name",
  "phone",
  "email",
  "last_email",
  "last_phone",
];

const exclude = [
  "id",
  "master_record_history",
  "created_at",
  "emails",
  "phones",
  "master_record_id",
];
const changedKey = {
  ssn: "ssn",
  email: "email",
  emails: "emails",
  phone: "phone",
  phones: "phones",
  last_phone: "last_phone",
  last_email: "last_email",
  own_car: "ownCar",
  last_name: "lastName",
  birth_date: "birthDate",
  first_name: "firstName",
  updated_at: "updated_at",
  created_at: "created_at",
  detection_column: "detection_column",
};

const formatValue = (value) => {
  return isISODate(value) ? formatDateTime(value) : value;
};

const MasterRecordsDetails = () => {
  const { hash } = useParams();
  const response = useQuery(["getMasterDatabaseByHash", hash], () =>
    getMasterDatabaseByHash(hash)
  );
  const dataRecord = response?.data && response?.data[0]?.master_record;
  const [namesById, setNamesById] = useState({});

  const {
    sourcesData: { data, isLoading, isError },
  } = useSourcesAPI();

  const remainingKeys = Object.keys(dataRecord || {}).filter(
    (key) => !priorityKeys.includes(key) && !exclude.includes(key)
  );

  const orderedKeys = [...priorityKeys, ...remainingKeys];

  useEffect(() => {
    if (data?.data) {
      const transformedData = data?.data.reduce((acc, item) => {
        acc[item._id] = item.name;
        return acc;
      }, {});

      setNamesById(transformedData);
    }
  }, [data?.data]);

  const sortedHistory =
    dataRecord && dataRecord.master_record_history
      ? [...dataRecord.master_record_history].sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB - dateA;
        })
      : [];


  const getMostRecentRecord = (dataRecord, key) => {
    if (dataRecord.master_record_history && dataRecord.master_record_history.length) {
      const sortedHistory = [...dataRecord.master_record_history].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
  
      const mappedKey = changedKey[key] || key;
  
      if (key === 'last_email') {
        for (const historyItem of sortedHistory) {
          if (historyItem.email) {
            return formatValue(historyItem.email);
          }
        }
        return formatValue(dataRecord.email);
      } else if (key === 'last_phone') {
        for (const historyItem of sortedHistory) {
          if (historyItem.phone) {
            return formatValue(historyItem.phone);
          }
        }
        return formatValue(dataRecord.phone);
      } else {
        for (const historyItem of sortedHistory) {
          if (historyItem.hasOwnProperty(mappedKey) && historyItem[mappedKey]) {
            return formatValue(historyItem[mappedKey]);
          }
          if (historyItem.data && historyItem.data.hasOwnProperty(mappedKey) && historyItem.data[mappedKey]) {
            return formatValue(historyItem.data[mappedKey]);
          }
        }
      }
    }
  
    return formatValue(dataRecord[key]);
  };
  

  if (isLoading || !dataRecord) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <h1>Something wrong</h1>;
  }

  return (
    <>
      {dataRecord && (
        <Box className={style.container_database_page}>
          <TableContainer
            variant="custom"
            sx={{ maxWidth: "max-content" }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell variant="master-records">Created</TableCell>
                  <TableCell
                    variant="master-records"
                    sx={
                      !dataRecord?.master_record_history.length !== 0
                        ? borderStyles
                        : borderStylesLeft
                    }
                  >
                    Full Recent Record
                  </TableCell>            
                  <TableCell variant="master-records">
                    {/* {formatDateTime(dataRecord?.updated_at)} */}
                    Original Record
                  </TableCell>

                  {sortedHistory.map((historyItem, index) => (
                    <TableCell variant="master-records" key={index}>
                      {formatDateTime(historyItem.updated_at)}
                    </TableCell>
                  ))}
                </TableRow>
                {dataRecord?.emails.length > 0 && (
                  <TableRow>
                    <TableCell variant="master-records">All Emails</TableCell>
                    <TableCell sx={borderStyles}>
                      {dataRecord?.emails.map((email) => (
                        <div key={email.id}>{email.email}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {dataRecord?.phones.length > 0 && (
                  <TableRow>
                    <TableCell variant="master-records" sx={{}}>All Phones</TableCell>
                    <TableCell sx={borderStyles}>
                      {dataRecord?.phones.map((phone) => (
                        <div key={phone.id}>{phone.phone}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {dataRecord && (
                  <TableRow>
                    <TableCell variant="master-records">Source</TableCell>

                    {/* source values */}
                    <TableCell

                      sx={
                        !sortedHistory.length !== 0
                          ? borderStyles
                          : borderStylesLeft
                      }
                      variant="master-records"
                    >
                      {namesById[dataRecord?.source_id]}
                    </TableCell>
                    <TableCell
                      variant="master-records"
                    >
                      {namesById[dataRecord?.source_id]}
                    </TableCell>

                    {/* source all*/}

                    {sortedHistory.map((historyItem, index) => (
                      <TableCell
                        variant="customStorage"
                        key={`source-id-${index}`}
                      >
                        {namesById[historyItem.source_id]
                          ? namesById[historyItem.source_id]
                          : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {orderedKeys.map((key) => {
                  if (dataRecord && dataRecord.hasOwnProperty(key)) {
                    const value = dataRecord[key];
                    return (
                      <TableRow key={key}>
                        <TableCell variant="master-records">
                          
                          {/* left-table name */}

                          {key.replace(/_/g, " ")}
                        </TableCell>
                        <TableCell
                          sx={
                            !sortedHistory.length !== 0
                              ? borderStyles
                              : borderStylesLeft
                          }
                        >
                            {/* values for FULL RECENT RECORD */}

                          {getMostRecentRecord(dataRecord, key)} 
                        </TableCell>
                        
                        <TableCell>{formatValue(value)}</TableCell>
                        {sortedHistory.map((historyItem, index) => {
                          const detectionType =
                            historyItem.detection_column?.toUpperCase();
                          const isGreen =
                            (detectionType === "EMAIL" && key === "email") ||
                            (detectionType === "PHONE" && key === "phone") ||
                            (detectionType === "SSN" && key === "ssn");

                          return (
                            <TableCell
                              style={
                                isGreen
                                  ? { color: "green", fontWeight: 600 }
                                  : {}
                              }
                              key={`history-${index}`}
                            >
                              {changedKey[key]
                                ? formatValue(historyItem[changedKey[key]]) ||
                                  formatValue(historyItem.data[changedKey[key]])
                                : ""}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default MasterRecordsDetails;
