import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  TextField,
  Typography,
  Switch,
  Select,
  MenuItem,
  FormControl,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useAppState } from "../../contexts/AppContext";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import ExplanationConfig from "../../components/Explanation/ExplanationConfig/ExplanationConfig";
import ExplanationEndpoint from "../../components/Explanation/ExplanationConfig/ExplanationEndpoint";
import ExplanationIcon from "../../assets/ExplanationIcon.svg";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useTargetsAPI } from "../../hooks/useTargetsAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const TargetConfigurationTypes = {
  array: "array",
  object: "object",
  string: "string",
  segma: "segma",
};

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const SaveTargetModal = ({ handleCloseSaveModal, defaultValues }) => {
  const [name, setName] = useState("");
  const [requestType, setRequestType] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [authHeaderName, setAuthHeaderName] = useState("");
  const [authHeaderValue, setAuthHeaderValue] = useState("");
  const [configuration, setConfiguration] = useState("");
  const [paramName, setParamName] = useState(null);
  const [batchSize, setBatchSize] = useState(null);
  const [checked, setChecked] = useState(true);
  const [configurationType, setConfigurationType] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const { config } = useAppState();
  const types = useMemo(() => config?.config?.target?.requestType, [config]);
  const configTypes = useMemo(
    () => config?.config?.target?.requestConfigType,
    [config]
  );

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setRequestType(defaultValues.requestType);
      setEndpoint(defaultValues.endpoint);
      setConfiguration(JSON.stringify(defaultValues.config));
      setChecked(defaultValues.active);
      setConfigurationType(defaultValues?.requestConfig?.type);
      setBatchSize(
        defaultValues?.requestConfig?.batchSize
          ? `${defaultValues.requestConfig.batchSize}`
          : ""
      );
      setParamName(
        defaultValues?.requestConfig?.topLevelKey
          ? `${defaultValues.requestConfig.topLevelKey}`
          : ""
      );
      setAuthHeaderName(
        defaultValues?.requestConfig?.authHeaderName
          ? `${defaultValues.requestConfig.authHeaderName}`
          : ""
      );
      setAuthHeaderValue(
        defaultValues?.requestConfig?.authHeaderValue
          ? `${defaultValues.requestConfig.authHeaderValue}`
          : ""
      );
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  // todo: improve
  const requiredFields = [name, requestType, endpoint];
  if (configurationType !== TargetConfigurationTypes.string)
    requiredFields.push(configuration);
  const validation = () => !requiredFields.every((i) => i);

  const EditValidation = () => {
    if (
      defaultValues.name === name &&
      defaultValues.requestType === requestType &&
      defaultValues.endpoint === endpoint &&
      JSON.stringify(defaultValues.config) === configuration &&
      defaultValues.active === checked &&
      defaultValues?.requestConfig?.type === configurationType &&
      defaultValues?.requestConfig?.batchSize === +batchSize &&
      defaultValues?.requestConfig?.topLevelKey === paramName
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeRequestType = (event) => {
    setRequestType(event.target.value);
  };

  const handleChangeEndpoint = (event) => {
    setEndpoint(event.target.value);
  };

  const handleChangeConfiguration = (event) => {
    setConfiguration(event.target.value);
  };

  const handleChangeParamName = (event) => {
    setParamName(event.target.value);
  };

  const handleChangeAuthHeaderName = (event) => {
    setAuthHeaderName(event.target.value);
  };

  const handleChangeAuthHeaderValue = (event) => {
    setAuthHeaderValue(event.target.value);
  };

  const handleChangeBatchSize = (event) => {
    setBatchSize(event.target.value);
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeConfigurationType = (event) => {
    setConfigurationType(event.target.value);
  };

  const { postTarget, editTarget } = useTargetsAPI(handleCloseSaveModal);

  const handlePostTarget = () => {
    let Configcopy;
    try {
      if (configurationType !== TargetConfigurationTypes.string) {
        Configcopy = JSON.parse(configuration);
      }
      postTarget.mutate({
        name: name,
        active: checked,
        requestType: requestType,
        endpoint: endpoint,
        config: configurationType !== "string" ? Configcopy : null,
        requestConfig: configurationType
          ? {
              topLevelKey:
                configurationType === "array" || configurationType === "segma"
                  ? paramName
                  : null,
              batchSize:
                configurationType === "array" || configurationType === "segma"
                  ? +batchSize
                  : null,
              type: configurationType,
              authHeaderName: authHeaderName,
              authHeaderValue: authHeaderValue,
            }
          : null,
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };
  const handleEditTarget = () => {
    let Configcopy;
    try {
      Configcopy = JSON.parse(configuration);
      editTarget.mutate({
        data: {
          name: name,
          active: checked,
          requestType: requestType,
          endpoint: endpoint,
          config: Configcopy,
          requestConfig: configurationType
            ? {
                topLevelKey:
                  configurationType === "array" ||
                  configurationType === "object" ||
                  configurationType === "segma"
                    ? paramName
                    : null,
                batchSize:
                  configurationType === "array" || configurationType === "segma"
                    ? +batchSize
                    : null,
                type: configurationType,
                authHeaderName: authHeaderName,
                authHeaderValue: authHeaderValue,
              }
            : null,
        },
        id: defaultValues._id,
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };

  useEffect(() => {
    if (configurationType === "segma") {
      setParamName("leads");
    }
  }, [configurationType]);

  return (
    <>
      <Box className={style.modal}>
        <Box>
          <Typography variant={"h1"} className={style.title}>
            {defaultValues ? "Edit" : "Create"} Target
          </Typography>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Name</Typography>
            <TextField
              placeholder={"Enter name"}
              value={name}
              onChange={handleChangeName}
              variant="outlined"
            />
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Request Type</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={requestType}
                  onChange={handleChangeRequestType}
                  label="requestType"
                  displayEmpty
                  renderValue={
                    requestType !== ""
                      ? undefined
                      : () => <Placeholder>Select request type</Placeholder>
                  }
                >
                  {types &&
                    Object.entries(types)?.map(([key, value]) => (
                      <MenuItem value={value.value}>{value.label}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box className={style.create_source_item}>
            <Box sx={{ display: "flex" }}>
              <Typography variant={"subtitle2"}>Target endpoint</Typography>
              <Tooltip
                title={<ExplanationEndpoint />}
                arrow
                placement="top-start"
              >
                <IconButton sx={{ padding: "4px 8px 8px 8px" }}>
                  <img src={ExplanationIcon} alt="" />
                </IconButton>
              </Tooltip>
            </Box>
            <TextField
              placeholder={"Enter target endpoint"}
              value={endpoint}
              onChange={handleChangeEndpoint}
              variant="outlined"
            />
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Configuration Type</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={configurationType}
                  onChange={handleChangeConfigurationType}
                  label="Configuration Type"
                  displayEmpty
                  renderValue={
                    configurationType !== "" && configurationType !== null
                      ? undefined
                      : () => (
                          <Placeholder>Select configuration Type</Placeholder>
                        )
                  }
                >
                  {configTypes &&
                    Object.entries(configTypes)?.map(([key, value]) => (
                      <MenuItem value={value.value}>{value.label}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>
              Authentication Header Name
            </Typography>
            <TextField
              placeholder={"Enter Header Name"}
              value={authHeaderName}
              onChange={handleChangeAuthHeaderName}
              variant="outlined"
            />
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>
              Authentication Header Value
            </Typography>
            <TextField
              placeholder={"Enter Header Value"}
              value={authHeaderValue}
              onChange={handleChangeAuthHeaderValue}
              variant="outlined"
            />
          </Box>
          {(configurationType === "array" ||
            configurationType === "object" ||
            configurationType === "segma") && (
            <Box className={style.create_source_item}>
              <Box sx={{ display: "flex" }}>
                <Typography variant={"subtitle2"}>
                  Request body configuration
                </Typography>
                <Tooltip
                  title={
                    <ExplanationConfig handleOpenAlert={handleOpenAlert} />
                  }
                  arrow
                  placement="top-start"
                >
                  <IconButton sx={{ padding: "4px 8px 8px 8px" }}>
                    <img src={ExplanationIcon} alt="" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField
                placeholder={"Enter request body"}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingTop: "100px",
                    borderRadius: "8px",
                    paddingBottom: "80px",
                  },
                }}
                multiline
                rows={7}
                value={configuration}
                onChange={handleChangeConfiguration}
                variant="outlined"
              />
            </Box>
          )}
          {(configurationType === "array" || configurationType === "segma") && (
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>
                Array parameter name
              </Typography>
              <TextField
                placeholder={"Enter array parameter name"}
                value={paramName}
                onChange={handleChangeParamName}
                variant="outlined"
              />
            </Box>
          )}
          {(configurationType === "array" || configurationType === "segma") && (
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Batch Size</Typography>
              <TextField
                placeholder={"Enter batch size"}
                value={batchSize}
                onChange={handleChangeBatchSize}
                variant="outlined"
              />
            </Box>
          )}
          <Box className={style.create_source_item}>
            <Box className={style.switch}>
              <Switch checked={checked} onChange={handleChangeChecked} />
              <Typography sx={{ margin: "-2px 0 0 10px" }}>Active</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
            <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
            {defaultValues ? (
              <EditButton
                handlePost={handleEditTarget}
                validation={EditValidation}
              />
            ) : (
              <SaveButton
                handlePost={handlePostTarget}
                validation={validation}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveTargetModal;
