import React from 'react';
import {Box, Typography} from "@mui/material";
import style from "./styleConfig.module.css";

const ExplanationTimezone = () => {
    return (
        <>
            <Box className={style.container}>
                <Typography variant={'subtitle1'} className={style.text}>
                    PST Timezone
                </Typography>
            </Box>
        </>
    );
};

export default ExplanationTimezone;
