import React from "react";
import { Button } from "@mui/material";

const CancelButton = ({ handleCloseSaveModal, disabled = false, name="Cancel"}) => {
  return (
    <Button
      onClick={handleCloseSaveModal}
      variant="cancel"
      size="large"
      disabled={disabled}
    >
      {name}
    </Button>
  );
};
export default CancelButton;
