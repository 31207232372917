import React from "react";
import { sortBy } from 'lodash'
import { Box } from "@mui/material";
import ModulesPageCardItem from './ModulesPageItem';
import ModulesPageErrorItem from './ModulesPageErrorItem';
import ModulesPageLoadingItem from './ModulesPageLoadingItem';
import style from './style.module.css';
import {useStaticModulesAPI} from "../../hooks/useStaticModulesAPI";

const StaticModules = () => {
  const {staticModulesData: {data, isLoading, isError}} = useStaticModulesAPI()

    if (isLoading) {
      return (
        <ModulesPageLoadingItem />
      )
    }
    if (isError) {
      return (
        <ModulesPageErrorItem />
      )
    }

    return (
      <Box className={style.modules_page_container}>
        {sortBy(data.result, 'label').map((module) => {
          return (
              <ModulesPageCardItem module={module}/>
          );
        })}
      </Box>
    );
};

export default StaticModules;
