import React from "react";
import { Button } from "@mui/material";

const AddNewButton = ({ redirectAddNew, text }) => {
  return (
    <Button onClick={redirectAddNew} variant="addNew" size="large">
      Add {text}
    </Button>
  );
};
export default AddNewButton;
