import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppState } from "../contexts/AppContext";
import { useLogout } from "./useLogout";
import {
  deleteActions,
  editActions,
  getActions,
  postActions,
} from "../services/DataService";

export function useActionsAPI(handleCloseSaveModal) {
  const { handleOpenAlert } = useAppState();
  const { logout } = useLogout();
  const queryClient = useQueryClient();

  const actionData = useQuery(["getActions"], getActions);

  const deleteAction = useMutation(deleteActions, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Action deleted successfully");
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const createAction = useMutation(postActions, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Action created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  console.log(createAction, "createAction");
  const editAction = useMutation(editActions, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Action edited successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  return useMemo(
    () => ({
      deleteAction,
      actionData,
      createAction,
      editAction,
    }),
    [deleteAction, actionData, createAction, editAction]
  );
}
