import {useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAppState} from "../contexts/AppContext";
import {useLogout} from "./useLogout";
import {deleteStorages, editStorages, getStorages, postStorages} from "../services/DataService";


export function useStorageAPI(handleCloseSaveModal) {

    const {handleOpenAlert} = useAppState();
    const {logout} = useLogout();
    const queryClient = useQueryClient();

    const storageData = useQuery(["getStorages"], getStorages);

    const deleteStorageData = useMutation(deleteStorages, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Storage deleted successfully");
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout();
            }
            handleOpenAlert("error", "Oops, something went wrong");
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const createStorage = useMutation(postStorages, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Storage created successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const editStorage = useMutation(editStorages, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Source edited successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });


    return useMemo(() => ({
        storageData,
        deleteStorageData,
        editStorage,
        createStorage
    }), [storageData, deleteStorageData, editStorage, createStorage])
}
