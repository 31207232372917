import { useMemo } from "react";
import { useQueryClient, useMutation } from "react-query";

import { useLogout } from "./useLogout";
import { useAppState } from "../contexts/AppContext";
import { getChartByRange } from "../services/DataService";

export function useChartAPI(setChart) {
  const { handleOpenAlert } = useAppState();

  const { logout } = useLogout();
  const queryClient = useQueryClient();

  const getChart = useMutation(getChartByRange, {
    onSuccess: (data) => {
      setChart(data);
      // console.log("ByRange Chart data: ", data);
    },

    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("get");
    },
  });

  return useMemo(
    () => ({
      getChart,
    }),
    [getChart]
  );
}
