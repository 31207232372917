import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextFieldSearch = styled(TextField)(() => ({
  width: 280,
  margin: 10,
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.3)", 
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.6)",
      borderWidth: 1,
    },
  },
}));

export default TextFieldSearch;
