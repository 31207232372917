import React from "react";
import { Button } from "@mui/material";

const SearchButton = ({ handleSearch, disabled = false }) => {
  return (
    <Button
      onClick={handleSearch}
      disabled={disabled}
      variant="save"
      size="large"
    >
      Search
    </Button>
  );
};
export default SearchButton;
