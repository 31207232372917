import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isObject } from "lodash";
import { Alert } from "../../utils/alert";
import style from "./style.module.css";
import ".././style.css";
import EditButton from "../../components/SaveModalButtons/EditButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import { ModuleAttributes } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppState } from "../../contexts/AppContext";
import { v4 } from "uuid";
import ExplanationIcon from "../../assets/ExplanationIcon.svg";
import ExplanationProcessingType from "../../components/Explanation/ExplanationConfig/ExplanationProcesingType";
import { useStaticStorageAPI } from "../../hooks/useStaticStorageAPI";
import { useCustomModulesAPI } from "../../hooks/useCustomModulesAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const SaveCustomModuleModal = ({ handleCloseSaveModal, defaultValues }) => {
  const firstAttribute = {
    id: v4(),
    attribute: "",
    matchingAttribute: [],
  };

  const [name, setName] = useState("");
  const [matchingDatabase, setMatchingDatabase] = useState("");
  const [attributes, setAttributes] = useState([firstAttribute]);
  const [processingType, setProcessingType] = useState("");
  const [conditions, setConditions] = useState([]);
  const [checked, setChecked] = useState(true);

  const [expandedAttributeIds, setExpandedAttributeIds] = useState([
    firstAttribute.id,
  ]);
  const [expandedConditionIds, setExpandedConditionIds] = useState([]);

  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const {
    staticStorageData: { data: databases },
  } = useStaticStorageAPI();
console.log(databases,'fff')

  const { config } = useAppState();
  const dataProcessingTypes = useMemo(
    () => config?.config?.customModule?.dataProcessingTypes,
    [config]
  );
  const conditionTypes = useMemo(
    () => config?.config?.customModule?.conditions,
    [config]
  );

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const isNotEmptyAttributes = () => {
    if (attributes.length) {
      return attributes.every((e) => {
        if (e.attribute && e.matchingAttribute.length) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  };
  const isNotEmptyConditions = () => {
    if (conditions.length) {
      return conditions.every((e) => {
        if (e.condition && e.conditionAttribute.length) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return true;
    }
  };
  const attributesArrayToObj = () => {
    const queryColumns = {};
    attributes.forEach((attribute) => {
      const key = attribute.attribute;
      const value =
        attribute?.matchingAttribute?.length > 1
          ? { columns: [...attribute.matchingAttribute], type: "string" }
          : { column: attribute.matchingAttribute[0], type: "string" };
      queryColumns[key] = value;
    });
    return queryColumns;
  };
  const conditionArrayToObj = () => {
    const queryConditions = {};
    conditions.forEach((condition) => {
      const isConditionNotNull = condition.condition !== "not_null";
      const key = condition.conditionAttribute;
      const value =
        condition?.condition?.length > 1
          ? isConditionNotNull
            ? {
                type: condition.condition,
                value: +condition.conditionValue,
              }
            : { type: condition.condition }
          : isConditionNotNull
          ? {
              type: condition.condition[0],
              value: +condition.conditionValue,
            }
          : { type: condition.condition[0] };
      queryConditions[key] = value;
    });
    return queryConditions;
  };
  const validation = () => {
    return !(
      name &&
      matchingDatabase &&
      processingType &&
      isNotEmptyAttributes() &&
      isNotEmptyConditions()
    );
  };
  const EditValidation = () => {
    if (
      defaultValues?.name === name &&
      defaultValues?.matchingDatabaseId === matchingDatabase &&
      JSON.stringify(defaultValues?.matchingConfig?.queryColumns) ===
        JSON.stringify(attributesArrayToObj()) &&
      JSON.stringify(
        defaultValues?.matchingConfig?.conditions
          ? defaultValues?.matchingConfig?.conditions
          : {}
      ) === JSON.stringify(conditionArrayToObj()) &&
      defaultValues?.active === checked &&
      defaultValues?.dataProcessingType === processingType
    ) {
      return true;
    } else {
      if (isNotEmptyAttributes() && isNotEmptyConditions()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeMatchingDatabase = (event) => {
    setMatchingDatabase(event.target.value);
  };
  const handleChangeAttributes = (event, index) => {
    setAttributes(
      attributes.map((e, i) => {
        if (i === index) {
          e.attribute = event.target.value;
          return e;
        } else {
          return e;
        }
      })
    );
  };
  const handleChangeMatchingAttribute = (event, index) => {
    setAttributes(
      attributes.map((e, i) => {
        if (i === index) {
          e.matchingAttribute = event.target.value;
          return e;
        } else {
          return e;
        }
      })
    );
  };
  const handleChangeCondition = (event, index) => {
    setConditions(
      conditions.map((e, i) => {
        if (i === index) {
          e.condition = event.target.value;
          return e;
        } else {
          return e;
        }
      })
    );
  };
  const handleChangeConditionValue = (event, index) => {
    setConditions(
      conditions.map((e, i) => {
        if (i === index) {
          e.conditionValue = event.target.value;
          return e;
        } else {
          return e;
        }
      })
    );
  };
  const handleChangeConditionAttribute = (event, index) => {
    setConditions(
      conditions.map((e, i) => {
        if (i === index) {
          e.conditionAttribute = event.target.value;
          return e;
        } else {
          return e;
        }
      })
    );
  };
  const handleChangeProcessingType = (event) => {
    setProcessingType(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleAddAttribute = () => {
    const newAttribute = {
      id: v4(),
      attribute: "",
      matchingAttribute: [],
    };
    setAttributes((prevState) => [...prevState, newAttribute]);
    setExpandedAttributeIds((prevState) => prevState.concat([newAttribute.id]));
  };
  const handleAddCondition = () => {
    const newCondition = {
      id: v4(),
      condition: "",
      conditionAttribute: "",
      conditionValue: 0,
    };
    setConditions((prevState) => [...prevState, newCondition]);
    setExpandedConditionIds((prevState) => prevState.concat([newCondition.id]));
  };
  const handleDeleteAttribute = (id) => {
    setAttributes((prevState) => prevState.filter((el) => el.id !== id));
  };
  const handleDeleteCondition = (id) => {
    setConditions((prevState) => prevState.filter((el) => el.id !== id));
  };
  const handleSetAttributeExpanded = (id) => {
    if (expandedAttributeIds.includes(id)) {
      setExpandedAttributeIds((prevState) =>
        prevState.filter((el) => el !== id)
      );
    } else {
      setExpandedAttributeIds((prevState) => prevState.concat(id));
    }
  };
  const handleSetConditionExpanded = (id) => {
    if (expandedConditionIds.includes(id)) {
      setExpandedConditionIds((prevState) =>
        prevState.filter((el) => el !== id)
      );
    } else {
      setExpandedConditionIds((prevState) => prevState.concat(id));
    }
  };

  const { editCustomModulesModal, createCustomModule } = useCustomModulesAPI();

  const handlePostModule = () => {
    createCustomModule.mutate({
      name: name,
      matchingDatabaseId: matchingDatabase,
      matchingConfig: {
        queryColumns: attributesArrayToObj(),
        conditions: conditionArrayToObj(),
      },
      active: checked,
      dataProcessingType: processingType,
    });
  };

  const handleEditCustomModule = () => {
    editCustomModulesModal.mutate({
      data: {
        name: name,
        matchingDatabaseId: matchingDatabase,
        matchingConfig: {
          queryColumns: attributesArrayToObj(),
          conditions: conditionArrayToObj(),
        },
        active: checked,
        dataProcessingType: processingType,
      },
      id: defaultValues._id,
    });
  };

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setMatchingDatabase(defaultValues.matchingDatabaseId);
      setProcessingType(defaultValues.dataProcessingType);
      setChecked(defaultValues.active);
      if (isObject(defaultValues?.matchingConfig?.conditions)) {
        setConditions(
          Object.entries(defaultValues?.matchingConfig?.conditions).map(
            ([key, value]) => {
              const idForExpanded = v4();
              setExpandedConditionIds((prevState) =>
                prevState.concat([idForExpanded])
              );

              return {
                condition: value?.type,
                conditionValue: value?.value,
                conditionAttribute: key,
                id: idForExpanded,
              };
            }
          )
        );
      }
      setAttributes(
        Object.entries(defaultValues.matchingConfig.queryColumns).map(
          ([key, value]) => {
            const idForExpanded = v4();
            setExpandedAttributeIds((prevState) =>
              prevState.concat([idForExpanded])
            );

            return {
              id: idForExpanded,
              attribute: key,
              matchingAttribute: value?.column
                ? [value?.column]
                : value?.columns
                ? [...value?.columns]
                : [],
            };
          }
        )
      );
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  return (
    <>
      <Box className={style.modal}>
        <Box>
          <Typography variant={"h1"} className={style.title}>
            {defaultValues ? "Edit" : "Create"} Module
          </Typography>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Name</Typography>
            <TextField
              placeholder={"Enter name"}
              value={name}
              onChange={handleChangeName}
              variant="outlined"
            />
          </Box>

          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Matching Database</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={matchingDatabase}
                  onChange={handleChangeMatchingDatabase}
                  label="matchingDatabase"
                  displayEmpty
                  renderValue={
                    matchingDatabase !== ""
                      ? undefined
                      : () => (
                          <Placeholder>Select matching database</Placeholder>
                        )
                  }
                >
                  {databases &&
                    databases?.map((element) => (
                      <MenuItem value={element._id}>{element.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {matchingDatabase && (
            <>
              {attributes.map((attribute, i) => {
                return (
                  <Accordion
                    expanded={expandedAttributeIds.includes(attribute.id)}
                    variant="modal"
                  >
                    <Box className={style.container_attribute}>
                      <Typography className={style.inputTitle}>
                        Attribute {i + 1}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Button
                          variant="refresh"
                          color="primary"
                          size="large"
                          onClick={() => handleDeleteAttribute(attribute.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                        <Button
                          className={style.button_option}
                          variant="refresh"
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleSetAttributeExpanded(attribute.id)
                          }
                        >
                          {expandedAttributeIds.includes(attribute.id) ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </Button>
                      </Box>
                    </Box>
                    <Box className={style.create_source_item}>
                      <Box className={style.text}>
                        <FormControl fullWidth>
                          <Select
                            value={attribute.attribute}
                            onChange={(event) =>
                              handleChangeAttributes(event, i)
                            }
                            label="attribute"
                            displayEmpty
                            renderValue={
                              attribute.attribute !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>Select attribute</Placeholder>
                                  )
                            }
                          >
                            {Object.keys(ModuleAttributes).map((key) => (
                              <MenuItem value={key}>{key}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className={style.create_source_item}>
                      <Typography variant={"subtitle2"}>
                        Matching Attribute {i + 1}
                      </Typography>
                      <Box className={style.text}>
                        <FormControl fullWidth>
                          <Select
                            value={attribute.matchingAttribute}
                            onChange={(event) =>
                              handleChangeMatchingAttribute(event, i)
                            }
                            label="matchingDatabase"
                            displayEmpty
                            multiple
                            renderValue={
                              attribute?.matchingAttribute?.length !== 0
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      Select matching attribute
                                    </Placeholder>
                                  )
                            }
                          >
                            {databases &&
                              databases
                                ?.find(
                                  (database) =>
                                    database._id === matchingDatabase
                                )
                                ?.columnsWithIndices?.map((column) => {
                                  return (
                                    <MenuItem value={column}>{column}</MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Accordion>
                );
              })}
              <Button
                variant="refresh"
                color="primary"
                size="large"
                onClick={handleAddAttribute}
              >
                <AddIcon />
                Add Attribute
              </Button>

              <Box className={style.create_source_item}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant={"subtitle2"}>Processing Type</Typography>
                  <Tooltip
                    title={<ExplanationProcessingType />}
                    arrow
                    placement="top-start"
                  >
                    <IconButton sx={{ padding: "4px 8px 8px 8px" }}>
                      <img src={ExplanationIcon} alt="" />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box className={style.text}>
                  <FormControl fullWidth>
                    <Select
                      value={processingType}
                      onChange={handleChangeProcessingType}
                      label="processingType"
                      displayEmpty
                      renderValue={
                        processingType !== ""
                          ? undefined
                          : () => (
                              <Placeholder>Select processing type</Placeholder>
                            )
                      }
                    >
                      {dataProcessingTypes &&
                        Object.entries(dataProcessingTypes)?.map(
                          ([key, value]) => (
                            <MenuItem value={value.value}>
                              {value.label}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {conditions.map((condition, i) => {
                return (
                  <Accordion
                    expanded={expandedConditionIds.includes(condition.id)}
                    sx={{
                      boxShadow: "none",
                      margin: "0 !important",
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className={style.inputTitle}>
                        Condition {i + 1}
                      </Typography>
                      <Box sx={{ display: "flex", background: "yekk" }}>
                        <Button
                          className={style.button_option}
                          variant="refresh"
                          color="primary"
                          size="large"
                          onClick={() => {
                            handleDeleteCondition(condition.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                        <Button
                          className={style.button_option}
                          variant="refresh"
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleSetConditionExpanded(condition.id)
                          }
                        >
                          {expandedConditionIds.includes(condition.id) ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </Button>
                      </Box>
                    </Box>
                    <Box className={style.create_source_item}>
                      <Box className={style.text}>
                        <FormControl fullWidth>
                          <Select
                            value={condition.condition}
                            onChange={(event) =>
                              handleChangeCondition(event, i)
                            }
                            label="condition"
                            displayEmpty
                            renderValue={
                              condition.condition !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>Select condition</Placeholder>
                                  )
                            }
                          >
                            {conditionTypes &&
                              Object.entries(conditionTypes)?.map(
                                ([key, value]) => (
                                  <MenuItem value={value.value}>
                                    {value.label}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>

                    <Box className={style.create_source_item}>
                      <Typography variant={"subtitle2"}>
                        Coundition Attribute {i + 1}
                      </Typography>
                      <Box className={style.text}>
                        <FormControl fullWidth>
                          <Select
                            value={condition.conditionAttribute}
                            onChange={(event) =>
                              handleChangeConditionAttribute(event, i)
                            }
                            label="conditionAttribute"
                            displayEmpty
                            renderValue={
                              condition?.conditionAttribute !== ""
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      Select condition attribute
                                    </Placeholder>
                                  )
                            }
                          >
                            {databases &&
                              databases
                                ?.find(
                                  (database) =>
                                    database._id === matchingDatabase
                                )
                                ?.columnsWithIndices?.map((column) => {
                                  return (
                                    <MenuItem value={column}>{column}</MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    {condition.condition &&
                      condition.condition !== "not_null" && (
                        <Box className={style.create_source_item}>
                          <Typography variant={"subtitle2"}>
                            Condition Value {i + 1}
                          </Typography>
                          <TextField
                            placeholder={"Condition Value"}
                            type={"number"}
                            value={condition.conditionValue}
                            onChange={(event) =>
                              handleChangeConditionValue(event, i)
                            }
                            variant="outlined"
                          />
                        </Box>
                      )}
                  </Accordion>
                );
              })}
              <Button
                variant="refresh"
                color="primary"
                size="large"
                onClick={handleAddCondition}
              >
                <AddIcon />
                Add Condition
              </Button>
            </>
          )}

          <Box className={style.create_source_item}>
            <Box className={style.switch}>
              <Switch checked={checked} onChange={handleChangeChecked} />
              <Typography sx={{ margin: "-2px 0 0 10px" }}>Active</Typography>
            </Box>
          </Box>

          <Box className={style.container_main_buttons}>
            <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
            {defaultValues ? (
              <EditButton
                handlePost={handleEditCustomModule}
                validation={EditValidation}
              />
            ) : (
              <SaveButton
                handlePost={handlePostModule}
                validation={validation}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveCustomModuleModal;
