import {
  Box,
  CircularProgress,
} from "@mui/material";

const ModulesPageLoadingItem = () => {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
}

export default ModulesPageLoadingItem