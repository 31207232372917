import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import SourcesStatictis from "../../components/Statistics/SourcesStatictis/SourcesStatictis";
import TabPanel from "../../components/Tab/TabPanel";

import style from "./styles.module.css";
import "../style.css";

const Dashboards = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={style.container_sources_page}>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Sources statistic" />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={0} index={0}>
        <SourcesStatictis />
      </TabPanel>
    </>
  );
};
export default Dashboards;
