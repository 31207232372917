import React from "react";
import { Box, Typography } from "@mui/material";
import style from "./styleConfig.module.css";

const ExplanationEndpoint = () => {
  return (
    <Box className={style.container}>
      <Typography variant={'subtitle1'} className={style.text}>
        Use {`{{`}param_name{`}}`} to put dynamic values in URL. Not existing property will be replaced by empty string. Example: https://segma.io/api/{`{`}{`{`}email{`}}`}/leads?email={`{{`}email{`}}`}&homePhone={`{{`}phone{`}}`}
      </Typography>
    </Box>
  );
};
export default ExplanationEndpoint;
