import { createTheme } from "@mui/material/styles";

const defaultMode = "light";

export const borderStyles = {
  borderLeft: "2px solid #e0e0e0",
  borderRight: "2px solid #e0e0e0",
};

export const borderStylesLeft = {
  borderLeft: "1px solid #e0e0e0",
}

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 5,
    },
    body1: {
      fontSize: 16,
    },
    staticModule: {
      letterSpacing: "0.06em",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "14px",
      color: "#7E7E7E",
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: "#272626",
        },
        root: {
          "& .toggleDataBase-root:hover": {
            color: "#272626",
          },
        },
      },
      variants: [
        {
          props: { variant: "custom" },
          style: {
            width: "fit-content",
            background: "#F8F9FA",
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          width: 28,
          height: 16,
          display: "flex",
        },
        thumb: {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: "width 200ms",
        },
        track: {
          borderRadius: 8,
          opacity: 1,
          backgroundColor:
            defaultMode === "dark"
              ? "rgba(255,255,255,.35)"
              : "rgba(0,0,0,.25)",
          boxSizing: "border-box",
        },
        switchBase: {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: defaultMode === "dark" ? "#177ddc" : "#1EBA40",
            opacity: 1,
          },
          "&:active": {
            "&$checked": {
              transform: "translateX(9px)",
            },
            "& $thumb": {
              width: 15,
            },
          },
          "&.Mui-disabled": {
            border: "2px solid grey",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "white",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          maxWidth: "fit-content",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#272626",
          },
        },
      },
      variants: [
        {
          props: { variant: "custom" },
          style: {
            color: "#ACACAC",
            minHeight: 0,
            "&.Mui-selected": {
              color: "#272626",
              margin: "4px 8px 4px 8px",
              background: "#FFFFFF",
              boxShadow:
                "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
              borderRadius: "4px",
            },
          },
        },
      ],
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiToolbar-root": {
            display: "flex",
            alignItems: "baseline",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        text: {
          "& .MuiInputBase-root": {
            height: 45,
            borderRadius: "8px",
          },
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: 50,
            borderRadius: "8px",
          },
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          '&[variant="custom"]': {
            width: 200,
            border: "1px solid lightgrey",
            boxShadow: "none",
            borderRadius: "8px",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "auth" },
          style: {
            color: "white",
            marginTop: "30px",
            height: "39.97px !important",
            width: "100% !important",
            borderRadius: "40px !important",
            textTransform: "capitalize !important",
            fontWeight: 500,
            fontSize: "14px !important",
            backgroundColor: "#DF4036 !important",
            "&:hover": {
              backgroundColor: "rgb(204,38,54) !important",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26) !important",
              boxShadow: "none !important",
              backgroundColor: "rgba(0, 0, 0, 0.12) !important",
            },
          },
        },
        {
          props: {
            variant: "addNew",
          },
          style: {
            minWidth: "140px",
            borderRadius: "40px",
            height: "39.97px !important",
            textTransform: "capitalize",
            color: "white",
            fontWeight: 500,
            fontSize: "14px",
            backgroundColor: "#DF4036",
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&:hover": {
              backgroundColor: "rgb(204,38,54)",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        {
          props: {
            variant: "import",
          },
          style: {
            borderRadius: "40px !important",
            textTransform: "capitalize !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            backgroundColor: "white !important",
            color: "black !important",
            border: "2px solid black !important",
            padding: "0 25px",
            height: "39.97px",
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&:hover": {
              backgroundColor: "white",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        {
          props: {
            variant: "refresh",
          },
          style: {
            height: 20,
            textTransform: "capitalize",
            border: "none",
            fontSize: "14px",
            fontWeight: 600,
            color: "#272626",
            margin: "0 0 25px 0",
            padding: 0,
            display: "flex",
            gap: "5px",
            minWidth: 25,
          },
        },
        {
          props: {
            variant: "smallIcon",
          },
          style: {
            height: 20,
            textTransform: "capitalize",
            border: "none",
            fontSize: "14px",
            fontWeight: 600,
            color: "#272626",
            margin: "-5px 0 25px 0",
            padding: 0,
            display: "flex",
            gap: "5px",
          },
        },
        {
          props: {
            variant: "addStep",
          },
          style: {
            height: 20,
            textTransform: "capitalize",
            border: "0 px solid transparent",
            fontSize: "14px",
            fontWeight: 600,
            color: "#272626",
            margin: "30px 0 30px 0",
            padding: 0,
            display: "flex",
            gap: "5px",
            "&:disabled": {
              color: "#ACACAC",
              border: "0 px solid transparent",
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "save" },
          style: {
            width: "90px",
            borderRadius: "40px",
            textTransform: "capitalize",
            height: "39.97px",
            fontWeight: 500,
            color: "white",
            fontSize: "14px",
            backgroundColor: "#DF4036",
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&:hover": {
              backgroundColor: "rgb(204,38,54)",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              boxShadow: "none",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          },
        },
        {
          props: { variant: "skip" },
          style: {
            width: "120px",
            borderRadius: "4px",
            textTransform: "capitalize",
            height: "44.97px",
            fontWeight: 500,
            color: "black",
            fontSize: "14px",
            backgroundColor: "none",
            marginBottom: 5,
            marginRight: 10,
            border: "1px solid rgba(0,0,0,0.3)",
            "&:hover": {
              border: "none",
              boxShadow: "0px 0px 1px rgba(0,0,0,0.5)",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              boxShadow: "none",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          },
        },
        {
          props: { variant: "skipZero" },
          style: {
            width: "120px",
            borderRadius: "8px",
            textTransform: "capitalize",
            height: "45.97px",
            fontWeight: 400,
            paddingButtom: -5,
            color: "black",
            fontSize: "14px",
            border: "1px solid rgba(0,0,0,0.2)",
            "&:hover": {
              border: "1px solid rgba(0,0,0,0.7)",
              backgroundColor: "white",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              boxShadow: "none",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          },
        },
        {
          props: { variant: "search" },
          style: {
            borderRadius: 8,
            textTransform: "capitalize",
            marginLeft: -65,
            width: 50,
            height: "50px",
            fontWeight: 400,
            color: "black",
            padding: 0,
          },
        },
        {
          props: { variant: "sort" },
          style: {
            borderRadius: 8,
            textTransform: "capitalize",
            marginLeft: 10,
            width: 150,
            border: "1px solid rgba(0,0,0,0.2)",
            height: "50px",
            fontWeight: 500,
            fontSize: 14,
            color: "black",
            padding: 0,
          },
        },
        {
          props: { variant: "cancel" },
          style: {
            width: "90px",
            borderRadius: "40px",
            textTransform: "capitalize",
            fontWeight: 500,
            height: "43.97px",
            fontSize: "14px",
            backgroundColor: "white",
            color: "black",
            border: "2px solid black",
            boxShadow: "none",
            padding: "0 25px",
            "&:hover": {
              backgroundColor: "white",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
            "&:disabled": {
              border: "2px solid grey",
            },
          },
        },
      ],
    },
    MuiRadio: {
      variants: [
        {
          props: { variant: "red" },
          style: {
            color: "#DF4036",
            "&.Mui-checked": {
              color: "#DF4036",
            },
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "module" },
          style: {
            maxWidth: 377,
            maxHeight: 233,
            margin: 16,
            paddingTop: 24,
            border: "1px dashed grey",
          },
        },
      ],
    },
    
    MuiAccordion: {
      variants: [
        {
          props: { variant: "modal" },
          style: {
            boxShadow: "none",
            margin: "0",
            "&:before": {
              display: "none",
            },
          },
        },
      ],
    },
    
    MuiTableCell: {
      variants: [
        {
          props: { variant: "customBold" },
          style: {
            letterSpacing: "0.06em",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "14px",
            color: "#7E7E7E",
          },
        },
        {
          props: { variant: "customStorage" },
          style: {
            letterSpacing: "0.06em",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            fontWeight: 700,
            textAlign: "left",

            fontSize: "14px",
            color: "#7E7E7E",
          },
        },
        {
          props: { variant: "master-records" },
          style: {
            letterSpacing: "0.06em",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            fontWeight: 700,
            // maxWidth: 130,
            textAlign: "left",
            // minWidth: 100,
            fontSize: "14px",
            color: "#7E7E7E",
          },
        },
        {
          props: { variant: "custom" },
          style: {
            fontWeight: 400,
            fontSize: "14px",
            color: "#272626",
            cursor: "pointer",
          },
        },
        {
          props: { variant: "custom-body" },
          style: {
            fontWeight: 500,
            fontSize: "10px",
            color: "#272626",
            cursor: "pointer",
            height: 80,
          },
        },

        {
          props: { variant: "workflows" },
          style: {
            padding: "10px 16px 10px 16px",
          },
        },
      ],
    },
    MuiTableRow: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            "&:hover": {
              backgroundColor: "#F8F9FA",
            },
            "&:last-child td, &:last-child th": {
              border: 0,
            },
            cursor: "pointer",
          },
        },
      ],
    },
    MuiTableContainer: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            border: "1px solid lightgrey",
            boxShadow: "none",
            borderRadius: "8px",
          },
        },
      ],
    },
  },
});
