import React, {useState} from "react";
import {Box, Tabs, Tab, CircularProgress, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import FormUpdateWorkflow from "../../components/Forms/FormUpdateWorkflow";
import TabPanel from "../../components/Tab/TabPanel";
import {Flow} from "../../components/Flow/Flow";
import WorkflowStepsLogs from "./WorkflowStepsLogs";
import style from "./styles.module.css";
import {useWorkflowAPI} from "../../hooks/useWorkFlowAPI";

const CustomColorTab = withStyles({
    root: {
        "&.Mui-selected": {
            color: "#272626 !important",
        },
    },
})((props) => <Tab {...props} />);

const UpdateWorkflowPage = () => {
    const [value, setValue] = useState(0);
    const {id} = useParams();
    const {workflowData: {data, isLoading, isError}} = useWorkflowAPI(id);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px",
                }}
            >
                <Tabs
                    TabIndicatorProps={{style: {background: "#272626"}}}
                    sx={{
                        "& button:hover": {color: "#272626"},
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    <CustomColorTab label="Step View"/>
                    <CustomColorTab label="Flow View"/>
                    <CustomColorTab label="Logs"/>
                </Tabs>
                {value === 1 && (
                    <Box sx={{display: "flex", gap: "10px"}}>
                        <Box className={style.button_container}>
                            <Typography>
                                <Typography
                                    className={style.button}
                                    sx={{background: "#DCAF10"}}
                                ></Typography>
                            </Typography>
                            <Typography>Push</Typography>
                        </Box>
                        <Box className={style.button_container}>
                            <Typography
                                className={style.button}
                                sx={{background: "#10A1C0"}}
                            ></Typography>
                            <Typography>Processing</Typography>
                        </Box>
                        <Box className={style.button_container}>
                            <Typography
                                className={style.button}
                                sx={{background: "#3538CD"}}
                            ></Typography>
                            <Typography>Proxy</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <TabPanel value={value} index={0}>
                <FormUpdateWorkflow PrevData={data} id={id}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div style={{width: "100%", height: "500px"}}>
                    <Flow FlowData={data}/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <WorkflowStepsLogs workflowSteps={data.workflowSteps}/>
            </TabPanel>
        </>
    );
};

export default UpdateWorkflowPage;
