import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  Snackbar,
  FormControl,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import { Dropzone } from "../../components/Dropzone/Dropzone";
import DeleteIcon from "../../assets/DeleteSource.svg";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const ImportFileModal = ({ handleCloseSaveModal }) => {
  const {
    sourcesData: { data },
    importFiles,
  } = useSourcesAPI();

  const [apiKey, setApiKey] = useState("");
  const [source, setSource] = useState("");
  const [file, setFile] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });
  useEffect(() => {
    if (source && source.apiKey) {
      setApiKey(source.apiKey);
    }
  }, [source]);

  useEscapeClose(handleCloseSaveModal);

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleChangeApiKey = (event) => {};
  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };
  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item).then(() => {
      handleOpenAlert("success", "Key copied to clipboard");
    });
  };
  const handleSetFile = (files) => {
    setFile(files[0]);
  };
  const handleDeleteFile = () => {
    setFile("");
  };

  const validation = () => {
    if (apiKey && source && file) {
      return false;
    } else {
      return true;
    }
  };

  const handlePostSources = () => {
    const formData = new FormData();
    formData.append("file", file);
    importFiles.mutate({
      file: formData,
      apiKey: apiKey,
    });
  };
  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          Import File
        </Typography>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Source</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={source || ""}
                onChange={handleChangeSource}
                label="Source"
                displayEmpty
                renderValue={
                  source !== "" && source !== null
                    ? undefined
                    : () => <Placeholder>Select source</Placeholder>
                }
              >
                {data?.data?.map((e) => (
                  <MenuItem key={e._id} value={e}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>API Key</Typography>
          <TextField
            InputLabelProps={{ shrink: false }}
            value={apiKey}
            placeholder={"API Key"}
            variant="outlined"
            onChange={handleChangeApiKey}
            disabled
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ paddingRight: 0 }}
                  onClick={() => copyToClipboard(apiKey)}
                  aria-label="copy"
                >
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box className={style.create_source_item}>
          {file ? (
            <Box className={style.uploadedFileWrapper}>
              <Typography variant={"body1"} className={style.fileTitle}>
                {file?.path}
              </Typography>
              <IconButton aria-label="delete" onClick={handleDeleteFile}>
                <img src={DeleteIcon} alt="" />
              </IconButton>
            </Box>
          ) : (
            <Dropzone onDrop={handleSetFile} />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          <SaveButton
            text={"Import"}
            handlePost={handlePostSources}
            validation={validation}
          />
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ImportFileModal;
