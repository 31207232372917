import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Box, CircularProgress, Snackbar, Modal, Button } from "@mui/material";

import moment from "moment";

import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";

import { deleteGroups, getGroup } from "../../services/DataService";

import { Alert } from "../../utils/alert";

import SaveGroupModal from "./SaveGroupModal";
import { useGroupsAPI } from "../../hooks/useGroupsAPI";

const GroupsPage = () => {
  const {
    groupsData: { data, isLoading, isError },
  } = useGroupsAPI();

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Group created successfully",
  });

  const queryClient = useQueryClient();

  const Delete = useMutation(deleteGroups, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Group deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const handleOpenDeleteModal = (group) => {
    setDeleteId({ id: group._id, name: group.name });
    setShowDeleteModal(true);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const redirectIdGroup = async (group) => {
    const data = await getGroup(group._id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  const handleDeleteGroup = (group) => {
    handleCloseDeleteModal();
    Delete.mutate(deleteId.id);
  };

  const headers = [
    {
      lable: "Group Name",
      align: "left",
    },
    {
      lable: "Type",
      align: "left",
    },
    {
      lable: "Dupe Rule",
      align: "left",
    },
    {
      lable: "Members",
      align: "left",
    },
    {
      lable: "",
      align: "left",
    },
    {
      lable: "Created at",
      align: "right",
    },
    {
      lable: "",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, group) => {},
      align: "left",
    },
    {
      accessor: (element) => element["type"],
      Cell: (event, group) => {},
      align: "left",
    },
    {
      accessor: (element) => `${element["duplicateConfig"]["type"]}, ${element["duplicateConfig"]["value"]}${element["duplicateConfig"]["period"][0]}`,
      Cell: (event, group) => {},
      align: "left",
    },
    {
      accessor: (element) => element.sources?.length || element.targets?.length,
      Cell: (event, group) => {},
      align: "left",
    },
    {
      accessor: (element) => "",
      Cell: (event, group) => {},
      align: "left",
    },
    {
      accessor: (element) => moment(element.createdAt).format("YYYY-MM-DD HH:MM"),
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return (
    <>
      <Box>
        <Box sx={{ m: "20px", textAlign: "end" }}>
          <Button onClick={redirectAddNew} variant="addNew" size="large">
            New Group
          </Button>
        </Box>
        <Box className="wrapper-source-list" sx={{ padding: "24px" }}>
          <ListTable headers={headers} data={data} handleDelete={handleOpenDeleteModal} redirectId={redirectIdGroup} columns={columns} />
        </Box>
        <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
      <Modal open={showSaveModal} onClose={handleCloseSaveModal} sx={{ backdropFilter: "blur(14px)" }}>
        <SaveGroupModal handleCloseSaveModal={handleCloseSaveModal} defaultValues={defaultValues} />
      </Modal>
      <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} sx={{ backdropFilter: "blur(14px)" }}>
        <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} handleDelete={handleDeleteGroup} text={"Group"} name={deleteId?.name} />
      </Modal>
    </>
  );
};

export default GroupsPage;
