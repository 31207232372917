import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Modal,
} from "@mui/material";
import BackButton from "../../components/SaveModalButtons/BackButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AddStepModal } from "../Modals/AddStepModal/AddStepModal";
import { AddSourceModal } from "../Modals/AddSourceModal/AddSourceModal";
import ModalBack from "../Modals/ModalBack/ModalBack";
import { Alert } from "../../utils/alert";
import style from "../../pages/WorkflowsPage/styles.module.css";
import { useActionsAPI } from "../../hooks/useActionsAPI";
import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import { useAppState } from "../../contexts/AppContext";
import { useLogout } from "../../hooks/useLogout";
import {
  postWorkflowSteps,
  postWorkflows,
  editWorkflowSteps,
  deleteWorkflows,
  postSourceData,
  postNewSources,
} from "../../services/DataService";
import TextFieldSearch from "../../theme/TextFieldSearch";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const FormCreateWorkflow = () => {
  console.log("render FormCreateWorkflow");

  const [name, setName] = useState("");
  const [source, setSource] = useState([]);
  const [checked, setChecked] = useState(true);
  const [id, setId] = useState("");
  const [workflowSteps, setWorkflowSteps] = useState([]);
  const [action, setActions] = useState("");
  const [dependsOn, setDependsOn] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalBack, setOpenModalBack] = useState(false);
  const [chagedData, setChangedData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [openModalAddSources, setOpenModalAddSources] = useState(false);
  const [sourceName, setSourceName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Strategy created successfully",
  });
  const { handleOpenAlert } = useAppState();

  const { logout } = useLogout();

  const queryClient = useQueryClient();

  const navigation = useNavigate();

  const addStep = useMutation(postWorkflowSteps, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Step created successfully");
      setWorkflowSteps([...data.workflowSteps]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  //

  const chooseSource = useMutation(postSourceData, {
    onSuccess: (data) => {
      handleOpenAlert("success", "seaching..");
      setChangedData(data);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const postWorkflow = useMutation(postWorkflows, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Workflow created successfully");
      navigation("/workflows");
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const dataOnStep = useMutation(postWorkflows, {
    onSuccess: (data) => {
      setId(data._id);
      handleOpenModal();
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const editWorkflowStep = useMutation(editWorkflowSteps, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Step edited successfully");
      setWorkflowSteps([...data.workflowSteps]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const deleteWorkflow = useMutation(deleteWorkflows, {
    onSuccess: (data) => {
      navigation("/workflows");
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const postNewSource = useMutation(postNewSources, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Source added successfully");
    },
    onError: (err) => {
      if (err.response.status === 401) {
        handleOpenAlert("error");
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const saveSource = () => {
    postNewSource.mutate({
      active: true,
      apiKey: apiKey,
      name: sourceName,
    });
    handleCloseModalSources();
  };

  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item).then(() => {
      handleOpenAlert("success", "Key copied to clipboard");
    });
  };

  const {
    sourcesData: { data: sources },
  } = useSourcesAPI();

  const {
    actionData: { data: actions },
  } = useActionsAPI();

  const validation = () => {
    return !(name && source);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.value);
  };
  const handleSaveWorkflow = () => {
    if (id) {
      navigation("/workflows");
    } else {
      postWorkflow.mutate({
        name: name,
        active: checked,
        sources: [...source],
      });
    }
  };
  //
  const handleLisener = (source) => {
    setToggle(true);
    chooseSource.mutate({
      queryText: source,
      entityType: "source",
    });
  };

  const handleSaveSteps = (order) => {
    addStep.mutate({
      data: {
        actionId: action,
        order: order,
        dependsOn: dependsOn ? dependsOn : null,
      },
      id: id,
    });
    handleCloseModal();
  };
  const handleBack = () => {
    if (!id && !name && source?.length === 0 && checked) {
      navigation("/workflows");
    } else {
      handleOpenModalBack();
    }
  };
  const handleOpenModalBack = () => setOpenModalBack(true);
  const handleCloseModalBack = () => setOpenModalBack(false);
  const handleAddStep = () => {
    if (id) {
      handleOpenModal();
    } else {
      dataOnStep.mutate({ name: name, active: checked, sources: [...source] });
    }
  };
  const navigateToPage = () => {
    if (id) {
      deleteWorkflow.mutate(id);
    } else {
      navigation("/workflows");
    }
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModalSources = () => setOpenModalAddSources(true);
  const handleCloseModalSources = () => {
    setOpenModalAddSources(false);
    setSourceName("");
  };
  const handleChangeActions = (event) => {
    setActions(event.target.value);
  };
  const handleEditStepActions = (event, workflowId, stepId) => {
    editWorkflowStep.mutate({
      data: { actionId: event.target.value },
      workflowId: workflowId,
      stepId: stepId,
    });
  };
  const handleEditDependsOn = (event, workflowId, stepId) => {
    editWorkflowStep.mutate({
      data: { dependsOn: event.target.value },
      workflowId: workflowId,
      stepId: stepId,
    });
  };
  const handleChangeDependsOn = (event) => {
    setDependsOn(event.target.value);
  };

  const dataToggle = toggle ? chagedData : sources?.data;

  useEscapeClose(handleBack);
  
  console.log("render FormWorkflowCreate sources", sources);
  return (
    <Box className="container">
      <Box className={style.create_source_item}>
        <Typography variant={"subtitle2"}>Name</Typography>
        <TextField
          placeholder={"Enter name"}
          value={name}
          onChange={handleChangeName}
        />
      </Box>
      <Box className={style.create_source_item}>
        <Typography variant={"subtitle2"}>Source</Typography>
        <Box className={style.text}>
          <FormControl fullWidth>
            <Select
              value={source || ""}
              onChange={handleChangeSource}
              label="Source"
              multiple
              displayEmpty
              renderValue={
                source?.length !== 0 && source !== null
                  ? undefined
                  : () => <Placeholder>Select source</Placeholder>
              }
            >
              <TextFieldSearch
                placeholder="Type to search"
                className={style.text_field_search}
                onChange={(event) => {
                  handleLisener(event.target.value);
                }}
              />
              {dataToggle?.length !== 0
              ? dataToggle?.map((e) => (
                  <MenuItem key={e?._id} value={e?._id}>
                    {e?.name}
                  </MenuItem>
                ))
              : toggle && <MenuItem disabled>not found source</MenuItem>}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={style.create_source_item}>
        <Typography variant={"subtitle2"}>Active</Typography>
        <Box className={style.text}>
          <FormControl fullWidth>
            <Select
              value={checked}
              onChange={handleChangeChecked}
              label="Active"
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {workflowSteps?.map((step, i) => {
        return (
          <>
            <Typography variant={"subtitle1"} className={style.StepTitle}>
              Step {i + 1}
            </Typography>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Action</Typography>
              <Box className={style.text}>
                <FormControl fullWidth>
                  <Select
                    value={step.actionId}
                    onChange={(event) => {
                      handleEditStepActions(event, step.workflowId, step._id);
                    }}
                    label="Action"
                  >
                    {actions?.map((e) => (
                      <MenuItem
                        disabled={
                          workflowSteps?.length === 1 && e.type === "PUSH"
                        }
                        value={e._id}
                      >
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {step.dependsOn && (
              <>
                <Box className={style.create_source_item}>
                  <Typography variant={"subtitle2"}>Dependes on</Typography>
                  <Box className={style.text}>
                    <FormControl fullWidth>
                      <Select
                        value={step.dependsOn}
                        className="createSource-form-item-container"
                        onChange={(event) => {
                          handleEditDependsOn(event, step.workflowId, step._id);
                        }}
                        label="Depends on Step"
                      >
                        {workflowSteps.map((e, i) => {
                          return (
                            <MenuItem
                              disabled={e._id === step._id}
                              value={e._id}
                            >
                              Step {i + 1}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={null}>None</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </>
            )}
          </>
        );
      })}
      <Button
        variant="addStep"
        size="large"
        sx={{ width: "fit-content" }}
        onClick={handleOpenModalSources}
      >
        <AddIcon />
        Add processing source
      </Button>
      <Modal
        open={openModalAddSources}
        onClose={handleCloseModalSources}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <AddSourceModal
          openModalAddSources={openModalAddSources}
          handleClickBack={handleCloseModalSources}
          copyToClipboard={copyToClipboard}
          postNewSource={postNewSource}
          saveSource={saveSource}
          sourceName={sourceName}
          setSourceName={setSourceName}
          setApiKey={setApiKey}
          apiKey={apiKey}
        />
      </Modal>
      <Button
        variant="addStep"
        size="large"
        onClick={handleAddStep}
        disabled={validation()}
      >
        <AddIcon />
        Add Step
      </Button>
      <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
        <BackButton handleCloseSaveModal={handleBack} />
        <SaveButton handlePost={handleSaveWorkflow} validation={validation} />
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <AddStepModal
          action={action}
          handleClickBack={handleCloseModal}
          handleChangeActions={handleChangeActions}
          dependsOn={dependsOn}
          handleChangeDependsOn={handleChangeDependsOn}
          workflowSteps={workflowSteps}
          actions={actions}
          handleSaveSteps={handleSaveSteps}
        />
      </Modal>
      <Modal
        open={openModalBack}
        onClose={handleCloseModalBack}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <ModalBack
          handleClickBack={handleCloseModalBack}
          handleClickOk={navigateToPage}
        />
      </Modal>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default FormCreateWorkflow;
