import React from "react";
import { Box } from "@mui/material";
import FormCreateWorkflow from "../../components/Forms/FormCreateWorkflow";

const CreateWorkflowPage = () => {
  return (
    <>
      <Box sx={{ margin: "40px 0 0 20px" }}>
        <FormCreateWorkflow />
      </Box>
    </>
  );
};

export default CreateWorkflowPage;
