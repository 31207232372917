import React from "react";
import { Button } from "@mui/material";

const EditButton = ({ handlePost, validation }) => {
  return (
    <Button
      disabled={validation()}
      onClick={handlePost}
      variant="save"
      size="large"
    >
      Confirm
    </Button>
  );
};
export default EditButton;
