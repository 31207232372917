import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from "react-query";
import { Box, Typography, Select, FormControl, MenuItem, Modal, Snackbar, CircularProgress, Button } from "@mui/material";
import { LinearWithValueLabel } from '../../components/ProgressBar/LinearProgressBar';
import { getImportStatistic, deleteImportFile } from "../../services/DataService";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";
import { Alert } from "../../utils/alert";
import moment from "moment";
import style from "./styles.module.css";

const SourcesImportStatistic = ({sources}) => {
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedSource, setSelectedSource] = useState(" ");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false)
  const [isLoading , setIsLoading] = useState(false)
  const [isError , setIsError] = useState(false)
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const response = useQuery(["getImportStatistic"], () => selectedSource !== " " && getImportStatistic(selectedSource));

  useEffect(() => {
    if(response.isSuccess && selectedSource !== " "){
      setIsRefresh(true)
      setData(response.data.data)
    }
  // eslint-disable-next-line
  },[response.isSuccess])

  useEffect(() => {
      setIsRefresh(false)
  },[selectedSource])

  const Delete = useMutation(deleteImportFile, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Source deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      setIsLoading(true)
      getImportStatistic(selectedSource).then((res) => {
        setIsLoading(false)
        setData(res.data);
      }).catch(() => {
        setIsError(true)
      })
    },
  });

  const handleChangeSelectedSource = (event) => {
    setSelectedSource(event.target.value)
  };
  const handleSearch = async () => {
    setIsLoading(true)
    getImportStatistic(selectedSource).then((res) => {
      setIsLoading(false)
      setData(res.data);
    }).catch(() => {
      setIsError(true)
    })
    setIsRefresh(true)
  }
  const handleOpenDeleteModal = (file) => {
    setDeleteId({ id: file._id, name: file.fileName });
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteSources = () => {
    handleCloseDeleteModal();
    Delete.mutate({sourceId:selectedSource, fileId: deleteId.id});
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const headers = [
    {
      lable: "Import Date",
      align: "left",
    },
    {
      lable: "File Name",
      align: "left",
    },
    {
      lable: "Quantity of Leads",
      align: "left",
    },
    {
      lable: "Size",
      align: "left",
    },
    {
      lable: "Total Processed Leads",
      align: "left",
    },
    {
      lable: "Total Failed Leads",
      align: "left",
    },
    {
      lable: "Import Progress",
      align: "left",
    },
    {
      lable: " ",
      align: "center",
    },
  ];
  const columns = [
    {
      accessor: (element) => moment(element["createdAt"]).format("MM-DD-YYYY"),
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) => element["fileName"],
      Cell: (event, source) => {
      },
      align: "left",
    },
    {
      accessor: (element) => element["totalRecords"],
      Cell: (event, source) => {
      },
      align: "left",
    },
    {
      accessor: (element) => element["fileSize"],
      Cell: (event, source) => {
      },
      align: "left",
    },
    {
      accessor: (element) => element?.progress["currentProcessedRecords"],
      Cell: (event, source) => {
      },
      align: "left",
    },
    {
      accessor: (element) => element?.progress["currentFailedRecords"],
      Cell: (event, source) => {
      },
      align: "left",
    },
    {
      accessor: (element) => <LinearWithValueLabel value={element?.progress?.percentage} />,
      Cell: (event, source) => {
      },
      align: "left",
    },
  ];
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return(
    <>
    <Box className="wrapper-source-list">
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Box
          sx={{ maxWidth: "200px", width: "100%" }}
          className={style.create_source_item}
        >
          <Typography variant={'subtitle2'}>Source</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={selectedSource}
                onChange={handleChangeSelectedSource}
                label="Selected Source"
              >
              <MenuItem value={" "}>
                Select Source
              </MenuItem>
                {sources.map((e) => {
                  return (
                    <MenuItem key={e._id} value={e._id}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ padding: "16px 0 0 0" }}>
        {isRefresh ? 
          <Button 
            onClick={handleSearch}
            variant="import"
            size="large"
          >
            Refresh
          </Button>
          :
          <SearchButton handleSearch={handleSearch} disabled={selectedSource === " "} /> }
        </Box>
      </Box>
          {isLoading ?
          (<Box className="table-loading">
            <CircularProgress />
          </Box>)
          :
          (!(selectedSource === " " && data.length === 0) && <ListTable
            headers={headers}
            data={data}
            handleDelete={handleOpenDeleteModal}
            columns={columns}
          />)}
    </Box>
    <Modal
      open={showDeleteModal}
      onClose={handleCloseDeleteModal}
      sx={{ backdropFilter: "blur(14px)" }}
    >
      <DeleteModal
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDelete={handleDeleteSources}
        text={"Import File"}
        name={deleteId?.name}
      />
    </Modal>
    <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
    </>

  )
}

export default SourcesImportStatistic;
