import React, { useState } from "react";
import { Box, Snackbar, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelButton from "../../../components/SaveModalButtons/CancelButton";
import { Alert } from "../../../utils/alert";
import style from "./../styles.module.css";
import SaveButton from "../../../components/SaveModalButtons/SaveButton";
import { postConnectCollection } from "../../../services/DataService";
import { useMutation, useQueryClient } from "react-query";
import useEscapeClose from "../../../hooks/CloseModal/CloseModal";

const useInputStyle = makeStyles({
  text: {
    "& .MuiInputBase-root": {
      height: 50,
      borderRadius: "8px",
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },
});

const ConnectCollectionModal = ({ handleCloseSaveModal }) => {
  const inputStyle = useInputStyle();

  const [connectionName, setConnectionName] = useState("");
  const [tableName, setTableName] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Storage created successfully",
  });

  useEscapeClose(handleCloseSaveModal);

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation(postConnectCollection, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Collection connect successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const validation = () => {
    if (tableName && connectionName) {
      return false;
    } else {
      return true;
    }
  };

  const handleConnectCollection = () => {
    mutate({ tableName: tableName, title: connectionName });
  };

  const handleChangeConnectionName = (event) => {
    setConnectionName(event.target.value);
  };
  const handleChangeTableName = (event) => {
    setTableName(event.target.value);
  };

  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          Connect Collection
        </Typography>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Connection Name</Typography>
          <TextField
            placeholder={"Enter Connection Name"}
            className={inputStyle.text}
            value={connectionName}
            onChange={handleChangeConnectionName}
            variant="outlined"
          />
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>PostgreSQL Table Name</Typography>
          <TextField
            placeholder={"Enter PostgreSQL Table Name"}
            className={inputStyle.text}
            value={tableName}
            onChange={handleChangeTableName}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", gap: "20px", margin: "40px 0 20px 0" }}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          <SaveButton
            handlePost={handleConnectCollection}
            validation={validation}
            text={"connect"}
          />
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConnectCollectionModal;
