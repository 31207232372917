import React, { useState } from "react";

import {
  Box,
  Typography,
  CircularProgress,
  Modal,
  Tab,
  Tabs,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import moment from "moment";

import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "./ActionListTable";

import { getAction } from "../../services/DataService";

import { Alert } from "../../utils/alert";

import style from "./styles.module.css";

import SaveActionModal from "./SaveActionModal";
import { useActionsAPI } from "../../hooks/useActionsAPI";
import { useCustomModulesAPI } from "../../hooks/useCustomModulesAPI";

const ActionsPage = () => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Storage created successfully",
  });
  const [tabValue, setTabValue] = useState(0);
  const [selectedModule, setSelectedModule] = useState("");

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const {
    actionData: { data, isLoading, isError },
    deleteAction,
  } = useActionsAPI(handleOpenAlert);
  const {
    customModules: { data: customModules },
  } = useCustomModulesAPI();

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const handleOpenDeleteModal = (action) => {
    setDeleteId({ id: action._id, name: action.name });
    setShowDeleteModal(true);
  };

  const handleDeleteAction = (action) => {
    handleCloseDeleteModal();
    deleteAction.mutate(deleteId.id);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const redirectIdsource = async (action) => {
    const data = await getAction(action._id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  const filteredData = data
    ? data.filter((action) => {
        if (tabValue === 1) return action.type === "PUSH";
        if (tabValue === 0) {
          if (selectedModule) {
            return (
              action.type === "PROCESSING" && action.module === selectedModule
            );
          }
          return action.type === "PROCESSING";
        }
        if (tabValue === 2) return action.type === "PROXY";
        return true;
      })
    : [];

  const setType = (type) => {
    if (type === "PROXY") {
      return style.type_proxy;
    } else if (type === "PUSH") {
      return style.type_push;
    } else if (type === "PROCESSING") {
      return style.type_processing;
    } else {
      return null;
    }
  };

  const headers = [
    {
      lable: "Name",
      align: "left",
    },
    {
      lable: "Static Module",
      align: "left",
    },
    {
      lable: "Custom Module",
      align: "left",
    },
    {
      lable: "Type",
      align: "left",
    },
    {
      lable: "Created at",
      align: "left",
    },
    {
      lable: " ",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, action) => {},
      align: "left",
    },
    {
      accessor: (element) => {
        return (
          <Typography variant={"body1"}>
            {element.module
              ? element.module.charAt(0).toUpperCase() +
                element.module.slice(1).toLowerCase().replaceAll("_", " ")
              : "―"}
          </Typography>
        );
      },
      Cell: (event, action) => {},
      align: "left",
    },
    {
      accessor: (element) => {
        if (customModules?.length) {
          const customModule = customModules.find(
            (e) => e._id === element.customModuleId
          );
          if (customModule?.name) {
            return customModule.name;
          } else {
            return "―";
          }
        } else {
          return "―";
        }
      },
      Cell: (event, action) => {},
      align: "left",
    },
    {
      accessor: (element) => (
        <Typography variant={"subtitle1"} className={setType(element["type"])}>
          {element["type"]}
        </Typography>
      ),
      Cell: (event, action) => {},
      align: "left",
    },
    {
      accessor: (element) =>
        moment(element.createdAt).format("YYYY-MM-DD HH:MM"),
      Cell: (event, action) => {},
      align: "left",
    },
  ];
  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return (
    <Box>
      <Box className={style.container_action_page}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Action tabs"
        >
          <Tab label="Processing" />
          <Tab label="Push" />
          <Tab label="Proxy" />
        </Tabs>
        <AddNewButton redirectAddNew={redirectAddNew} text={"Action"} />
      </Box>
      <Box className="wrapper-source-list" sx={{ padding: "14px" }}>
        <ListTable
          headers={headers}
          data={filteredData}
          handleDelete={handleOpenDeleteModal}
          redirectId={redirectIdsource}
          columns={columns}
          tabValue={tabValue}
          handleModuleChange={handleModuleChange}
          selectedModule={selectedModule}
        />
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={showSaveModal}
        onClose={handleCloseSaveModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <SaveActionModal
          handleCloseSaveModal={handleCloseSaveModal}
          defaultValues={defaultValues}
        />
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <DeleteModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDeleteAction}
          text={"Action"}
          name={deleteId?.name}
        />
      </Modal>
    </Box>
  );
};

export default ActionsPage;
