import React, { useState } from "react";

import _get from 'lodash/get'

import {
  Box,
  Typography,
  TableCell,
  TableRow,
  CircularProgress
} from "@mui/material";

import ListTable from '../../ListTable/ListTabel';

import AngleDown from '../../../assets/AngleDown.svg';
import AngleUp from '../../../assets/AngleUp.svg';

import style from './style.module.css';

const WorkflowStatistic = ({AllData}) => {
  const [openActions, setOpenActions] = useState('')

  const underField = (element) => {
    return (
      element.stepsWeek.map((week,index) => {
        return(
        element.stepsMonth.map((month) => {
          if(week.workflowStepId === month.workflowStepId){
            return(
              <TableRow key={element._id}>
                <TableCell variant="workflows" sx={{maxWidth: '200px'}}>
                  {week.workflowStepName}
                </TableCell>
                <TableCell variant="workflows" align="left">
                </TableCell>
                <TableCell variant="workflows" align="left">
                  {_get(element.stepsToday[index], 'totalIncome', 0)}
                </TableCell>
                <TableCell variant="workflows" align="left">
                  {week.totalIncome}
                </TableCell>
                <TableCell variant="workflows" align="left">
                  {month.totalIncome}
                </TableCell>
                <TableCell variant="workflows" align="left">
                {_get(element.stepsToday[index], 'totalOutcome', 0)}
                </TableCell>
                <TableCell variant="workflows" align="left">
                  {week.totalOutcome}
                </TableCell>
                <TableCell variant="workflows" align="left">
                  {month.totalOutcome}
                </TableCell>
            </TableRow>
            )
          } else {return null}
        }))
      }))
  }
  const headers = [
    {
      lable: "Workflow",
      align: "left",
    },
    {
      lable: "Steps",
      align: "left",
    },
    {
      lable: "In Today",
      align: "left",
    },
    {
      lable: "In 7 Days",
      align: "left",
    },
    {
      lable: "In 30 Days",
      align: "left",
    },
    {
      lable: "Out Today",
      align: "left",
    },
    {
      lable: "Out 7 Days",
      align: "left",
    },
    {
      lable: "Out 30 Days",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element.name,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => (
        <Typography variant={'subtitle1'} sx={{fontWeight: 400}}>
          {element.stepCount}
          <img style={{ marginLeft: "5px" }} src={openActions === element._id ? AngleUp : AngleDown} alt="" />
        </Typography>),
      Cell: (event, workflow) => {
        event.stopPropagation();
        if (openActions !== workflow._id) {
          setOpenActions(workflow._id)
        } else {
          setOpenActions(null)
        }
      },
      align: 'left',
    },
    {
      accessor: (element) => element.AllTodayIn,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => element.AllWeekIn,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => element.AllMonthIn,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => element.AllTodayOut,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => element.AllWeekOut,
      Cell: (event, workflow) => { },
      align: 'left',
    },
    {
      accessor: (element) => element.AllMonthOut,
      Cell: (event, workflow) => { },
      align: 'left',
    },
  ]
  if(AllData.length === 0){
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box className="wrapper-source-list">
      <Box className={style.list_table}>
        <ListTable
          headers={headers}
          data={AllData}
          columns={columns}
          openAction={openActions}
          underField={underField}
        />
      </Box>
    </Box>
  )
}

export default WorkflowStatistic
