import moment from "moment-timezone";

export function calculateTotalProperty(array, propertyName) {
    if (!Array.isArray(array)) {
        return 0;
    }

    return array.reduce((total, current) => total + current[propertyName], 0);
}

export function calculateTotalFromArray(dataArray, property) {
    return dataArray.map(entry => entry.workflowSteps.reduce((sum, elem) => sum + elem[property], 0)).reduce((sum, elem) => sum + elem, 0);
}

export function calculateTotalFromArrayChart(dataArray, property) {
    return dataArray.map(entry => entry.workflowSteps.reduce((sum, elem) => sum + elem[property], 0));
}

export function processSteps(week, month) {
    const Week = week.data.flatMap(el => el.workflowSteps);
    const Month = month.data.flatMap(el => el.workflowSteps);

    const WeekSteps = calculateSteps(Week);
    const TodaySteps = calculateSteps(week.data.find(i => i.day === moment().format("YYYY-MM-DD")).workflowSteps);
    const WeekStepsChart = calculateStepsChart(Week);
    const MonthStepsChart = calculateStepsChart(Month);
    const MonthSteps = calculateSteps(Month);

    return {WeekSteps, TodaySteps, WeekStepsChart, MonthStepsChart, MonthSteps};
}

export function calculateSteps(steps) {
    return steps.reduce((results, current) => ({
        ...results,
        [current.workflowStepId]: {
            workflowStepName: current.name,
            workflowStepId: current.workflowStepId,
            totalIncome: current.totalIncome + (results[current.workflowStepId]?.totalIncome || 0),
            totalOutcome: current.totalOutcome + (results[current.workflowStepId]?.totalOutcome || 0),
        },
    }), {});
}

export function calculateStepsChart(steps) {
    return steps.reduce((results, current) => ({
        ...results,
        [current.workflowStepId]: {
            workflowStepName: current.name,
            workflowStepId: current.workflowStepId,
            totalIncome: results[current.workflowStepId]?.totalIncome?.length
                ? [...results[current.workflowStepId].totalIncome, current.totalIncome]
                : [current.totalIncome],
            totalOutcome: results[current.workflowStepId]?.totalOutcome?.length
                ? [...results[current.workflowStepId].totalOutcome, current.totalOutcome]
                : [current.totalOutcome],
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        },
    }), {});
}

export function reformatData(dataArray) {
    const reformattedArray = [];

    dataArray.forEach(item => {
        const reformattedItem = {
            _id: item._id,
            name: item.name,
            dailyCount: item.dailyCount,
            steps: []
        };

        if (item.statistic) {
            item.statistic.forEach(stat => {
                if (stat.steps) {
                    stat.steps.forEach(step => {
                        reformattedItem.steps.push({
                            name: step.name,
                            workflowStepId: step.workflowStepId,
                            totalOutcome: step.totalOutcome,
                            percentage: step.percentage,
                            totalOutcomeMatchedByEmail: step.totalOutcomeMatchedByEmail,
                            totalOutcomeMatchedByPhone: step.totalOutcomeMatchedByPhone,
                            percentageTotalOutcomeMatchedByEmail: step.percentageTotalOutcomeMatchedByEmail,
                            percentageTotalOutcomeMatchedByPhone: step.percentageTotalOutcomeMatchedByPhone
                        });
                    });
                }
            });
        }

        reformattedArray.push(reformattedItem);
    });

    return reformattedArray;
}


export const isISODate = (str) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
    return regex.test(str);
};

export const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

export const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  export const  formatToDateTime = (date) => {
    if (!date) return '';

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
  
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = '00'; // Static seconds as per your requirement
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  export function hashStringToColor(str) {
    // Simple hash function to convert a string to a numeric value
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash value to a valid hex color code
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

