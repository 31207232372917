import {useMemo} from "react";
import {useQuery} from "react-query";
import { getStaticModules} from "../services/DataService";


export function useStaticModulesAPI() {

    const staticModulesData = useQuery(["getStaticModules"], getStaticModules);


    return useMemo(() => ({
        staticModulesData
    }), [staticModulesData])
}
