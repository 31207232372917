import {useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    deleteSources,
    editSources,
    generateApiKey,
    getSources,
    importFile,
    postSources
} from "../services/DataService";
import {useAppState} from "../contexts/AppContext";
import {useLogout} from "./useLogout";


export function useSourcesAPI(setApiKey, handleCloseSaveModal) {

    const {handleOpenAlert} = useAppState();
    const {logout} = useLogout();
    const queryClient = useQueryClient();

    const sourcesData = useQuery(["getSources"], getSources)

    const createSource = useMutation(postSources, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Source created successfully");
            setTimeout(() => {
                handleCloseSaveModal && handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("getSources");
        },
    });

    const editSource = useMutation(editSources, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Source edited successfully");
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const deleteSource = useMutation(deleteSources, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Source deleted successfully");
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", "Oops, something went wrong");
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const importFiles = useMutation(importFile, {
        onSuccess: (data) => {
            handleOpenAlert("success", "File imported successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", "Oops, something went wrong");
        },
        onSettled: () => {
            queryClient.invalidateQueries("fetchPost");
        },
    });

    const postSource = useMutation(postSources, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Source created successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const generateKey = useMutation(generateApiKey, {
        onSuccess: (data) => {
            setApiKey(data.apiToken);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    return useMemo(() => ({
        createSource,
        editSource,
        deleteSource,
        importFiles,
        sourcesData,
        postSource,
        generateKey,

    }), [sourcesData, createSource, editSource, importFiles, deleteSource, postSource, generateKey])
}
