import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Snackbar, Modal } from "@mui/material";

import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";

import { Alert } from "../../utils/alert";
import {useStorageAPI} from "../../hooks/useStorageAPI";

const StoragePage = React.memo(() => {
  const {storageData: {data, isLoading, isError}, deleteStorageData} = useStorageAPI();
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Storage created successfully",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate()

  const openId = (storage) => {
    navigate(`/databases/${storage.name}`)
  }

  const handleCloseAlert = () => {
    setAlert(false);
  };


  const handleDeleteStorages = () => {
    handleCloseDeleteModal();
    deleteStorageData.mutate(deleteId.id);
  };
  const handleOpenDeleteModal = (storage) => {
    setDeleteId({ id: storage._id, name: storage.name });
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const headers = [
    {
      lable: "Collection",
      align: "left",
    },
    {
      lable: "Instance",
      align: "left",
    },
    {
      lable: "Total Records",
      align: "left",
    },
    {
      lable: "Total Size",
      align: "left",
    },
    {
      lable: " ",
      align: "left",
    },
  ];

  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => element["instance"],
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => element["totalRecords"],
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => element["totalSize"],
      Cell: (event, storage) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <h1>Something wrong</h1>;
  }

  return (
    <>
      <Box className="wrapper-source-list">
        <ListTable
          headers={headers}
          data={data}
          handleDelete={handleOpenDeleteModal}
          // redirectId={redirectIdstorage}
          openId={openId}
          columns={columns}
        />
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <DeleteModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDeleteStorages}
          text={"Storage"}
          name={deleteId?.name}
        />
      </Modal>
    </>
  );
});

export default StoragePage;
