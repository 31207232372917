import React, { useEffect, useRef, useState } from "react";

import { reformatData } from "../../utils/functions";
import ListTable from "../../components/ListTable/ListTabel";

import AngleUp from "../../assets/AngleUp.svg";
import AngleDown from "../../assets/AngleDown.svg";
import style from "../ApiKeys/styles.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { timeZoneChanger } from "../../utils/constants";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import { Box, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useStatisticAPI } from "../../hooks/useStatisticAPI";

const AnalyticsStatistic = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const lastTwoWeek = new Date();
  lastTwoWeek.setDate(lastTwoWeek.getDate() - 14);

  const [dateFrom, setDateFrom] = useState(yesterday);
  const [dateTo, setDateTo] = useState(new Date());

  const firstRun = useRef(true);
  const firstTime = useRef(true);

  const handleSearch = () => {
    getAnalyticStatistic.mutate({
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
    });
  };

  const [statistics, setStatistics] = useState({});

  const { getAnalyticStatistic } = useStatisticAPI(setStatistics);

  useEffect(() => {
    if (firstRun.current) {
      getAnalyticStatistic.mutate();
      firstRun.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const [openActions, setOpenActions] = useState("");

  const headers = [
    {
      lable: "Sources",
      align: "left",
      style: {
        width: "33%",
      },
    },
    {
      lable: "Steps",
      align: "left",
      style: {
        width: "33%",
      },
    },
    {
      lable: "Daily Count",
      align: "left",
      style: {
        width: "33%",
      },
    },
  ];

  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, target) => {},
      align: "left",
    },
    {
      accessor: (element) => (
        <Typography>
          {element?.steps?.length}
          <img
            style={{ marginLeft: "5px" }}
            src={openActions === element._id ? AngleUp : AngleDown}
            alt=""
          />
        </Typography>
      ),

      Cell: (event, workflow) => {
        event.stopPropagation();
        if (openActions !== workflow._id) {
          setOpenActions(workflow._id);
        } else {
          setOpenActions(null);
        }
      },
      align: "left",
    },
    {
      accessor: (element) => element["dailyCount"],
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  const underField = (element) => {
    return (
      <>
        <TableRow style={{ background: "#e0e0e0" }}>
          <TableCell
            sx={{
              fontSize: "12px",
              fontWeight: "800",
              textTransform: "uppercase",
            }}
            colSpan={3}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "20%" }}>Name</div>
              <div style={{ width: "20%" }}>Matched by Email</div>
              <div style={{ width: "20%" }}>Matched by Phone</div>
              <div style={{ width: "20%" }}>Total matched</div>
              <div style={{ width: "20%" }}>Percentage</div>
            </div>
          </TableCell>
        </TableRow>

        {element?.steps?.map((e, index) => (
          <TableRow
            sx={index % 2 ? { background: "#ededed" } : null}
            key={e._id}
          >
            <TableCell colSpan={3}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "20%" }}>{e.name}</div>
                <div style={{ width: "20%" }}>
                  {e.totalOutcomeMatchedByEmail}
                </div>
                <div style={{ width: "20%" }}>
                  {e.totalOutcomeMatchedByPhone}
                </div>
                <div style={{ width: "20%" }}>{e.totalOutcome}</div>
                <div style={{ width: "20%" }}>{e.percentage}</div>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Date From</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              placeholder="Date From"
              value={firstTime.current ? timeZoneChanger(dateFrom) : dateFrom}
              minDate={timeZoneChanger(lastTwoWeek)}
              maxDate={timeZoneChanger(yesterday)}
              onChange={(newValue) => {
                setDateFrom(newValue);
                firstTime.current = false;
              }}
              renderInput={(params) => <TextField {...params} />}
              // disabled={page >= 1}
            />
          </LocalizationProvider>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Date To</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              placeholder="Date To"
              value={firstTime.current ? timeZoneChanger(dateTo) : dateTo}
              minDate={timeZoneChanger(lastTwoWeek)}
              maxDate={timeZoneChanger(new Date())}
              onChange={(newValue) => {
                setDateTo(newValue);
                firstTime.current = false;
              }}
              renderInput={(params) => <TextField {...params} />}
              // disabled={page >= 1}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ padding: "16px 0 0 0" }}>
          <SearchButton handleSearch={handleSearch} />
        </Box>
      </Box>
      <ListTable
        headers={headers}
        data={statistics?.sources && reformatData(statistics?.sources)}
        columns={columns}
        openAction={openActions}
        underField={underField}
      />
    </>
  );
};

export default AnalyticsStatistic;
