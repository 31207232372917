import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useAppState } from "../../contexts/AppContext";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useActionsAPI } from "../../hooks/useActionsAPI";
import { useTargetsAPI } from "../../hooks/useTargetsAPI";
import { useStorageAPI } from "../../hooks/useStorageAPI";
import { useCustomModulesAPI } from "../../hooks/useCustomModulesAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const Placeholder = ({ children }) => {
  return <div className={style.text_placeholder}>{children}</div>;
};

const SaveActionModal = ({ handleCloseSaveModal, defaultValues }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [storage, setStorage] = useState("");
  const [target, setTarget] = useState("");
  const [staticModule, setStaticModule] = useState(null);
  const [customModule, setCustomModule] = useState(null);
  const [pushInterval, setPushInterval] = useState(null);
  const [push, setPush] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Strategy created successfully",
  });

  const [cap, setCap] = useState(null);
  const [capType, setCapType] = useState(null);

  const { config } = useAppState();
  const requestInterval = useMemo(
    () => config?.config?.source?.requestInterval,
    [config]
  );
  const types = useMemo(() => config?.config?.action?.type, [config]);
  const modules = useMemo(() => config?.config?.action?.modules, [config]);
  const time = useMemo(() => config?.config?.action?.capType, [config]);

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const { createAction, editAction } = useActionsAPI(
    handleCloseSaveModal,
    handleOpenAlert
  );
  const {
    customModules: { data: customModules },
  } = useCustomModulesAPI();
  const {
    storageData: { data: storages },
  } = useStorageAPI();
  const {
    targetsData: { data: targets },
  } = useTargetsAPI();

  useEffect(() => {
    if (type === "PUSH") {
      setStorage(null);
      setStaticModule(null);
    } else if (type === "PROXY") {
      setStorage(null);
      setPushInterval(null);
      setPush(null);
      setStaticModule(null);
      setCapType(null);
    } else {
      setPushInterval(null);
      setPush(null);
      setTarget(null);
      setCapType(null);
    }
  }, [type]);
  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setType(defaultValues.type);
      setTarget(defaultValues.targetId);
      setStaticModule(defaultValues.module);
      setCustomModule(defaultValues.customModuleId);
      setStorage(defaultValues.storageId);
      setPushInterval(defaultValues.pushInterval);
      setPush(defaultValues.pushLimit);
      setCap(defaultValues.cap);
      setCapType(defaultValues.capType);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  const validation = () => {
    return !(name && type);
  };
  const EditValidation = () => {
    if (type === "PUSH") {
      return (
        name === defaultValues.name &&
        type === defaultValues.type &&
        target === defaultValues.targetId &&
        pushInterval === defaultValues.pushInterval &&
        push === defaultValues.pushLimit &&
        cap === defaultValues.cap &&
        capType === defaultValues.capType
      );
    } else if (type === "PROCESSING") {
      return (
        name === defaultValues.name &&
        type === defaultValues.type &&
        storage === defaultValues.storageId &&
        customModule === defaultValues.customModuleId &&
        staticModule === defaultValues.module
      );
    } else if (type === "PROXY") {
      return (
        name === defaultValues.name &&
        type === defaultValues.type &&
        target === defaultValues.targetId
      );
    }
    return true;
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeStaticModule = (event) => {
    setStaticModule(event.target.value);
    setCustomModule(null);
  };
  const handleChangeCustomModule = (event) => {
    setCustomModule(event.target.value);
    setStaticModule(null);
  };
  const handleChangeStorage = (event) => {
    setStorage(event.target.value);
  };
  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangePush = (event) => {
    setPush(event.target.value);
  };
  const handleChangePushInterval = (event) => {
    setPushInterval(event.target.value);
  };
  const handleChangeCap = (event) => {
    setCap(+event.target.value);
  };
  const handleChangeCapType = (event) => {
    setCapType(event.target.value);
  };

  const handlePostAction = () => {
    createAction.mutate({
      name: name,
      type: type,
      targetId: target,
      storageId: storage,
      customModuleId: customModule ? customModule : null,
      module: staticModule ? staticModule : null,
      pushLimit: push ? +push : null,
      pushInterval: pushInterval ? pushInterval : null,
      cap: cap ? cap : null,
      capType: capType ? capType : null,
    });
  };

  const handleUpdateAction = () => {
    editAction.mutate({
      data: {
        name: name,
        type: type,
        targetId: target,
        storageId: storage,
        customModuleId: customModule ? customModule : null,
        module: staticModule ? staticModule : null,
        pushLimit: push ? +push : null,
        pushInterval: pushInterval ? pushInterval : null,
        cap: cap ? cap : null,
        capType: capType ? capType : null,
      },
      id: defaultValues._id,
    });
  };
  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          {defaultValues ? "Edit" : "Create"} Action
        </Typography>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Name</Typography>
          <TextField
            placeholder={"Enter name"}
            value={name}
            onChange={handleChangeName}
            variant="outlined"
          />
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Request Type</Typography>
          <RadioGroup
            row
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
            value={type}
            onChange={handleChangeType}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {types &&
              Object.entries(types).map(([key, value]) => (
                <FormControlLabel
                  value={value.value}
                  control={<Radio variant="red" />}
                  label={value.label}
                />
              ))}
          </RadioGroup>
        </Box>
        {type === "PROCESSING" && (
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Storage</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={storage || ""}
                  onChange={handleChangeStorage}
                  label="Storage"
                  displayEmpty
                  renderValue={
                    storage !== "" && storage !== null
                      ? undefined
                      : () => <Placeholder>Select storage</Placeholder>
                  }
                >
                  {storages?.map((e) => (
                    <MenuItem key={e._id} value={e._id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {(type === "PUSH" || type === "PROXY") && (
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Target</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={target || ""}
                  onChange={handleChangeTarget}
                  label="Target"
                  displayEmpty
                  renderValue={
                    target !== "" && target !== null
                      ? undefined
                      : () => <Placeholder>Select target</Placeholder>
                  }
                >
                  {targets?.map((e) => (
                    <MenuItem key={e._id} value={e._id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {type === "PROCESSING" && (
          <Box>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Static Module</Typography>
              <Box className={style.text}>
                <FormControl fullWidth>
                  <Select
                    value={staticModule}
                    onChange={handleChangeStaticModule}
                    label="Static Module"
                    displayEmpty
                    renderValue={
                      staticModule !== "" && staticModule !== null
                        ? undefined
                        : () => <Placeholder>Select Static Module</Placeholder>
                    }
                  >
                    {modules &&
                      Object.entries(modules).map(([key, value]) => (
                        <MenuItem value={value.value}>{value.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Custom Module</Typography>
              <Box className={style.text}>
                <FormControl fullWidth>
                  <Select
                    value={customModule}
                    onChange={handleChangeCustomModule}
                    label="Custom Module"
                    displayEmpty
                    renderValue={
                      !!customModule
                        ? undefined
                        : () => <Placeholder>Select Custom Module</Placeholder>
                    }
                  >
                    {customModules?.map((e) => (
                      <MenuItem key={e._id} value={e._id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        {type === "PUSH" && (
          <>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Push Interval</Typography>
              <TextField
                InputLabelProps={{ shrink: false }}
                value={push}
                placeholder={"Enter push Interval"}
                variant="outlined"
                onChange={handleChangePush}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        variant="standard"
                        disableUnderline={true}
                        margin="normal"
                        value={pushInterval}
                        onChange={handleChangePushInterval}
                      >
                        {requestInterval &&
                          Object.entries(requestInterval).map(
                            ([key, value]) => (
                              <MenuItem value={value.value}>
                                {value.label}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Cap</Typography>
              <TextField
                placeholder={"Enter cap"}
                type="number"
                value={cap}
                onChange={handleChangeCap}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        variant="standard"
                        disableUnderline={true}
                        margin="normal"
                        value={capType}
                        onChange={handleChangeCapType}
                      >
                        {time &&
                          Object.entries(time).map(([key, value]) => (
                            <MenuItem value={value.value}>
                              {value.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        )}
        <Box className={style.container_button}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          {defaultValues ? (
            <EditButton
              handlePost={handleUpdateAction}
              validation={EditValidation}
            />
          ) : (
            <SaveButton handlePost={handlePostAction} validation={validation} />
          )}
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveActionModal;
