import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const ModulesPageCardItem = ({ module }) => {
  return (
    <Card variant='module'>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {module.label}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Module processing tables: {module.processingTables.join(', ')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Module processing fileds: {module.processingFields.join(', ')}
        </Typography>
        {
        module.condition
        ?
            <Typography variant="body2" color="textSecondary" component="p">
              Condition: {module.condition}
            </Typography>
        :
          <></>
        }
      </CardContent>
    </Card>
  );
}

export default ModulesPageCardItem
