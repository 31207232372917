import React from 'react';
import ReactJson from 'react-json-view'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import moment from "moment";

import { Box, IconButton, Typography, tooltipClasses, Tooltip } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from "@mui/material/styles";

import ListTable from "../../../components/ListTable/ListTabel";

import { getLink } from '../../../services/DataService';

import exportFileIcon from "../../../assets/exportFileIcon.svg";

import style from './styles.module.css';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    width: "500px",
  },
});

const MatchingDetailsPage = () => {
  const { state } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/databases', {state: {
      ...state,
      tabIndex: 1
    }});
  }

  const handleGetLink = async (event, name) => {
    event.stopPropagation();
    await getLink(name).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const headers = [
    {
      lable: "Created at",
      align: "left",
      style:{
        width: '150px'
      }
    },
    {
      lable: "Email",
      align: "left",
    },
    {
      lable: "Phone",
      align: "left",
    },
    {
      lable: "Data",
      align: "left",
    },
  ];

  const columns = [
    {
      accessor: (element) => moment(element["createdAt"]).format("MM-DD-YYYY") || moment(element["Data Created"]).format("MM-DD-YYYY") || "-",
      Cell: (event, storage) => {},
      align: "left",
      style:{
        width: '150px'
      }
    },
    {
      accessor: (element) => element["email"] || element["Email"] || "-",
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) => element["cellPhone"] || element["Phone"] || element["phone"] || element["cell_phone"] || element["cellphone"] || "-",
      Cell: (event, storage) => {},
      align: "left",
    },
    {
      accessor: (element) =>
      <Box>
        <CustomWidthTooltip
          title={<ReactJson src={element}
          name="Data"
          enableClipboard={false}
          iconStyle="circle"
          theme="summerfruit:inverted"
          displayObjectSize={false}
          displayDataTypes={false}
        />}
          arrow
          placement="left"
        >
        <Box>
          {JSON.stringify(element)}
        </Box>
        </CustomWidthTooltip>
     </Box>,
      Cell: (event, storage) => {},
      align: "left",
    },
  ];

  return(
    <Box className="wrapper-source-list" sx={{ padding: "74px 24px 24px 24px" }}>
      <Box style={{paddingBottom: '24px', display: "flex", gap: "10px", alignItems: "center", justifyContent: 'space-between'}}>
        <Box style={{display: "flex", gap: "10px", alignItems: "center"}}>
          <IconButton onClick={handleBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant={"h1"} className={style.name}>
            {id}
          </Typography>
        </Box>

        <IconButton onClick={(event) => handleGetLink(event, state.name)}>
          <img src={exportFileIcon} alt=" " />
        </IconButton>
      </Box>
      <ListTable
          headers={headers}
          data={state.staticElement}
          columns={columns}
      />
    </Box>
  )
}

export default MatchingDetailsPage;
