import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Controls,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import "./flow.css";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 200;
const nodeHeight = 100;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export function Flow({ FlowData }) {
  const formatDatafromNode = (FlowData) => {
    const nodes = FlowData.workflowSteps.map((nodes, i) => ({
      id: nodes._id,

      position: { x: 0, y: 0 },
      data: { label: `${nodes.action.name} (by step ${nodes.order})` },
      type:
        nodes.action.type === "PROXY" || nodes.action.type === "PROCESSING"
          ? "input"
          : "default",
      style:
        nodes.action.type === "PROXY"
          ? {
              border: `1px solid #3538CD`,
              backgroundColor: "#EEF4FF",
              color: "#3538CD",
            }
          : nodes.action.type === "PROCESSING"
          ? {
              border: `1px solid #10A1C0`,
              backgroundColor: "#F1FFFF",
              color: "#10A1C0",
            }
          : {
              border: `1px solid #DCAF10`,
              backgroundColor: "#FFFAEB",
              color: "#DCAF10",
            },
    }));
    return nodes;
  };
  const formatDatafromEge = (FlowData) => {
    const eges = FlowData.workflowSteps.map((ege, i) => {
      return {
        id: ege.order,
        source: ege.dependsOn,
        target: ege._id,
        label: ege.action.type,
        animated: true,
        type: "smoothstep",
      };
    });
    return eges;
  };

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodesCopy, setNodesCopy] = useNodesState([]);
  const [edgesCopy, setEdgesCopy] = useEdgesState([]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [setEdges]
  );
  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      "TB"
    );

    setNodesCopy([...layoutedNodes]);
    setEdgesCopy([...layoutedEdges]);
  }, [nodes, edges, setEdgesCopy, setNodesCopy]);

  useEffect(() => {
    setNodes(formatDatafromNode(FlowData));
    setEdges(formatDatafromEge(FlowData));
  }, [FlowData, setEdges, setNodes]);
  return (
    <>
      <ReactFlow
        nodes={nodesCopy}
        edges={edgesCopy}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView={(res) => {
          console.log(res);
        }}
      >
        <Controls />
      </ReactFlow>
    </>
  );
}
