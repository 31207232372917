import {useMemo} from "react";
import {useQuery, useMutation} from "react-query";
import {getStaticData, postStaticData,} from "../services/DataService";
import {useAppState} from "../contexts/AppContext";


export function useStaticStorageAPI(setStaticData) {

    const {handleOpenAlert} = useAppState();
    const staticStorageData = useQuery(["getStaticData"], getStaticData);

    const staticDataMutation = useMutation(postStaticData, {
        onSuccess: (data) => {
            setStaticData(data)
        },
        onError: () => {
            setStaticData(null);
            handleOpenAlert("error", "Oops, something went wrong");
        }
    });

    return useMemo(() => ({
        staticStorageData,
        staticDataMutation
    }), [staticStorageData, staticDataMutation])
}
