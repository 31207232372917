import React from "react";
import { Button } from "@mui/material";

const SaveButton = ({ handleDelete }) => {
  return (
    <Button
      onClick={handleDelete}
      variant="save"
      size="large"
    >
      Delete
    </Button>
  );
};
export default SaveButton;
