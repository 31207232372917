import React from 'react';
import ReactDropzone from 'react-dropzone';
import {Box, Typography} from "@mui/material";
import Folder from '../../assets/folder.svg';
import style from './Dropzone.module.css';

export const Dropzone = ({onDrop}) => {

  return(
    <ReactDropzone accept={{
        'text/html': ['.csv'],
    }} onDrop={onDrop}>
            {({
                getRootProps,
                getInputProps
            }) => {
                return (
                    <Box
                        className={style.fileDropHolder}
                        {...getRootProps({
                            onClick: (e) => {

                            },
                        })}
                    >
                        <input {...getInputProps()} />
                        <Box className={style.folder}>
                            <img style={{width: "77px"}} src={Folder} alt="" />
                        </Box>
                        <Typography variant={'subtitle1'} className={style.title}>
                          Allow formats: CSV
                        </Typography>
                    </Box>
                );
            }}
        </ReactDropzone>
  )
}
