import React, { useState, useRef, useMemo } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles, withStyles } from "@mui/styles";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import ListTable from "../../components/ListTable/ListTabel";
import { useAppState } from "../../contexts/AppContext";
import { getLogs } from "../../services/DataService";
import { convertTimestamp } from "../../utils/constants";
import style from "./styles.module.css";
import { useEffect } from "react";

const useInputStyle = makeStyles({
  text: {
    "& .MuiInputBase-root": {
      height: 50,
      borderRadius: "8px",
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },
});
const CustomPagination = withStyles({
  root: {
    "& .MuiToolbar-root": {
      display: "flex",
      alignItems: "baseline",
    },
  },
})((props) => <TablePagination {...props} />);

const WorkflowStepsLogs = ({ workflowSteps }) => {
  const inputStyle = useInputStyle();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const lastTwoWeek = new Date();
  lastTwoWeek.setDate(lastTwoWeek.getDate() - 14);

  const [dateFrom, setDateFrom] = useState(yesterday);
  const [dateTo, setDateTo] = useState(new Date());
  const [selectedWorkflowStep, setSelectedWorkflowStep] = useState([" "]);
  const [logsData, setLogsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [level, setLevel] = useState(["info"]);
  const [state, setState] = useState(["in"]);
  const firstRun = useRef(true);

  const { config } = useAppState();
  const levels = useMemo(() => config?.config?.log?.level, [config]);
  const states = useMemo(() => config?.config?.log?.state, [config]);

  const queryClient = useQueryClient();
  const getWorkflowStepLogs = useMutation(getLogs, {
    onSuccess: (data) => {
      setLogsData(data);
    },
    onError: (err) => {
      console.log(err);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  useEffect(() => {
    if (firstRun.current) {
      getWorkflowStepLogs.mutate({
        entityIds: selectedWorkflowStep[0] === " " ? [] : selectedWorkflowStep,
        entityType: "workflowStep",
        page: 1,
        limit: rowsPerPage,
        from: Math.floor(+dateFrom / 1000),
        to: Math.floor(+dateTo / 1000),
        filters: {
          level: level,
          state: state,
        },
      });
      firstRun.current = false;
    }
    // eslint-disable-next-line
  }, []);
  const handleSearch = () => {
    getWorkflowStepLogs.mutate({
      entityIds: selectedWorkflowStep[0] === " " ? [] : selectedWorkflowStep,
      entityType: "workflowStep",
      page: 1,
      limit: rowsPerPage,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
      filters: {
        level: level,
        state: state,
      },
    });
    setPage(0);
  };
  const handleChangeSelectedWorkflowStep = (event) => {
    setSelectedWorkflowStep(event.target.value);
  };
  const handleChangeLevel = (event) => {
    setLevel(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const setRequestType = (level) => {
    if (level === "error") {
      return style.level_error;
    } else if (level === "warn") {
      return style.level_warn;
    } else {
      return style.level_info;
    }
  };
  const headers = [
    {
      lable: "Timestamp",
      align: "left",
      style: {
        width: "180px",
      },
      icon: true
    },
    {
      lable: "Level",
      align: "left",
      style: {
        width: "100px",
      },
    },
    {
      lable: "In/Out",
      align: "left",
      style: {
        width: "100px",
      },
    },
    {
      lable: "Message",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => convertTimestamp(element.time),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
    {
      accessor: (element) => (
        <Typography variant={'subtitle1'} className={setRequestType(element.level)}>
          {element.level}
        </Typography>
      ),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
    {
      accessor: (element) => element.state,
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
    {
      accessor: (element) => JSON.stringify(element.message),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
  ];
  const handleChangePage = (event, newPage) => {
    getWorkflowStepLogs.mutate({
      entityIds: selectedWorkflowStep[0] === " " ? [] : selectedWorkflowStep,
      entityType: "workflowStep",
      page: newPage + 1,
      limit: rowsPerPage,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
      filters: {
        level: level,
        state: state,
      },
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getWorkflowStepLogs.mutate({
      entityIds: selectedWorkflowStep[0] === " " ? [] : selectedWorkflowStep,
      entityType: "workflowStep",
      page: 1,
      limit: event.target.value,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
      filters: {
        level: level,
        state: state,
      },
    });
    setPage(0);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Date From</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              placeholder="Date From"
              value={dateFrom}
              minDate={lastTwoWeek}
              maxDate={yesterday}
              onChange={(newValue) => {
                setDateFrom(newValue);
              }}
              renderInput={(params) => (
                <TextField className={inputStyle.text} {...params} />
              )}
              disabled={page >= 1 ? true : false}
            />
          </LocalizationProvider>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Date To</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              placeholder="Date To"
              value={dateTo}
              minDate={lastTwoWeek}
              maxDate={new Date()}
              onChange={(newValue) => {
                setDateTo(newValue);
              }}
              renderInput={(params) => (
                <TextField className={inputStyle.text} {...params} />
              )}
              disabled={page >= 1 ? true : false}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{ maxWidth: "200px", width: "100%" }}
          className={style.create_source_item}
        >
          <Typography variant={'subtitle2'}>Step</Typography>
          <Box className={inputStyle.text}>
            <FormControl fullWidth>
              <Select
                value={selectedWorkflowStep}
                onChange={handleChangeSelectedWorkflowStep}
                label="Selected Step"
                multiple
                disabled={page >= 1 ? true : false}
              >
                <MenuItem value={" "}>All Workflow Steps</MenuItem>
                {workflowSteps.map((e) => {
                  return (
                    <MenuItem key={e._id} value={e._id}>
                      {e.action.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{ maxWidth: "100px", width: "100%" }}
          className={style.create_source_item}
        >
          <Typography variant={'subtitle2'}>Level</Typography>
          <Box className={inputStyle.text}>
            <FormControl fullWidth>
              <Select
                value={level}
                onChange={handleChangeLevel}
                label="Selected Source"
                multiple
                disabled={page >= 1 ? true : false}
              >
                {levels &&
                  Object.entries(levels)?.map(([key, value]) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{ maxWidth: "100px", width: "100%" }}
          className={style.create_source_item}
        >
          <Typography variant={'subtitle2'}>State</Typography>
          <Box className={inputStyle.text}>
            <FormControl fullWidth>
              <Select
                value={state}
                onChange={handleChangeState}
                label="Selected Source"
                multiple
                disabled={page >= 1 ? true : false}
              >
                {states &&
                  Object.entries(states)?.map(([key, value]) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ padding: "16px 0 0 0" }}>
          <SearchButton handleSearch={handleSearch} />
        </Box>
      </Box>
      <ListTable headers={headers} data={logsData.logs} columns={columns} />
      <CustomPagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={logsData.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
export default WorkflowStepsLogs;
