import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    TextField,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import ReactJson from 'react-json-view'
import {getLink} from "../../../services/DataService";
import ListTable from "../../../components/ListTable/ListTabel";
import SearchButton from "../../../components/SaveModalButtons/SearchButton";
import CancelButton from "../../../components/SaveModalButtons/CancelButton";
import exportFileIcon from "../../../assets/exportFileIcon.svg";
import {Alert} from "../../../utils/alert";
import style from './styles.module.css';
import ConnectCollectionModal from "./ConnectCollectionModal";
import {useStaticStorageAPI} from "../../../hooks/useStaticStorageAPI";

const CustomWidthTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        background: "white",
        boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        width: '100%',
        maxHeight: "400px",
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});


const MatchingPage = ({state}) => {
    const [selectedStorages, setSelectedStorages] = useState(state?.selectedStorages || [])
    const [searchTerm, setSearchTerm] = useState(state?.searchTerm || '');
    const [column, setColumn] = useState(state?.column || 'email');
    const [staticData, setStaticData] = useState(state?.staticData || null)
    const [alert, setAlert] = useState({
        isShow: false,
        type: "success",
        message: "Strategy created successfully",
    });
    const [showConnectCollectionModal, setShowConnectCollectionModal] = useState(false);
    const {staticStorageData: {data, isLoading, isError}, staticDataMutation} = useStaticStorageAPI(setStaticData);


    const navigation = useNavigate();

    const redirectAddNew = () => {
        handleOpenConnectCollectionModal();
    };
    const handleOpenConnectCollectionModal = () => setShowConnectCollectionModal(true);
    const handleCloseConnectCollectionModal = () => setShowConnectCollectionModal(false);

    const handleSearch = async () => {
        staticDataMutation.mutate({
            sources: selectedStorages,
            column: column,
            text: searchTerm
        })
    };

    const handleCloseAlert = () => {
        setAlert(false);
    };

    const handleChangeTerm = (event) => {
        setSearchTerm(event.target.value)
    }

    const handleSetId = (id, event) => {
        event.stopPropagation()
        if (!!selectedStorages?.includes(id)) {
            setSelectedStorages(selectedStorages?.filter((e) => e !== id))
        } else {
            setSelectedStorages([...selectedStorages, id])
        }
    }

    const handleSetAllId = () => {
        if (data?.every((e) => selectedStorages?.includes(e.name))) {
            setSelectedStorages([])
        } else {
            const allStorages = data?.map((e) => e.name)
            setSelectedStorages(allStorages)
        }
    }

    const handleChangeSelectedColumn = (event) => {
        setColumn(event.target.value)
    }

    const handleClearSearch = () => {
        setStaticData(null);
    }

    const openId = (element) => {
        if (staticData && staticData[element.name]?.length) {
            navigation(`/databases/matching/${element.name}`, {
                state: {
                    staticElement: staticData[element.name],
                    name: element.name,
                    staticData: staticData,
                    column: column,
                    searchTerm: searchTerm,
                    selectedStorages: selectedStorages
                }
            })
        }
    }
    const handleGetLink = async (event, name) => {
        event.stopPropagation();
        await getLink(name).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${name}.csv`)
            document.body.appendChild(link)
            link.click()
        })
    }
    const headers = [
        {
            lable: <Checkbox
                color="error"
                checked={data?.every((e) => selectedStorages?.includes(e.name))}
                onClick={handleSetAllId}/>,
            align: "left",
            style: {
                width: '20px'
            }
        },
        {
            lable: "Collection",
            align: "left",
            style: {
                width: '200px'
            }
        },
        {
            lable: "Instance",
            align: "left",
            style: {
                width: '200px'
            }
        },
        {
            lable: "Total Found",
            align: "left",
            style: staticData ? {} : {
                display: "none"
            }
        },
        {
            lable: " ",
            align: "right",
            style: staticData ? {} : {
                display: "none"
            }
        },
        {
            lable: "Total Records",
            align: "left",
            style: staticData ? {
                display: "none"
            } : {}
        },
        {
            lable: "Total Size",
            align: "left",
            style: staticData ? {
                display: "none"
            } : {}
        },
        {
            lable: "Data Preview",
            align: "center",
            style: staticData ? {
                display: "none"
            } : {}
        },
    ];
    const columns = [
        {
            accessor: (element) => {
                const isChecked = !!selectedStorages?.includes(element?.name)
                return (
                    <Checkbox color="error" checked={isChecked} onClick={(event) => handleSetId(element?.name, event)}/>
                )
            },
            Cell: (event, storage) => {
            },
            align: "left",
        },
        {
            accessor: (element) => element["title"],
            Cell: (event, storage) => {
            },
            align: "left",
            style: {
                width: '200px'
            }
        },
        {
            accessor: (element) => element["instance"],
            Cell: (event, storage) => {
            },
            align: "left",
            style: {
                width: '200px'
            }
        },
        {
            accessor: (element) => {
                if (staticData && staticData[element.name]) {
                    return `${new Intl.NumberFormat().format(staticData[element?.name]?.length)}`
                } else {
                    return '-'
                }
            },
            Cell: (event, storage) => {
            },
            align: "left",
            style: staticData ? {} : {
                display: "none"
            }
        },
        {
            accessor: (element) => new Intl.NumberFormat().format(element["totalRecords"]),
            Cell: (event, storage) => {
            },
            align: "left",
            style: staticData ? {
                display: "none"
            } : {}
        },
        {
            accessor: (element) =>
                <IconButton onClick={(event) => handleGetLink(event, element.name)}
                            disabled={!staticData || !staticData[element.name]?.length}>
                    <img src={exportFileIcon} alt=" "/>
                </IconButton>,
            Cell: (event, storage) => {
            },
            align: "right",
            style: staticData ? {} : {
                display: "none"
            }
        },
        {
            accessor: (element) => element["totalSize"],
            Cell: (event, storage) => {
            },
            align: "left",
            style: staticData ? {
                display: "none"
            } : {}
        },
        {
            accessor: (element) => {
                return (
                    <CustomWidthTooltip
                        title={<ReactJson src={element.example}
                                          name="Data"
                                          enableClipboard={false}
                                          iconStyle="circle"
                                          theme="summerfruit:inverted"
                                          displayObjectSize={false}
                                          displayDataTypes={false}
                        />}
                        arrow
                        disableFocusListener
                        placement="left"
                    >
                        <Box className={style.tooltipWrapper}>
                            <Box className={style.tooltipContainer}>
                                {"{ ... }"}
                            </Box>
                        </Box>
                    </CustomWidthTooltip>
                )
            },
            Cell: (event, storage) => {
            },
            align: "left",
            style: staticData ? {
                display: "none"
            } : {
                height: "1px",
                padding: 0
            }
        },
    ];
    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress/>
            </Box>
        );
    }
    if (isError) {
        return <h1>Something wrong</h1>;
    }
    return (
        <>
            <Box className="wrapper-source-list">
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Box
                            sx={{maxWidth: "200px", width: "100%"}}
                            className={style.create_source_item}
                        >
                            <Typography variant={'subtitle2'}>Attribute</Typography>
                            <Box sx={{
                                "& .MuiInputBase-root": {
                                    height: 50,
                                    borderRadius: "8px",
                                },
                                "& legend": {display: "none"},
                                "& fieldset": {top: 0},
                            }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={column}
                                        onChange={handleChangeSelectedColumn}
                                        label="Selected Column"
                                    >
                                        <MenuItem value={"email"}>Email</MenuItem>
                                        <MenuItem value={"phone"}>Phone</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box className={style.InputStyle}>
                            <Typography variant={'subtitle2'}>Term</Typography>
                            <TextField sx={{
                                "& .MuiInputBase-root": {
                                    height: 50,
                                    borderRadius: "8px",
                                },
                                "& legend": {display: "none"},
                                "& fieldset": {top: 0},
                            }} onChange={handleChangeTerm} value={searchTerm} placeholder="Term"/>
                        </Box>
                        <Box sx={{padding: "16px 0 0 0"}}>
                            <SearchButton handleSearch={handleSearch}
                                          disabled={!(selectedStorages.length > 0 && searchTerm.length >= 5)}/>
                        </Box>
                        {staticData && <Box sx={{padding: "16px 0 0 0"}}>
                            <CancelButton handleCloseSaveModal={handleClearSearch}/>
                        </Box>}
                    </Box>
                    <Box>
                        <Button
                            sx={{
                                minWidth: "140px",
                                borderRadius: "40px",
                                textTransform: "capitalize",
                                fontWeight: 500,
                                fontSize: "14px",
                                backgroundColor: "#DF4036",
                                "&:hover": {
                                    backgroundColor: "rgb(204,38,54)",
                                },
                                height: "39.97px",
                            }}
                            onClick={redirectAddNew}
                            variant="contained"
                            size="large"
                        >
                            Connect Collection
                        </Button>
                    </Box>
                </Box>
                <Box className="wrapper-source-list">
                    <ListTable
                        headers={headers}
                        data={data}
                        columns={columns}
                        openId={openId}
                    />
                </Box>
            </Box>
            {staticDataMutation.isLoading &&
                <Box className={style.loadingWrapper}>
                    <Box className={style.loading}>
                        <CircularProgress/>
                    </Box>
                </Box>
            }
            <Snackbar
                open={alert.isShow}
                autoHideDuration={3500}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alert.type}
                    sx={{width: "100%"}}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
            <Modal
                open={showConnectCollectionModal}
                onClose={handleCloseConnectCollectionModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <ConnectCollectionModal
                    handleCloseSaveModal={handleCloseConnectCollectionModal}
                />
            </Modal>
        </>
    )
}

export default MatchingPage;
