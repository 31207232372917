import React, { useState } from "react";

import { Box, Button, Tab, Tabs, Modal } from "@mui/material";
import TabPanel from "../../components/Tab/TabPanel";
import CustomModules from "./CustomModules";
import StaticModules from "./StaticModules";
import SaveCustomModuleModal from "./SaveCustomModuleModal";
import { getCustomModule } from "../../services/DataService";
import style from "./style.module.css";

const ModulesPage = () => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const redirectIdCustomModule = async (module) => {
    const data = await getCustomModule(module._id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  return (
    <>
      <Box>
        <Box className={style.container_tabs}>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Static Modules" />
              <Tab label="Custom Modules" />
            </Tabs>
          </Box>
          {value === 1 && (
            <Button onClick={redirectAddNew} variant="addNew" size="large">
              Add Module
            </Button>
          )}
        </Box>
        <TabPanel value={value} index={0}>
          <StaticModules />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CustomModules redirectIdCustomModule={redirectIdCustomModule} />
        </TabPanel>
      </Box>
      <Modal
        open={showSaveModal}
        onClose={handleCloseSaveModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <SaveCustomModuleModal
          handleCloseSaveModal={handleCloseSaveModal}
          defaultValues={defaultValues}
        />
      </Modal>
    </>
  );
};

export default ModulesPage;
