import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {getConfig} from "../services/DataService";

const AppStateContext = React.createContext(undefined);
const AppDispatchContext = React.createContext(undefined);

const AppProvider = ({children}) => {
    const [config, setConfig] = useState();
    const [alert, setAlert] = useState({
        isShow: false,
        type: "success",
        message: "Source created successfully",
    });

    const handleCloseAlert = () => {
        setAlert(false);
    };
    const handleOpenAlert = (type, message) => {
        setAlert({isShow: true, type: type, message});
    }

    const configRequest = useQuery(["generalConfig"], () => getConfig(), {
        keepPreviousData: true,
        cacheTime: "Infinity",
    });

    useEffect(() => {
        if (configRequest.isSuccess) {
            setConfig(configRequest.data);
        }
    }, [configRequest.isSuccess, configRequest.data]);

    return (
        <AppStateContext.Provider
            value={{
                config,
                handleCloseAlert,
                handleOpenAlert,
                alert
            }}
        >
            <AppDispatchContext.Provider
                value={{
                    setConfig,
                }}
            >
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

const useAppState = () => {
    const context = React.useContext(AppStateContext);
    if (context === undefined) {
        throw new Error("useAppState must be used within a AppProvider");
    }
    return context;
};

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error("useAppDispatch must be used within a AppProvider");
    }
    return context;
};

export {AppProvider, useAppState, useAppDispatch};
