import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TablePagination from "@mui/material/TablePagination";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { getLogs } from "../../services/DataService";
import SearchButton from "../../components/SaveModalButtons/SearchButton";
import ListTable from "../../components/ListTable/ListTabel";
import { convertTimestamp, timeZoneChanger } from "../../utils/constants";
import style from "./styles.module.css";

const PusherLogs = ({ pusher }) => {
  const yesterday = new Date();
  yesterday.setMinutes(yesterday.getMinutes() - 5);

  const lastTwoWeek = new Date();
  lastTwoWeek.setDate(lastTwoWeek.getDate() - 14);

  const [dateFrom, setDateFrom] = useState(yesterday);
  const [dateTo, setDateTo] = useState(new Date());
  const [logsData, setLogsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const firstRun = useRef(true);
  const firstTime = useRef(true);
  const [selectedPusher, setSelectedPusher] = useState([" "]);

  const queryClient = useQueryClient();

  const getPusherLogs = useMutation(getLogs, {
    onSuccess: (data) => {
      setLogsData(data);
    },
    onError: (err) => {
      console.log(err);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  useEffect(() => {
    if (firstRun.current) {
      getPusherLogs.mutate({
        parentIds: selectedPusher[0] === " " ? [] : selectedPusher,
        entityType: "pusher",
        page: 1,
        limit: rowsPerPage,
        from: Math.floor(+dateFrom / 1000),
        to: Math.floor(+dateTo / 1000),
      });
      firstRun.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    getPusherLogs.mutate({
      parentIds: selectedPusher[0] === " " ? [] : selectedPusher,
      entityType: "pusher",
      page: 1,
      limit: rowsPerPage,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
    });
    setPage(0);
  };

  const handleChangeSelectedPusher = (event) => {
    if (
      selectedPusher.includes(" ") &&
      event.target.value.length > 1 &&
      event.target.value.includes(" ")
    ) {
      setSelectedPusher(event.target.value.filter((e) => e !== " "));
    } else if (
      !selectedPusher.includes(" ") &&
      event.target.value.includes(" ")
    ) {
      setSelectedPusher([" "]);
    } else if (pusher.length === event.target.value.length) {
      setSelectedPusher([" "]);
    } else {
      setSelectedPusher(event.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    getPusherLogs.mutate({
      parentIds: selectedPusher[0] === " " ? [] : selectedPusher,
      entityType: "pusher",
      page: newPage + 1,
      limit: rowsPerPage,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
     
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getPusherLogs.mutate({
      parentIds: selectedPusher[0] === " " ? [] : selectedPusher,
      page: 1,
      entityType: "pusher",
      limit: event.target.value,
      from: Math.floor(+dateFrom / 1000),
      to: Math.floor(+dateTo / 1000),
    });
    setPage(0);
  };

  const setRequestType = (level) => {
    if (level === "error") {
      return style.level_error;
    } else if (level === "warn") {
      return style.level_warn;
    } else {
      return style.level_info;
    }
  };

  const headers = [
    {
      lable: "Timestamp",
      align: "left",
      style: {
        width: "180px",
      },
      icon: true,
    },
    {
      lable: "Level",
      align: "left",
      style: {
        width: "100px",
      },
    },
    {
      lable: "Message",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => convertTimestamp(element.time),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
    {
      accessor: (element) => (
        <Typography
          variant={"subtitle1"}
          className={setRequestType(element.level)}
        >
          {element.level}
        </Typography>
      ),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
    {
      accessor: (element) => JSON.stringify(element.message),
      Cell: (event, source) => {},
      align: "left",
      style: {
        padding: "10px 16px 10px 16px",
      },
    },
  ];

  if (logsData.length === 0) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Date From</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              placeholder="Date From"
              value={firstTime.current ? timeZoneChanger(dateFrom) : dateFrom}
              minDate={timeZoneChanger(lastTwoWeek)}
              maxDate={timeZoneChanger(yesterday)}
              timeZone={process.env.REACT_APP_TIME_ZONE}
              onChange={(newValue) => {
                setDateFrom(newValue);
                firstTime.current = false;
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={page >= 1}
            />
          </LocalizationProvider>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Date To</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              placeholder="Date To"
              value={firstTime.current ? timeZoneChanger(dateTo) : dateTo}
              minDate={timeZoneChanger(lastTwoWeek)}
              maxDate={timeZoneChanger(new Date())}
              onChange={(newValue) => {
                setDateTo(newValue);
                firstTime.current = false;
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={page >= 1}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{ maxWidth: "200px", width: "100%" }}
          className={style.create_source_item}
        >
          <Typography variant={"subtitle2"}>Sources</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={selectedPusher}
                onChange={handleChangeSelectedPusher}
                label="Selected Source"
                multiple
                disabled={page >= 1}
              >
                <MenuItem value={" "}>All Sources</MenuItem>
                {pusher.map((e) => {
                  return (
                    <MenuItem key={e._id} value={e._id}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ padding: "16px 0 0 0" }}>
          <SearchButton handleSearch={handleSearch} />
        </Box>
      </Box>
      <ListTable headers={headers} data={logsData.logs} columns={columns} />
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={logsData.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PusherLogs;
