import React from "react";
import { Box, Typography, FormControl, Select, MenuItem } from "@mui/material";
import CancelButton from "../../SaveModalButtons/CancelButton";
import SaveButton from "../../SaveModalButtons/SaveButton";
import style from "../../../pages/WorkflowsPage/styles.module.css";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

export const AddStepModal = ({
  action,
  handleChangeActions,
  dependsOn,
  handleChangeDependsOn,
  workflowSteps,
  actions,
  handleSaveSteps,
  handleClickBack,
}) => {

  const validationStep = () => {
    return !action;
  };
  return (
    <Box className={style.modal}>
      <Typography variant={"h2"} className={style.modal_title}>Add Action</Typography>
      <Box className={style.create_source_item} sx={{ width: "100%" }}>
        <Typography variant={'subtitle2'}>Action</Typography>
        <Box className={style.text}>
          <FormControl fullWidth>
            <Select
              value={action || ""}
              onChange={handleChangeActions}
              label="Select Action"
              displayEmpty
              renderValue={
                action !== "" && action !== null
                  ? undefined
                  : () => <Placeholder>Select action</Placeholder>
              }
            >
              {actions?.map((e) => (
                <MenuItem
                  disabled={
                    workflowSteps.length === 0 && e.type === "PUSH"
                  }
                  value={e._id}
                >
                  {e.name} ({e.type})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {workflowSteps.length > 0 && (
        <Box className={style.create_source_item} sx={{ width: "100%" }}>
          <Typography variant={'subtitle2'}>Depends on</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={dependsOn || ""}
                onChange={handleChangeDependsOn}
                label="Select Action"
                displayEmpty
                renderValue={
                  dependsOn !== "" && dependsOn !== null
                    ? undefined
                    : () => <Placeholder>Select depends</Placeholder>
                }
              >
                {workflowSteps.map((e, i) => {
                  return <MenuItem value={e._id}>Step {i + 1}</MenuItem>;
                })}
                <MenuItem value={null}>None</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
        <CancelButton handleCloseSaveModal={handleClickBack} />
        <SaveButton
          handlePost={() => handleSaveSteps(workflowSteps.length + 1)}
          validation={validationStep}
        />
      </Box>
    </Box>
  );
};
