import {
  Box,
  Typography,
  Tooltip,
} from "@mui/material";

import WarningIcon from '@mui/icons-material/Warning';

import style from "../../pages/WorkflowsPage/styles.module.css";

const msg = "Step action has inactive target"

const FormInactiveStep = ({ item }) => {
  return (
    <Box sx={{ display: "inline-flex", flexDirection: 'row'  }}>
      <Typography variant={'subtitle1'} className={style.InactiveStepTitle}> Step {item + 1} </Typography>
      <Tooltip title={msg} placement="right">
        <WarningIcon fontSize="small"  className={style.warning_icon}/>
      </Tooltip>
    </Box>
  );
}

export { FormInactiveStep }
