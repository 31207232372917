import { useMemo } from "react";
import { useQueryClient, useMutation } from "react-query";

import { useLogout } from "./useLogout";
import { useAppState } from "../contexts/AppContext";
import { getStatistics } from "../services/DataService";

export function useStatisticAPI(setStatistics) {
  const { handleOpenAlert } = useAppState();

  const { logout } = useLogout();
  const queryClient = useQueryClient();

  const getAnalyticStatistic = useMutation(getStatistics, {
    onSuccess: (data) => {
      setStatistics(data);
      console.log(data, "datadata");
    },

    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("get");
    },
  });

  return useMemo(
    () => ({
      getAnalyticStatistic,
    }),
    [getAnalyticStatistic]
  );
}
