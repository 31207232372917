import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";

import _isEmpty from 'lodash/isEmpty'

import {
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Snackbar,
  IconButton,
  Modal,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import BackButton from "../../components/SaveModalButtons/BackButton";
import EditButton from "../../components/SaveModalButtons/EditButton";

import {
  editWorkflows,
  postWorkflowSteps,
  getActions,
  editWorkflowSteps,
  deleteWorkflowSteps,
	getSources,
} from "../../services/DataService";

import { AddStepModal } from "../Modals/AddStepModal/AddStepModal";
import ModalBack from "../Modals/ModalBack/ModalBack";

import DeleteIcon from "../../assets/DeleteSource.svg";
import { Alert } from "../../utils/alert";

import style from "../../pages/WorkflowsPage/styles.module.css";

import { FormInactiveStep } from "./FormInactiveStep";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const checkActiveStateOfWorkflowStepActionTarget = step => {
  if (!_isEmpty(step.action.target)) {
    return !step.action.target.active
  } else {
    return false
  }
}

const FormUpdateWorkflow = ({ PrevData, id }) => {

  const [name, setName] = useState(PrevData?.name);
  const [source, setSource] = useState([
    ...PrevData?.sources?.map((e) => e._id),
  ]);
  const [checked, setChecked] = useState(PrevData?.active);
  const [workflowSteps, setWorkflowSteps] = useState([
    ...PrevData?.workflowSteps,
  ]);
  const [action, setActions] = useState("");
  const [dependsOn, setDependsOn] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalBack, setOpenModalBack] = useState(false);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Strategy created successfully",
  });

  const { data: sources } = useQuery(["getSources"], getSources);
  const { data: actions } = useQuery(["getActions"], getActions);

  const navigation = useNavigate();

  const validation = () => {
    if (
      name === PrevData?.name &&
      checked === PrevData?.active &&
      JSON.stringify(source) ===
        JSON.stringify(PrevData?.sources?.map((e) => e._id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const queryClient = useQueryClient();
  const data = useMutation(editWorkflows, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Workflow edited successfully");
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const addStep = useMutation(postWorkflowSteps, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Step created successfully");
      setWorkflowSteps([...data.workflowSteps]);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const EditStep = useMutation(editWorkflowSteps, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Step edited successfully");
      setWorkflowSteps([...data.workflowSteps]);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const DeleteStep = useMutation(deleteWorkflowSteps, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Step deleted successfully");
      setWorkflowSteps([...data.workflowSteps]);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.value);
  };
  const handleSaveWorkflow = () => {
    data.mutate({
      data: { name: name, active: checked, sources: [...source] },
      id: id,
    });
  };
  const handleSaveSteps = (order) => {
    addStep.mutate({
      data: {
        actionId: action,
        order: order,
        dependsOn: dependsOn ? dependsOn : null,
      },
      id: id,
    });
    handleCloseModal();
  };
  const handleEditStepActions = (event, workflowId, stepId) => {
    EditStep.mutate({
      data: { actionId: event.target.value },
      workflowId: workflowId,
      stepId: stepId,
    });
  };
  const handleEditDependsOn = (event, workflowId, stepId) => {
    EditStep.mutate({
      data: { dependsOn: event.target.value },
      workflowId: workflowId,
      stepId: stepId,
    });
  };
  const handleBack = () => {
    if (
      name === PrevData?.name &&
      checked === PrevData?.active &&
      JSON.stringify(source) ===
        JSON.stringify(PrevData?.sources?.map((e) => e._id))
    ) {
      navigation("/workflows");
    } else {
      handleOpenModalBack();
    }
  };
  const handleOpenModalBack = () => setOpenModalBack(true);
  const handleCloseModalBack = () => setOpenModalBack(false);

  const navigateToPage = () => {
    navigation("/workflows");
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleChangeActions = (event) => {
    setActions(event.target.value);
  };
  const handleChangeDependsOn = (event) => {
    setDependsOn(event.target.value);
  };
  const handleDeleteStep = (step) => {
    DeleteStep.mutate({ WorkflowId: id, stepId: step._id });
  };
  return (
    <>
      <Box className="container">
        <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Name</Typography>
          <TextField
            placeholder={"Enter name"}
            value={name}
            onChange={handleChangeName}
            variant="outlined"
          />
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Source</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={source || ""}
                onChange={handleChangeSource}
                label="Source"
                multiple
                displayEmpty
                renderValue={
                  source.length !== 0 && source !== null
                    ? undefined
                    : () => <Placeholder>Select source</Placeholder>
                }
              >
                {sources?.data?.map((e) => (
                  <MenuItem key={e?._id} value={e?._id} disabled={e?.active !== true}>
                    {e?.name} {!e.active && "(Not active)"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Active</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={checked}
                onChange={handleChangeChecked}
                label="Active"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {workflowSteps?.map((step, i) => {
          const isInactive = checkActiveStateOfWorkflowStepActionTarget(step)
          return (
            <>
            {isInactive ? <FormInactiveStep item={i} /> : <Typography variant={'subtitle1'} className={style.StepTitle}>Step {i + 1}</Typography> }
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box className={style.create_source_item}>
                  <Typography variant={'subtitle2'}>Action</Typography>
                  <Box className={style.text}>
                    <FormControl fullWidth>
                      <Select
                        value={step.actionId}
                        onChange={(event) => {
                          handleEditStepActions(
                            event,
                            step.workflowId,
                            step._id
                          );
                        }}
                        label="Action"
                      >
                        {actions?.map((e) => (
                          <MenuItem
                            disabled={
                              workflowSteps.length === 1 && e.type === "PUSH"
                                ? true
                                : false
                            }
                            value={e._id}
                          >
                            {e.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                  <IconButton
                  sx={{marginTop: '16px'}}
                    aria-label="delete"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteStep(step);
                    }}
                  >
                    <img src={DeleteIcon} alt=" " />
                  </IconButton>
              </Box>
              {step.dependsOn && (
                <>
                  <Box className={style.create_source_item}>
                    <Typography variant={'subtitle2'}>
                      Dependes on
                    </Typography>
                    <Box className={style.text}>
                      <FormControl fullWidth>
                        <Select
                          value={step.dependsOn}
                          className="createSource-form-item-container"
                          onChange={(event) => {
                            handleEditDependsOn(
                              event,
                              step.workflowId,
                              step._id
                            );
                          }}
                          label="Depends on Step"
                        >
                          {workflowSteps.map((e, i) => {
                            return (
                              <MenuItem
                                disabled={e._id === step._id}
                                value={e._id}
                              >
                                Step {i + 1}
                              </MenuItem>
                            );
                          })}
                          <MenuItem value={null}>None</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </>
              )}
            </>
          );
        })}
        <Button
          variant="addStep"
          size="large"
          onClick={handleOpenModal}
        >
          <AddIcon />
          Add Step
        </Button>
        <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
          <BackButton handleCloseSaveModal={handleBack} />
          <EditButton handlePost={handleSaveWorkflow} validation={validation} />
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <AddStepModal
            action={action}
            handleClickBack={handleCloseModal}
            handleChangeActions={handleChangeActions}
            dependsOn={dependsOn}
            handleChangeDependsOn={handleChangeDependsOn}
            workflowSteps={workflowSteps}
            actions={actions}
            handleSaveSteps={handleSaveSteps}
          />
        </Modal>
        <Modal open={openModalBack} onClose={handleCloseModalBack}>
          <ModalBack
            handleClickBack={handleCloseModalBack}
            handleClickOk={navigateToPage}
          />
        </Modal>
        <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
export default FormUpdateWorkflow;
