import React from "react";
import { Box, Typography } from "@mui/material";
import style from "./styleConfig.module.css";

const ExplanationConfig = ({handleOpenAlert}) => {
  const copyToClipboardSegma = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`{“email”:{“attribute”:“email”,“type”:“email”},“firstname”:{“attribute”:“firstname”,“type”:“string”}}`).then(() => {
      handleOpenAlert("success", "Configuration copied to clipboard")
    });
  };
  const copyToClipboardArray = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`{“email”:{“attribute”:“email”},“firstname”:{“attribute”:“firstname”},“lastname”:{“attribute”:“last222name”}}`).then(() => {
      handleOpenAlert("success", "Configuration copied to clipboard")
    });
  };
  return (
    <Box className={style.container}>
      <Typography variant={'subtitle1'} className={style.text}>
      <div className={style.copySection} onClick={copyToClipboardSegma}>{`Segma example:`}<br/><Typography>{`{“email”:{“attribute”:“email”,“type”:“email”},“firstname”:{“attribute”:“firstname”,“type”:“string”}}`}</Typography></div>
      <div className={style.copySection} onClick={copyToClipboardArray}>{` Array, object example:`}<br/> <Typography>{`{“email”:{“attribute”:“email”},“firstname”:{“attribute”:“firstname”},“lastname”:{“attribute”:“last222name”}}`}</Typography></div>
      </Typography>
    </Box>
  );
};
export default ExplanationConfig;
