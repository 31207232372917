import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import DeleteIcon from "../../../assets/DeleteSource.svg";
import EditIcon from "../../../assets/EditSource.svg";
import ExplanationTimezone from "../../Explanation/ExplanationConfig/ExplanationTimezone";
const SourcesListTable = ({
  headers,
  data,
  handleDelete,
  redirectId,
  openId,
  columns,
  openAction,
  underField,
  onRowClick
}) => {
  // console.log(data,'data')
  const calculateSummedData = () => {
    return data.reduce((accumulator, currentObj) => {
      Object.keys(currentObj).forEach(key => {
        accumulator[key] = (accumulator[key] || 0) + currentObj[key];
      });
      return accumulator;
    }, {});
  };


  const summedData = calculateSummedData();
  return (
    <TableContainer variant="custom" sx={{ minWidth: 650 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, i) => {
              return (
                <TableCell
                  key={i}
                  sx={
                    header?.style
                      ? header.style
                      : header === "Steps" && { width: "30%" }
                  }
                  variant="customBold"
                  align={i === 0 ? "" : header.align}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {header.lable}
                    {header.icon && (
                      <Tooltip
                        title={<ExplanationTimezone />}
                        placement={"top"}
                        arrow
                        children={
                          <InfoOutlinedIcon
                            sx={{ ml: 1 }}
                            color={"warning"}
                            fontSize={"small"}
                          />
                        }
                      />
                    )}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!data?.length &&
            data?.map((element, i) => (
              <React.Fragment key={i}>
                <TableRow
                  key={element._id}
                  onClick={() => onRowClick(element._id.name)} 
                  variant="custom"
                >
                  {columns.map((e, i) => {
                    return (
                      <TableCell
                        key={i}
                        variant={
                          typeof e.accessor(element) === "string"
                            ? "custom"
                            : ""
                        }
                        sx={e.style ? e.style : ""}
                        align={i === 0 ? "" : e.align}
                        onClick={(event) => {
                          e.Cell(event, element);
                        }}
                      >
                        {e.accessor(element)}
                      </TableCell>
                    );
                  })}
                  {(handleDelete || redirectId) && (
                    <TableCell align="center">
                      {redirectId && (
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            redirectId(element);
                          }}
                        >
                          <img src={EditIcon} alt="" />
                        </IconButton>
                      )}
                      {handleDelete && (
                        <IconButton
                          aria-label="delete"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(element);
                          }}
                        >
                          <img src={DeleteIcon} alt="" />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
                {openAction === element._id
                  ? underField && underField(element)
                  : null}
              </React.Fragment>
            ))}
          <TableRow>
            <TableCell variant="customBold">Total count</TableCell>
            <TableCell variant="customBold">{summedData.today}</TableCell>
            <TableCell variant="customBold">{summedData.todayDuplicates}</TableCell>
            <TableCell variant="customBold">{summedData.week}</TableCell>
            <TableCell variant="customBold">{summedData.weekDuplicates}</TableCell>
            <TableCell variant="customBold">{summedData.lastDays}</TableCell>
            <TableCell variant="customBold">{summedData.lastDaysDuplicates}</TableCell>
            <TableCell variant="customBold">{summedData.total}</TableCell>
            <TableCell variant="customBold">{summedData.totalDuplicates}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SourcesListTable;