import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useMutation } from "react-query";
import { Box, Typography, TextField, Button, Snackbar } from "@mui/material";

import { Alert } from "../../utils/alert";

import { signIn } from "../../services/DataService";
import style from "./style.module.css";

const appName = process.env.REACT_APP_NAME

const LoginPage = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Strategy created successfully",
  });

  const formRef = useRef(null);
  const navigate = useNavigate();

  const settingToken = (response) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const cookies = new Cookies();
    const token = response.token;
    cookies.set("tokenDC", token, {
      expires: tomorrow,
    });
    navigate("/dashboards");
  };

  const signInMutation = useMutation(signIn, {
    onSuccess: (response) => {
      settingToken(response);
    },
    onError: () => {
      handleOpenAlert("error", "Invalid email or password");
    },
  });

  const handleSignIn = async (data) => {
    signInMutation.mutate({
      email: data.email,
      password: data.password,
    });
  };

  const SignIn = async () => {
    settingToken(await handleSignIn({ email: login, password: password }));
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      SignIn();
    }
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.focus();
    }
  }, []);

  return (
    <>
      <form
        ref={formRef}
        className={style.pageLoginWraper}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        onSubmit={(e) => {
          e.preventDefault();
          SignIn();
        }}
      >
        <Box className={style.loginFormWrapper}>
          <Box className={style.header}>
            <Typography variant={"h2"} className={`${style.logo} ${style.red}`}>
            {appName  && `${appName}.`}data
            </Typography>
            <Typography
              variant={"h2"}
              className={`${style.logo} ${style.white}`}
            >
              concepts
            </Typography>
          </Box>
          <Typography className={style.title}>Login</Typography>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Name</Typography>
            <TextField
              placeholder={"Enter name"}
              value={login}
              onKeyDown={handleKeyDown}
              onChange={(event) => {
                setLogin(event.target.value);
              }}
              variant="outlined"
            />
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Password</Typography>
            <TextField
              placeholder={"Enter password"}
              value={password}
              type="password"
              onKeyDown={handleKeyDown}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              variant="outlined"
            />
          </Box>
          <Button type="submit" variant="auth" size="large">
            Login
          </Button>
        </Box>
        <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </form>
    </>
  );
};

export default LoginPage;
