import React, { useState, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";

import {
  Box,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Button,
} from "@mui/material";

import { monthLabels, daysLabels } from "../../../utils/constants";

import style from "./style.module.css";

const WorkflowChartStatistic = ({ AllData }) => {
  const [valueIn, setValueIn] = useState(0);
  const [valueOut, setValueOut] = useState(0);
  const [AllDataIn, setAllDataIn] = useState([]);
  const [AllDataOut, setAllDataOut] = useState([]);
  const [SelectedWorkflow, setSelectedWorkflow] = useState("1");
  const [skipZero, setSkipZero] = useState(false);

  const filterData = useMemo(() => {
    return AllData.filter(
      (elem) =>
        !(
          elem.AllMonthIn === 0 &&
          elem.AllTodayIn === 0 &&
          elem.AllWeekIn === 0
        )
    );
  }, [AllData]);

  const toggleData = skipZero ? AllData : filterData;
  const toggleButton = skipZero ? "Show Active" : "Show All";

  useEffect(() => {
    if (AllData.length !== 0) {
      setAllDataIn({
        labels: daysLabels,
        datasets: toggleData.map((elem) => ({
          label: elem.name,
          data: elem.AllWeekInChart,
          lineTension: 0.5,
          borderColor: elem.color,
          backgroundColor: elem.color,
          borderWidth: 2,
        })),
      });
      setAllDataOut({
        labels: monthLabels,
        datasets: toggleData.map((elem) => ({
          label: elem.name,
          data: elem.AllMonthOutChart,
          lineTension: 0.5,
          borderColor: elem.color,
          backgroundColor: elem.color,
          borderWidth: 2,
        })),
      });
    }
  }, [toggleData, AllData.length]);

  useEffect(() => {
    if (SelectedWorkflow === "1") {
      if (valueIn === 0) {
        setAllDataIn({
          labels: daysLabels,
          datasets: toggleData.map((elem) => ({
            label: elem.name,
            data: elem.AllWeekInChart,
            lineTension: 0.5,
            borderColor: elem.color,
            backgroundColor: elem.color,
            borderWidth: 2,
          })),
        });
      } else if (valueIn === 1) {
        setAllDataIn({
          labels: monthLabels,
          datasets: toggleData.map((elem) => ({
            label: elem.name,
            data: elem.AllMonthInChart,
            lineTension: 0.5,
            borderColor: elem.color,
            backgroundColor: elem.color,
            borderWidth: 2,
          })),
        });
      }
    } else {
      if (valueIn === 0) {
        setAllDataIn({
          labels: daysLabels,
          datasets: toggleData
            .map((workflow) => {
              if (workflow._id === SelectedWorkflow) {
                return workflow.stepsWeekChart.map((elem) => {
                  return {
                    label: elem.workflowStepName,
                    data: elem.totalIncome,
                    lineTension: 0.5,
                    borderColor: elem.color,
                    backgroundColor: elem.color,
                    borderWidth: 2,
                  };
                });
              } else return null;
            })
            .find((e) => {
              if (e !== null) {
                return e;
              } else {
                return null;
              }
            }),
        });
      } else if (valueIn === 1) {
        setAllDataIn({
          labels: monthLabels,
          datasets: toggleData
            .map((workflow) => {
              if (workflow._id === SelectedWorkflow) {
                return workflow.stepsMonthChart.map((elem) => ({
                  label: elem.workflowStepName,
                  data: elem.totalIncome,
                  lineTension: 0.5,
                  borderColor: elem.color,
                  backgroundColor: elem.color,
                  borderWidth: 2,
                }));
              } else {
                return null;
              }
            })
            .find((e) => {
              if (e !== null) {
                return e;
              } else {
                return null;
              }
            }),
        });
      }
    }
  }, [valueIn, toggleData, SelectedWorkflow]);

  useEffect(() => {
    if (SelectedWorkflow === "1") {
      if (valueOut === 0) {
        setAllDataOut({
          labels: daysLabels,
          datasets: toggleData.map((elem) => ({
            label: elem.name,
            data: elem.AllWeekOutChart,
            lineTension: 0.5,
            borderColor: elem.color,
            backgroundColor: elem.color,
            borderWidth: 2,
          })),
        });
      } else if (valueOut === 1) {
        setAllDataOut({
          labels: monthLabels,
          datasets: toggleData.map((elem) => ({
            label: elem.name,
            data: elem.AllMonthOutChart,
            lineTension: 0.5,
            borderColor: elem.color,
            backgroundColor: elem.color,
            borderWidth: 2,
          })),
        });
      }
    } else {
      if (valueOut === 0) {
        setAllDataOut({
          labels: daysLabels,
          datasets: toggleData
            .map((workflow) => {
              if (workflow._id === SelectedWorkflow) {
                return workflow.stepsWeekChart.map((elem) => {
                  return {
                    label: elem.workflowStepName,
                    data: elem.totalOutcome,
                    lineTension: 0.5,
                    borderColor: elem.color,
                    backgroundColor: elem.color,
                    borderWidth: 2,
                  };
                });
              } else return null;
            })
            .find((e) => {
              if (e !== null) {
                return e;
              } else {
                return null;
              }
            }),
        });
      } else if (valueOut === 1) {
        setAllDataOut({
          labels: monthLabels,
          datasets: toggleData
            .map((workflow) => {
              if (workflow._id === SelectedWorkflow) {
                return workflow.stepsMonthChart.map((elem) => ({
                  label: elem.workflowStepName,
                  data: elem.totalOutcome,
                  lineTension: 0.5,
                  borderColor: elem.color,
                  backgroundColor: elem.color,
                  borderWidth: 2,
                }));
              } else {
                return null;
              }
            })
            .find((e) => {
              if (e !== null) {
                return e;
              } else {
                return null;
              }
            }),
        });
      }
    }
  }, [valueOut, toggleData, SelectedWorkflow]);

  const handleChangeIn = (event, newValue) => {
    setValueIn(newValue);
  };

  const handleChangeOut = (event, newValue) => {
    setValueOut(newValue);
  };

  const handleChangeSelectedWorkflow = (event) => {
    setSelectedWorkflow(event.target.value);
  };

  const tooltipPlugins = {
    callbacks: {
      title: function(tooltipItems) {
        let total = 0;
        let labels = tooltipItems.map((tooltipItem) => {
          if (tooltipItem.raw !== 0) {
            total += tooltipItem.raw;
          }
          return tooltipItem.label;
        });
        return `${labels[0]} - Total: ${total.toLocaleString()}`;
      },
      label: function(tooltipItem) {
        if (tooltipItem.raw === 0) {
          return null; // Skip items with value 0
        }

        return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toLocaleString()}`;
      },
      footer: function(tooltipItems) {
        let activeSources = 0;
         tooltipItems.forEach((tooltipItem) => {
          if (tooltipItem.raw !== 0) {
            activeSources++;
          }
        });
        return `Active sources: ${activeSources}`;
      }
    }
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
        },
      },
      y: {
        beginAtZero: true,
      }
    },
    layout: {
      display: "none",
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onHover: (event) => {
          event.native.target.style.cursor = "pointer";
        },
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          pointStyle: "circle",
        },
        position: "top",
        align: "end",
      },
      tooltip: {
        itemSort: function(a, b) {
          return b.raw - a.raw;
        },
        callbacks: tooltipPlugins.callbacks,
      },
    },
    elements: {
      point: {
        radius: 3,
      },
    },
    interaction: { mode: "index",  intersect: false, },
    onHover: function (e) {
      const points = this.getElementsAtEventForMode(
        e,
        "index",
        { axis: "x", intersect: true },
        false
      );

      if (points.length) e.native.target.style.cursor = "pointer";
      else e.native.target.style.cursor = "default";
    },
  }

  if (toggleData.length === 0) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "-24px 0 0 0" }}>
      <Box className={style.container_option}>
        <Box className={style.text}>
          <FormControl fullWidth>
            <Select
              value={SelectedWorkflow}
              onChange={handleChangeSelectedWorkflow}
              label="Selected Workflow"
            >
              <MenuItem key={"1"} value={"1"}>
                All Workflows
              </MenuItem>
              {toggleData?.map((e) => {
                return (
                  <MenuItem
                    key={e?._id}
                    disabled={!e?.stepCount}
                    value={e?._id}
                  >
                    {e?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Button onClick={() => setSkipZero(!skipZero)} variant="skipZero">
          {toggleButton}
        </Button>
      </Box>
      <Box className={style.chart_container}>
        <Box className={style.chart}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant={"h3"} className={style.page_title}>
              IN
            </Typography>
            <Tabs
              variant="custom"
              TabIndicatorProps={{ style: { background: "transparent" } }}
              value={valueIn}
              onChange={handleChangeIn}
            >
              <Tab variant="custom" label="Last 7 days" />
              <Tab variant="custom" label="Last 30 days" />
            </Tabs>
          </Box>
          <Box sx={{ height: "350px", width: "100" }}>
            {AllDataIn?.datasets?.length ? (
              <Line
                data={AllDataIn}
                options={{
                  ...options,
                  scales: {
                    ...options.scales,
                    y: {
                      ...options.scales.y,
                      max:
                        Math.max(
                          ...AllDataIn?.datasets?.map((elem) => {
                            return Math.max(...elem.data);
                          })
                        ) + 1,
                    },
                  },
                }}
              />
            ) : null}
          </Box>
        </Box>
        <Box className={style.chart}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant={"h3"} className={style.page_title}>
              OUT
            </Typography>
            <Tabs
              variant="custom"
              TabIndicatorProps={{ style: { background: "transparent" } }}
              value={valueOut}
              onChange={handleChangeOut}
            >
              <Tab variant="custom" label="Last 7 days" />
              <Tab variant="custom" label="Last 30 days" />
            </Tabs>
          </Box>
          <Box sx={{ height: "350px", width: "100" }}>
            {AllDataOut?.datasets?.length ? (
              <Line
                data={AllDataOut}
                options={{
                  ...options,
                  scales: {
                    ...options.scales,
                    y: {
                      ...options.scales.y,
                      max:
                        Math.max(
                          ...AllDataOut?.datasets?.map((elem) => {
                            return Math.max(...elem.data);
                          })
                        ) + 1,
                    },
                  },
                }}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowChartStatistic;
