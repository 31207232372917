import React from "react";
import { Button } from "@mui/material";

const SaveButton = ({ handleClickOk }) => {

  return (
    <Button
      onClick={handleClickOk}
      variant="save"
      size="large"
    >
      Ok
    </Button>
  );
};
export default SaveButton;
