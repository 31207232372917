import React from "react";
import { Box, Typography } from "@mui/material";
import CancelButton from "../../SaveModalButtons/CancelButton";
import DeleteButton from "../../SaveModalButtons/DeleteButton";
import style from "./style.module.css";

const DeleteModal = ({ handleCloseDeleteModal, handleDelete, text, name }) => {
  return (
    <Box className={style.modal}>
      <Typography variant={"h2"}>Delete {text}</Typography>
      <Typography variant={'subtitle1'} className={style.sub_title}>
        Do you want to delete "{name}" {text}?
      </Typography>
      <Box sx={{ display: "flex", gap: "20px", margin: "30px 0 20px 0" }}>
        <CancelButton handleCloseSaveModal={handleCloseDeleteModal} />
        <DeleteButton handleDelete={handleDelete} />
      </Box>
    </Box>
  );
};

export default DeleteModal;
