import { useEffect } from "react";

const useEscapeClose = (callback) => {
    useEffect(() => {
        const handleEscapePress = (event) => {
            if (event.key === 'Escape') {
                callback();
            }
        };
    
        document.addEventListener('keydown', handleEscapePress);
    
        return () => {
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, [callback]);
}

export default useEscapeClose;
