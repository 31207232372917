import {useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAppState} from "../contexts/AppContext";
import {useLogout} from "./useLogout";
import {deleteCustomModule, editCustomModule, getCustomModules, postCustomModules} from "../services/DataService";


export function useCustomModulesAPI(handleCloseSaveModal) {

    const {handleOpenAlert} = useAppState();
    const {logout} = useLogout();
    const queryClient = useQueryClient();

    const customModulesData = useQuery(["getStaticModules"], getCustomModules);

    const customModules = useQuery(["getCustomModules"], getCustomModules);


    const deleteCustomModules = useMutation(deleteCustomModule, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Custom module deleted successfully");
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", "Oops, something went wrong");
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const editCustomModules = useMutation(editCustomModule, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Custom module edited successfully");
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const editCustomModulesModal = useMutation(editCustomModule, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Custom module edited successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    const createCustomModule = useMutation(postCustomModules, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Module created successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });

    return useMemo(() => ({
        customModulesData,
        customModules,
        deleteCustomModules,
        editCustomModules,
        editCustomModulesModal,
        createCustomModule
    }), [customModulesData, deleteCustomModules,customModules, editCustomModules, editCustomModulesModal, createCustomModule])
}
