import {Box, Typography} from "@mui/material";
import styles from "./styles.module.css";
export default function Status({ status, className }) {
  return (
    <Box className={className}>
      {status ? (
        <Typography variant={'subtitle1'} className={styles.ActiveStatus}>Active</Typography>
      ) : (
        <Typography variant={'subtitle1'} className={styles.InactiveStatus}>Inactive</Typography>
      )}
    </Box>
  );
}
