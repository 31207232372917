import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {Box, Button, Modal, Snackbar, Tab, Tabs} from "@mui/material";
import StoragePage from './StoragePage';
import MatchingPage from './MatchingPage/MatchingPage';
import TabPanel from "../../components/Tab/TabPanel";
import {Alert} from "../../utils/alert";
import SaveStorageModal from "./SaveStorageModal";
import style from "./styles.module.css";

const DatabasePage = () => {
    const {state} = useLocation()
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState(null);
    const [alert, setAlert] = useState({
        isShow: false,
        type: "success",
        message: "Storage created successfully",
    });
    const [value, setValue] = useState(state?.tabIndex || 0);

    const redirectAddNew = () => {
        setDefaultValues(null);
        handleOpenSaveModal();
    };
    const handleOpenSaveModal = () => setShowSaveModal(true);
    const handleCloseSaveModal = () => setShowSaveModal(false);
    const handleCloseAlert = () => {
        setAlert(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <Box className={style.container_database_page}>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                    >
                        <Tab label="Storage Databases"/>
                        <Tab label="Matching Databases"/>
                    </Tabs>
                </Box>
                {value === 0 && <Button
                    onClick={redirectAddNew}
                    variant="addNew"
                    size="large"
                >
                    Add Storage
                </Button>}
            </Box>
            <TabPanel value={value} index={0}>
                <StoragePage/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MatchingPage state={state || null}/>
            </TabPanel>
            <Snackbar
                open={alert.isShow}
                autoHideDuration={3500}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alert.type}
                    sx={{width: "100%"}}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
            <Modal
                open={showSaveModal}
                onClose={handleCloseSaveModal}
                sx={{backdropFilter: "blur(14px)"}}
            >
                <SaveStorageModal
                    handleCloseSaveModal={handleCloseSaveModal}
                    defaultValues={defaultValues}
                />
            </Modal>
        </Box>
    )
}

export default DatabasePage;
