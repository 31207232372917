import {useMemo} from "react";
import {useQuery} from "react-query";
import {getWorkflow, getWorkflows} from "../services/DataService";

export function useWorkflowAPI(id) {

    const workflowData = useQuery(["getWorkflowID", id], () => getWorkflow(id));

    const workflowsData = useQuery(["getWorkflows"], getWorkflows);

    return useMemo(() => ({
        workflowData,
        workflowsData
    }), [workflowData, workflowsData])
}
