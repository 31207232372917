import React, { useState } from "react";
import { Box, Snackbar } from "@mui/material";
import { Alert } from "../../utils/alert";
import "./styles.css";

const Documentation = () => {
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `{
      "leads": [
         {
          "firstName": "string",
          "lastName": "string",
          "email": "string",                  //required if empty phone
          "phone": "string",                  //required if empty email
          "ssn": "string",
          "birthDate": "string",              //format MM/DD/YYYY
          "driverLicenseState": "string",
          "driverLicenseNumber": "string",
          "ownCar": "string",
          "ownHome": "string",
          "activeMilitary": "string",
          "address": "string",
          "address2": "string",
          "addressNumber": "string",
          "addressLengthMonths": "string",
          "city": "string",
          "state": "string",
          "zip": "string",
          "homePhone": "string",
          "workPhone": "string",
          "bestTimeToCall": "string",
          "incomeType": "string",
          "employer": "string",
          "employedMonths": "string",
          "jobTitle": "string",
          "monthlyIncome": "string",
          "directDeposit": "string",
          "payFrequency": "string",
          "payDate1": "string",               //format MM/DD/YYYY
          "payDate2": "string",               //format MM/DD/YYYY
          "bankAccountType": "string",
          "bankAba": "string",
          "bankAccountNumber": "string",
          "bankAccountLengthMonths": "string",
          "bankName": "string",
          "bankPhone": "string",
          "ipAddress": "string",
          "domain": "string",
          "source": "string",
          "vertical": "string",
          "price": "string",
          "optInDate": "string",              //format MM/DD/YYYY
          "creditScore": "string",
          "debtAmount": "string",
          "loanAmount": "string",
          "loanReason": "string",
          "loanPreference": "string",
          "zodiac": "string"
          "ua": "string"
          "jornayaId": "string"
          "trustedFormId": "string"
          "consentId": "string"
          "brand": "string"
          "cid": "string"
          "txid": "string"
         }
      ]
  }
  `
      )
      .then(() => {
        handleOpenAlert("success", "Request Body copied to clipboard");
      });
  };
  return (
    <Box className="wrapper-doc">
      <Box className="wrapper-info">
        <p className="info-title">API Documentation</p>
        <p className="info-subtitle">Import Leads Method API</p>
        <p className="info-text">
          This Method API allows to transfer leads from Third Party Applications
          to DataConcept Application. In order to use this API method you have
          to get API key on dataconcepts.io
        </p>
        <p className="info-labale">HTTP METHOD:</p>
        <Box className="wrapper-method">
          <p className="method-text">
            POST https://dataconcepts.io/api/leads
          </p>
        </Box>
        <p className="info-labale">HEADERS:</p>
        <p className="info-header">Content-Type:application/json</p>
        <p className="info-header"> Authorization: Bearer API_KEY</p>
        <Box className="borderBottom"></Box>
        <p className="info-labale">HTTP METHOD:</p>
        <Box className="wrapper-method" style={{marginBottom: '20px'}}>
          <p className="method-text">
            GET https://dataconcepts.io/api/leads/API_KEY?parameter=parameterValue
          </p>
        </Box>
        <p className="info-header">Parameters are only allowed which are present in request body example</p>
        <Box className="wrapper-method" style={{marginBottom: '20px'}}>
          <p className="method-text">
            GET https://dataconcepts.io/api/leads/API_KEY?parameter=parameterValue
          </p>
        </Box>
        <p className="warn-label">DUPLICATE LEADS POLICY</p>
        <p className="info-header">Duplicated phones and emails will not be processed during 24-hour period</p>
      </Box>
      <Box className="wrapper-code">
        <Box className="wrapper-code-title">
          <p className="code-title">REQUEST BODY EXAMPLE:</p>
          <p className="copy-btn" onClick={() => copyToClipboard()}>
            Copy
          </p>
        </Box>
        <Box className="wrapper-code-text">
          <span className="d bl ">{`{`}</span>
          <span className="w bl pl10">
            "leads": <span className="d">{`[ `}</span>
          </span>
          <span className="d bl pl20">{`{`}</span>
          <span className="bl w pl30">
            "firstName": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "lastName": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "email": <span className="g">"string", //required if empty phone</span>
          </span>
          <span className="bl w pl30">
            "phone": <span className="g">"string", //required if empty email</span>
          </span>
          <span className="bl w pl30">
            "ssn": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "birthDate": <span className="g"> "string", //format MM/DD/YYYY</span>
          </span>
          <span className="bl w pl30">
            "driverLicenseState": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "driverLicenseNumber": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "ownCar": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "ownHome": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "activeMilitary": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "address": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "address2": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "addressNumber": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "addressLengthMonths": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "city": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "state": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "zip": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "homePhone": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "workPhone": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bestTimeToCall": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "incomeType": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "employer": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "employedMonths": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "jobTitle": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "monthlyIncome": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "directDeposit": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "payFrequency": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "payDate1": <span className="g"> "string", //format MM/DD/YYYY</span>
          </span>
          <span className="bl w pl30">
            "payDate2": <span className="g"> "string", //format MM/DD/YYYY</span>
          </span>
          <span className="bl w pl30">
            "bankAccountType": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bankAba": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bankAccountNumber": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bankAccountLengthMonths": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bankName": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "bankPhone": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "ipAddress": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "domain": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "source": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "vertical": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "price": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "optInDate": <span className="g"> "string", //format MM/DD/YYYY</span>
          </span>
          <span className="bl w pl30">
            "creditScore": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "debtAmount": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "loanAmount": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "loanReason": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "loanPreference": <span className="g"> "string",</span>
          </span>
          <span className="bl w pl30">
            "zodiac": <span className="g"> "string"</span>
          </span>

          <span className="bl w pl30">
            "ua": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "jornayaId": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "trustedFormId": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "consentId": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "brand": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "cid": <span className="g"> "string"</span>
          </span>
          <span className="bl w pl30">
            "txid": <span className="g"> "string"</span>
          </span>
          <span className="bl d pl20">{`}`}</span>
          <span className="bl d pl10">{`]`}</span>
          <span className="bl d">{`}`}</span>
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default Documentation;
