import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Snackbar,
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  Popper,
  Grow,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import AddIcon from "@mui/icons-material/Add";
import style from "./styles.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ".././style.css";
import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";
import TextFieldSearch from "../../theme/TextFieldSearch";
import { useMutation, useQueryClient } from "react-query";
import { postSourceData } from "../../services/DataService";
import { useLogout } from "../../hooks/useLogout";
import { useGroupsAPI } from "../../hooks/useGroupsAPI";
import { useAppState } from "../../contexts/AppContext";
import { useTargetsAPI } from "../../hooks/useTargetsAPI";

const Placeholder = ({ children }) => {
  return <div className={style.container_placeholder}>{children}</div>;
};

const SaveGroupModal = ({ handleCloseSaveModal, defaultValues }) => {
  const [name, setName] = useState("");
  const [changedData, setChangedData] = useState([]);
  const [groupType, setGroupType] = useState("");
  const [toggle, setToggle] = useState(false);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [rule, setRule] = useState([]);
  const [openModalAddRule, setOpenModalAddRule] = useState(false);
  const anchorRef = React.useRef(null);

  const { config } = useAppState();
  const groupTypes = useMemo(() => config?.config?.group?.type, [config]);
  const duplicateTypes = useMemo(() => config?.config?.group?.duplicateConfig, [config]);

  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const { logout } = useLogout();
  const queryClient = useQueryClient();

  const {
    sourcesData: { data: sources },
  } = useSourcesAPI();

  const {
    targetsData: { data: targets },
  } = useTargetsAPI();

  // console.log("targets", targets);
  // console.log("sources", sources);
  // console.log("defaultValues", defaultValues);

  const { postGroup, editGroup } = useGroupsAPI(handleCloseSaveModal);

  const chooseSource = useMutation(postSourceData, {
    onSuccess: (data) => {
      setChangedData(data);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setGroupType(defaultValues.type);
      setSource(defaultValues.sources?.map((source) => source._id));
      setTarget(defaultValues.targets?.map((target) => target._id));
      setRule(defaultValues.duplicateConfig ? [JSON.parse(JSON.stringify(defaultValues.duplicateConfig))] : []);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  const requiredFields = [name, source?.length || target?.length, rule?.length];
  const validation = () => !requiredFields.every((i) => i);

  const editValidation = () => {
    if (
      (defaultValues.name === name &&
        defaultValues.sources?.length === source?.length &&
        defaultValues.targets?.length === target?.length &&
        JSON.stringify(defaultValues.duplicateConfig) === JSON.stringify(rule[0])) ||
      !(source?.length > 0 || target?.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeGroupType = (event) => {
    setGroupType(event.target.value);
  };

  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };

  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
  };

  const handleOpenModalAddRule = () => setOpenModalAddRule(true);
  const handleCloseModalAddRule = () => setOpenModalAddRule(false);

  const handleLisener = (source) => {
    setToggle(true);
    chooseSource.mutate({
      queryText: source,
      entityType: "source",
    });
  };

  const handlePostGroup = () => {
    try {
      postGroup.mutate({
        name: name,
        type: groupType,
        [`${groupType.toLowerCase()}Ids`]: groupType === "SOURCE" ? source : target,
        duplicateConfig: rule[0], // TODO: API only allows one rule for now, future: maybe allow multiple rules?
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };
  const handleEditGroup = () => {
    try {
      editGroup.mutate({
        data: {
          name: name,
          type: defaultValues.type,
          [`${groupType.toLowerCase()}Ids`]: groupType === "SOURCE" ? source : target,
          duplicateConfig: rule[0], // TODO: API only allows one rule for now, future: maybe allow multiple rules?
        },
        id: defaultValues._id,
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };

  const handleAddRule = (event) => {
    const rules = rule;
    rules.push({ type: event.target.id, value: 1, period: "DAY" }); // default value: 1 day
    setRule(rules);
    handleCloseModalAddRule();
  };

  const handleChangeRulePeriod = (event, index) => {
    const rules = rule;
    rules[index].period = event.target.value;
    setRule([...rules]);
  };
  const handleChangeRuleValue = (event, index) => {
    const rules = rule;
    rules[index].value = Number(event.target.value);
    setRule([...rules]);
  };

  const dataToggle = toggle ? changedData : sources?.data;

  return (
    <>
      <Box className={style.modal}>
        <Box>
          <Typography variant={"h1"} className={style.title}>
            {defaultValues ? "Edit" : "Create"} Group
          </Typography>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Name</Typography>
            <TextField placeholder={"Enter name"} value={name} onChange={handleChangeName} variant="outlined" />
          </Box>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Group Type</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={groupType}
                  onChange={handleChangeGroupType}
                  label="groupType"
                  disabled={defaultValues}
                  displayEmpty
                  renderValue={groupType !== "" ? undefined : () => <Placeholder>Select request type</Placeholder>}
                >
                  {groupTypes &&
                    Object.entries(groupTypes)?.map(([key, value]) => (
                      <MenuItem key={key} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        {groupType === "SOURCE" && (
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>{"Select Source(s)"}</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={source || ""}
                  onChange={handleChangeSource}
                  onBlur={() => {
                    setChangedData(undefined);
                    setToggle(false);
                  }}
                  label="Source"
                  multiple
                  displayEmpty
                  renderValue={source?.length !== 0 && source !== null ? undefined : () => <Placeholder>Select source</Placeholder>}
                >
                  <TextFieldSearch
                    placeholder="Type to search"
                    className={style.text_field_search}
                    onChange={(event) => {
                      handleLisener(event.target.value);
                    }}
                  />
                  {dataToggle?.length !== 0
                    ? dataToggle?.map((e) => {
                        // const isSameGroup = defaultValues && e.groups?.some((g) => g._id === defaultValues?._id);
                        // const isInOtherGroup = e.groups?.length > 0 && !isSameGroup;
                        return (
                          <MenuItem key={e?._id} value={e?._id}>
                            {e?.name}
                          </MenuItem>
                        );
                      })
                    : toggle && <MenuItem disabled>not found source</MenuItem>}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {groupType === "TARGET" && (
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>{"Select Target(s)"}</Typography>
            <Box className={style.text}>
              <FormControl fullWidth>
                <Select
                  value={target || ""}
                  onChange={handleChangeTarget}
                  label="Target"
                  multiple
                  displayEmpty
                  renderValue={target?.length !== 0 && source !== null ? undefined : () => <Placeholder>Select target</Placeholder>}
                >
                  {targets?.map((e) => {
                    // const isSameGroup = defaultValues && e.groups?.some((g) => g._id === defaultValues?._id);
                    // const isInOtherGroup = e.groups?.length > 0 && !isSameGroup;
                    return (
                      <MenuItem key={e._id} value={e._id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {(!!source?.length || !!target?.length) && (
          <Box>
            <Typography variant={"h3"} className={style.title}>
              Group Rule
            </Typography>

            {rule?.map((r, index) => (
              <Box key={index} className={style.create_source_item}>
                <Typography variant={"subtitle2"}>{"Prevent duplicates " + r.type.replace(/_/g, " ")}</Typography>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    helperText={"Prevents duplicates within the specified time window"}
                    InputLabelProps={{ shrink: false }}
                    defaultValue={rule[index].value}
                    sx={{ width: "100%" }}
                    placeholder={"Window"}
                    variant="outlined"
                    onChange={(e) => handleChangeRuleValue(e, index)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Select variant="standard" disableUnderline={true} margin="normal" value={rule[index].period} onChange={(e) => handleChangeRulePeriod(e, index)}>
                            <MenuItem value={duplicateTypes.period?.minute?.value}>{duplicateTypes.period?.minute?.label}(s)</MenuItem>
                            <MenuItem value={duplicateTypes.period?.hour?.value}>{duplicateTypes.period?.hour?.label}(s)</MenuItem>
                            <MenuItem value={duplicateTypes.period?.day?.value}>{duplicateTypes.period?.day?.label}(s)</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton color="default" sx={{ padding: "14px", height: "100%", marginLeft: "8px" }} onClick={() => setRule(rule.filter((_, i) => i !== index))}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}

            {!rule.length && (
              <Button
                onClick={handleOpenModalAddRule}
                ref={anchorRef}
                variant="addStep"
                size="large"
                sx={{ width: "100%", border: "#d1d1d1 solid 1px", paddingY: "16px", paddingX: "8px", marginY: "0px" }}
              >
                <AddIcon />
                Add group rule
              </Button>
            )}
            <Popper sx={{ zIndex: 99 }} open={openModalAddRule} anchorEl={anchorRef.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                  sx={{ maxHeight: "200px" }}
                >
                  <Paper elevation={4}>
                    <ClickAwayListener onClickAway={handleCloseModalAddRule}>
                      <MenuList
                        sx={{ backgroundColor: "#f5f5f5" }}
                        // autoFocusItem={handleOpenModalAddRule}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        // onKeyDown={handleListKeyDown}
                      >
                        <MenuItem id={duplicateTypes.type.byEmail.value} onClick={handleAddRule}>
                          Prevent Dups {duplicateTypes.type.byEmail.label}
                        </MenuItem>
                        <MenuItem id={duplicateTypes.type.byPhone.value} onClick={handleAddRule}>
                          Prevent Dups {duplicateTypes.type.byPhone.label}
                        </MenuItem>
                        <MenuItem id={duplicateTypes.type.byEmailAndPhone.value} onClick={handleAddRule}>
                          Prevent Dups {duplicateTypes.type.byEmailAndPhone.label}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        )}
        <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          {defaultValues ? <EditButton handlePost={handleEditGroup} validation={editValidation} /> : <SaveButton handlePost={handlePostGroup} validation={validation} />}
        </Box>
      </Box>
      <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveGroupModal;
