import {useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAppState} from "../contexts/AppContext";
import {useLogout} from "./useLogout";
import {editTargets, getTargets, postTargets} from "../services/DataService";


export function useTargetsAPI(handleCloseSaveModal) {

    const {handleOpenAlert} = useAppState();
    const {logout} = useLogout();
    const queryClient = useQueryClient();
    const targetsData = useQuery(["getTargets"], getTargets);

    const postTarget = useMutation(postTargets, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Target created successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });
    const editTarget = useMutation(editTargets, {
        onSuccess: (data) => {
            handleOpenAlert("success", "Target created successfully");
            setTimeout(() => {
                handleCloseSaveModal();
            }, [1000]);
        },
        onError: (err) => {
            if (err.response.status === 401) {
                logout()
            }
            handleOpenAlert("error", err.response.data.errors[0].msg);
        },
        onSettled: () => {
            queryClient.invalidateQueries("create");
        },
    });


    return useMemo(() => ({
        postTarget,
        editTarget,
        targetsData
    }), [postTarget, editTarget, targetsData])
}
