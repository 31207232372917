import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Box, Typography, Tabs, Tab, Button, TextField } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import SourcesListTable from "./SourcesListTable";
import style from "./styles.module.css";
import { hashStringToColor } from "../../../utils/functions";
import { useChartAPI } from "../../../hooks/useChartAPI";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SourcesStatictis = () => {
  const [valueIn, setValueIn] = useState(0);
  const [graphType, setGraphType] = useState(0);
  const [skipZero, setSkipZero] = useState(false);
  const toggleValue = !skipZero ? "Show Active" : "Show All";
  const [newDataForChart, setNewDataForChart] = useState({
    labels: [],
    datasets: [],
  });
  const [selectedSource, setSelectedSource] = useState(null);
  const [skippedNames, setSkippedNames] = useState([]);
  const [columnSums, setColumnSums] = useState([]);

  // Date picker
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const [ statsData, setChart] = useState(null);
  const { getChart } = useChartAPI(setChart);

  useEffect(() => {
    if (!statsData || valueIn < 3) {
      getChart.mutate({from: moment().subtract(31,'d').startOf('day'), to: moment().endOf('day')});
    }
    if (valueIn === 3 && endDate && startDate) {
      getChart.mutate({from: moment(startDate).startOf('day'), to: moment(endDate).endOf('day')});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, valueIn])

  const labelColors = useRef({}).current;

  const createChartLabels = useCallback((data, valueIn) => {
    if (!data || data.length === 0) return [];

    switch (valueIn) {
      case 0: // Today
        return Array.from({length: 24}, (_, i) => {
          const hour = i % 12 || 12; // Convert 0 to 12 for 12 AM
          const ampm = i < 12 ? 'am' : 'pm';
          return `${hour}${ampm}`;
        });      
      case 1: // Last 7 days
        return Array.from({length: 7}, (_, i) => moment().subtract(i, 'days').format('MMM DD')).reverse();
      case 2: // Last 30 days
        return Array.from({length: 31}, (_, i) => moment().subtract(i, 'days').format('MMM DD')).reverse();
      case 3: // Custom range
        const days = moment(endDate).diff(moment(startDate), 'days') + 1;
        return Array.from({length: days}, (_, i) => moment(startDate).add(i, 'days').format('MMM DD'));
      default:
        return [];
    }
  }, [endDate, startDate]);

  const createChartData = useCallback(
    (dataIncome, valueIn) => {
      let filteredDataChart = dataIncome.filter((item) => {
        if (selectedSource && item.name !== selectedSource) {
          return false;
        }
        if (skipZero) {
          return skippedNames.includes(item.name);
        }
        return true;
      });

      let columnSums = [];

      const chartData = filteredDataChart.map((item) => {
        const label = item.name;
        if (!labelColors[label]) {
          labelColors[label] = hashStringToColor(item._id);
        }

        let totalIncomeArray = [];
        const today = new Date();

        if (valueIn === 3) {
          setShowDatePicker(true)
        }
        else {
          setShowDatePicker(false)
        }

        // Today tab
        if (valueIn === 0) {
          const lastReport = item.dailyReports.reduce((latest, current) => {
            return new Date(latest.day) > new Date(current.day) ? latest : current;
          }, {});
          totalIncomeArray = Object.values(lastReport?.data?.totalIncome ?? {}).map(Number);
        }

        // Last 7 day tab
        if (valueIn === 1) {
          for (let i = 0; i <= 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const report = item.dailyReports.find((r) => new Date(r.day).toDateString() === date.toDateString());
            totalIncomeArray.push(report ? Object.values(report.data.totalIncome).reduce((sum, value) => sum + Number(value), 0) : 0);
          }
          totalIncomeArray.reverse();
        }

        // Last 30 day tab
        if (valueIn === 2) {
          for (let i = 0; i <= 31; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const report = item.dailyReports.find((r) => new Date(r.day).toDateString() === date.toDateString());
            totalIncomeArray.push(report ? Object.values(report.data.totalIncome).reduce((sum, value) => sum + Number(value), 0) : 0);
          }
          totalIncomeArray.reverse();
        }
        totalIncomeArray.forEach((value, index) => {
          columnSums[index] = (columnSums[index] || 0) + value;
        });

        // Custom range tab
        if (valueIn === 3 && startDate && endDate) {
          const start = moment(startDate);
          const end = moment(endDate);
          const days = end.diff(start, 'days') + 1;
    
          for (let i = 0; i <= days; i++) {
            const date = moment(start).add(i, 'days');
            const report = item.dailyReports.find((r) => moment(r.day).isSame(date, 'day'));
            const dailyTotal = report ? Object.values(report.data.totalIncome).reduce((sum, value) => sum + Number(value), 0) : 0;
            totalIncomeArray.push(dailyTotal);
            
            // Update column sums for stacked bar chart
            columnSums[i] = (columnSums[i] || 0) + dailyTotal;
          }
        }

        return {
          backgroundColor: labelColors[label],
          borderColor: labelColors[label],
          // borderWidth: 0,
          label: item.name,
          lineTension: 0.5,
          data: totalIncomeArray || [],
        };
      });

      setColumnSums(columnSums);
      return chartData;
    },
    [selectedSource, skipZero, skippedNames, labelColors, startDate, endDate]
  );

  useEffect(() => {
    if (statsData && statsData.length > 0) {
      const chartData = createChartData(statsData, valueIn);
      const labels = createChartLabels(statsData, valueIn);

      setNewDataForChart({
        labels: labels,
        datasets: chartData,
      });
      
    }
  }, [statsData, valueIn, createChartData, createChartLabels]);

  const createChartDataTable = useCallback(
    (incomeData) => {
      let tempSkippedNames = [];
      const result = incomeData && incomeData
        .map((item) => {
          const sortedReports = item.dailyReports.sort(
            (a, b) => new Date(b.day) - new Date(a.day)
          );
          const latestDate =
            sortedReports.length > 0 ? new Date(sortedReports[0].day) : null;
          let total = 0;
          let todayIncome = 0;
          let weekIncome = 0;
          let monthIncome = 0;
          let totalDuplicates = 0;
          let todayIncomeDuplicates = 0;
          let weekIncomeDuplicates = 0;
          let monthIncomeDuplicates = 0;
          const oneDay = 24 * 60 * 60 * 1000;
          const oneWeekAgo = latestDate
            ? new Date(latestDate.getTime() - 7 * oneDay)
            : null;
          const oneMonthAgo = latestDate
            ? new Date(latestDate.getTime() - 30 * oneDay)
            : null;

          sortedReports.forEach((report) => {
            const reportDate = new Date(report.day);
            const dailyTotal = Object.values(report.data.totalIncome).reduce(
              (sum, value) => sum + Number(value),
              0
            );
            const dailyTotalDuplicates = Object.values(
              report?.data?.totalDuplicates || {}
            ).reduce((sum, value) => sum + Number(value), 0);            

            if (reportDate.getTime() === latestDate.getTime()) {
              todayIncome = dailyTotal;
              todayIncomeDuplicates = dailyTotalDuplicates;
            }
            if (reportDate >= oneWeekAgo) {
              weekIncome += dailyTotal;
              weekIncomeDuplicates += dailyTotalDuplicates;
            }
            if (reportDate >= oneMonthAgo) {
              monthIncome += dailyTotal;
              monthIncomeDuplicates += dailyTotalDuplicates;
            }

            total += dailyTotal;
            totalDuplicates += dailyTotalDuplicates;
          });

          if (skipZero && (total !== 0 || totalDuplicates !== 0)) {
            tempSkippedNames.push(item.name);
          }

          return {
            lastDays: monthIncome,
            lastDaysDuplicates: monthIncomeDuplicates,
            total: total,
            totalDuplicates: totalDuplicates,
            today: todayIncome,
            todayDuplicates: todayIncomeDuplicates,
            week: weekIncome,
            weekDuplicates: weekIncomeDuplicates,
            label: item.name,
            _id: {
              _id: item._id,
              name: item.name,
            },
          };
        })
        .filter((item) => {
          if (skipZero) {
            return !(item.total === 0 && item.totalDuplicates === 0);
          }
          return true;
        });

      if (skipZero && skippedNames.length < 1) {
        setSkippedNames(tempSkippedNames);
      }

      return result;
    },
    [skipZero, skippedNames]
  );

  const dataTable =  useMemo(
    () => statsData && createChartDataTable(statsData),
    [statsData, createChartDataTable]
  );


  const handleClick = () => {
    setSkipZero(!skipZero);
  };

  const handleCustomDateChangeFrom = (date) => {
    setStartDate(date);
  };
  const handleCustomDateChangeTo = (date) => {
    setEndDate(date);
  };

  const handleRowClick = (sourceName) => {
    if (selectedSource === sourceName) {
      setSelectedSource(null);
    } else {
      setSelectedSource(sourceName);
    }
  };

  const tooltipPlugins = {
    callbacks: {
      title: function(tooltipItems) {
        let total = 0;
        let labels = tooltipItems.map((tooltipItem) => {
          if (tooltipItem.raw !== 0) {
            total += tooltipItem.raw;
          }
          return tooltipItem.label;
        });
        return `${labels[0]} - Total: ${total.toLocaleString()}`;
      },
      label: function(tooltipItem) {
        if (tooltipItem.raw === 0) {
          return null; // Skip items with value 0
        }

        return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toLocaleString()}`;
      },
      footer: function(tooltipItems) {
        let activeSources = 0;
         tooltipItems.forEach((tooltipItem) => {
          if (tooltipItem.raw !== 0) {
            activeSources++;
          }
        });
        return `Active sources: ${activeSources}`;
      }
    }
  };

  const optionsBars = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
        },
      },
      y: {  
        stacked: true,
      },
    },
    layout: {
      display: "none",
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onHover: (event) => {
          event.native.target.style.cursor = "pointer";
        },
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          pointStyle: "circle",
        },
        position: "top",
        align: "end",
      },
      tooltip: {
        itemSort: function(a, b) {
          return b.raw - a.raw;
        },
        callbacks: tooltipPlugins.callbacks,
      },
    },
    elements: {
      point: {
        radius: 2,
      },
    },
    interaction: { mode: "index",  intersect: false, },
    onHover: function (e) {
      const points = this.getElementsAtEventForMode(
        e,
        "index",
        { axis: "x", intersect: true },
        false
      );

      if (points.length) e.native.target.style.cursor = "pointer";
      else e.native.target.style.cursor = "default";
    },
  };

  const optionsLines = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
        },
      },
      y: {
        beginAtZero: true,
      }
    },
    layout: {
      display: "none",
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onHover: (event) => {
          event.native.target.style.cursor = "pointer";
        },
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          pointStyle: "circle",
        },
        position: "top",
        align: "end",
      },
      tooltip: {
        itemSort: function(a, b) {
          return b.raw - a.raw;
        },
        callbacks: tooltipPlugins.callbacks,
      },
    },
    elements: {
      point: {
        radius: 3,
      },
    },
    interaction: { mode: "index",  intersect: false, },
    onHover: function (e) {
      const points = this.getElementsAtEventForMode(
        e,
        "index",
        { axis: "x", intersect: true },
        false
      );

      if (points.length) e.native.target.style.cursor = "pointer";
      else e.native.target.style.cursor = "default";
    },
  };

  const headers = [
    {
      lable: "Source Name",
      align: "left",
    },
    {
      lable: "Today",
      align: "left",
    },
    {
      lable: "Today duplicates",
      align: "left",
    },
    {
      lable: "Last 7 Days",
      align: "left",
    },
    {
      lable: "Last 7 Days duplicates",
      align: "left",
    },
    {
      lable: "Last 30 Days",
      align: "left",
    },
    {
      lable: "Last 30 Days duplicates",
      align: "left",
    },
    {
      lable: "Total",
      align: "left",
    },
    {
      lable: "Total duplicates",
      align: "left",
    },
  ];

  const columns = [
    {
      accessor: (element) => {
        return (
          <Box sx={{ width: "20%" }}>
            <Typography className={style.sources_statictis_title_name}>
              {element["_id"]["name"]}
            </Typography>
            <Typography className={style.sources_statictis_title_id}>
              {element["_id"]._id}
            </Typography>
          </Box>
        );
      },
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) => element.total != null && element["today"],
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) =>
        element.total != null && element["todayDuplicates"],
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) => element.total != null && element["week"],
      Cell: (event) => {},
      align: "left",
    },
    {
      accessor: (element) => element.total != null && element["weekDuplicates"],
      Cell: (event) => {},
      align: "left",
    },
    {
      accessor: (element) => element.total != null && element["lastDays"],
      Cell: (event) => {},
      align: "left",
    },
    {
      accessor: (element) =>
        element.total != null && element["lastDaysDuplicates"],
      Cell: (event) => {},
      align: "left",
    },
    {
      accessor: (element) => element.total != null && element["total"],
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) =>
        element.total != null && element["totalDuplicates"],
      Cell: (event, source) => {},
      align: "left",
    },
  ];

  const handleChangeIn = (_, newValue) => setValueIn(newValue);
  const handleChangeGraphType = (_, newValue) => setGraphType(newValue);

  if (!statsData) return <div>Loading...</div>;

  return (
    <>
      <Box className="wrapper-source-list">
        <Typography
          className={style.page_title}
          sx={{ fontSize: "16px !important", textDecoration: "underline" }}
        >
          Daily incoming unique leads
        </Typography>
        <Button variant="skipZero" onClick={handleClick}>
          {toggleValue}
        </Button>
        <Box className={style.chart_container}>
          <Box className={style.chart}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", position: "relative", flexWrap: "wrap", gap: "8px" }}>
              <Tabs
                sx={{
                  width: "fit-content",
                  background: "#E5E5E5",
                  borderRadius: "8px",
                  alignItems: "center",
                  height: "50px",
                }}
                TabIndicatorProps={{ style: { background: "transparent" } }}
                value={valueIn}
                onChange={handleChangeIn}
              >
                <Tab variant="custom" label="Today" />
                <Tab variant="custom" label="Last 7 days" />
                <Tab variant="custom" label="Last 30 days" />
                <Tab variant="custom" label="Custom range" />
              </Tabs>

              {showDatePicker && <LocalizationProvider dateAdapter={AdapterMoment}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px"}}>
                  <DatePicker
                    placeholder="From"
                    minDate={moment().subtract(1, 'year')}
                    value={startDate}
                    onChange={handleCustomDateChangeFrom}
                    renderInput={(params) => <TextField sx={{ maxWidth: "160px"}} {...params} />}
                  />
                  -
                  <DatePicker
                    placeholder="To"
                    maxDate={moment()}
                    value={endDate || moment()}
                    onChange={handleCustomDateChangeTo}
                    renderInput={(params) => <TextField sx={{ maxWidth: "160px"}} {...params} />}
                  />
                </div>
              </LocalizationProvider>}

              <Tabs
                sx={{
                  width: "fit-content",
                  background: "#E5E5E5",
                  borderRadius: "8px",
                  alignItems: "center",
                  height: "50px",
                }}
                TabIndicatorProps={{ style: { background: "transparent" } }}
                value={graphType}
                onChange={handleChangeGraphType}
              >
                <Tab variant="custom" label="Line Graph" />
                <Tab variant="custom" label="Stacked Bars" />
              </Tabs>
            </Box>
            <Box sx={{ height: "350px", width: "100" }}>
              {newDataForChart?.datasets?.length > 0 ? (
                graphType === 0 ? 
                <Line
                  data={skipZero ? newDataForChart : newDataForChart}
                  options={{
                    ...optionsLines,
                    scales: {
                      ...optionsLines.scales,
                      y: {
                        ...(optionsLines.scales.y),
                        max:
                        Math.max(
                          ...newDataForChart.datasets.map((dataset) =>
                            Math.max(...dataset.data)
                          )
                        ) + Math.max(...[1, Math.floor(0.05 * Math.max(
                          ...newDataForChart.datasets.map((dataset) =>
                            Math.max(...dataset.data)
                          )
                        ))]),
                      },
                    },
                  }}
                />
              : <Bar
              data={skipZero ? newDataForChart : newDataForChart}
              options={{
                ...optionsBars,
                scales: {
                  ...optionsBars.scales,
                  y: {
                    ...(optionsBars.scales.y),
                    max: Math.max(...columnSums),
                  },
                },
              }}
            />) : null}
            </Box>
          </Box>
        </Box>
        <Typography
          className={style.page_title}
          sx={{ fontSize: "16px !important", textDecoration: "underline" }}
        >
          Leads statistic
        </Typography>
        <Box className={style.table}>
          <SourcesListTable
            className={style.table}
            headers={headers}
            data={dataTable}
            columns={columns}
            onRowClick={handleRowClick}
          />
        </Box>
      </Box>
    </>
  );
};

export default SourcesStatictis;