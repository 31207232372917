import React from "react";
import { Button } from "@mui/material";

const SaveButton = ({ handlePost, validation, text = "Save" }) => {
  return (
    <Button
      disabled={validation()}
      onClick={handlePost}
      variant="save"
      size="large"
    >
      {text}
    </Button>
  );
};
export default SaveButton;
