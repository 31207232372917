import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

const HeaderMenuItem = ({ item }) => {
  const { label, link, icon, create , openInNewTab} = item;
  // console.log(item, "item");
  return (
    <NavLink
      to={link}
      exact
      target={openInNewTab ? "_blank" : null}
      className={(props) => {
        return props.isActive || window.location.pathname === create
          ? "active menu-item"
          : "menu-item";
      }}
    >
      <div className="wrapper-icon">{icon}</div>
      <p className="menu-item-text">{label}</p>
    </NavLink>
  );
};

export default HeaderMenuItem;
