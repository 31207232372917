import React from "react";
import { Box, Typography } from "@mui/material";
import style from "./styleConfig.module.css";

const ExplanationProcessingType = () => {
  return (
    <Box className={style.container}>
      <Typography variant={'subtitle1'} className={style.text}>
          By incoming data - Process incoming record fields (if matched)<br/>
          By matching db data - Process record fields found in DB (if matched)<br/>
          Merge by income data - Process new missing fields - don’t override existing (if
          matched)<br/>
          Merge by matching db data - Process new missing fields - override existing (if matched)<br/>
      </Typography>
    </Box>
  );
};
export default ExplanationProcessingType;
