import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Snackbar } from "@mui/material";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useStorageAPI } from "../../hooks/useStorageAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

// const Placeholder = ({ children }) => {
//   const classes = usePlaceholderStyles();
//   return <div className={style.container_placeholder}>{children}</div>;
// };

const SaveStorageModal = ({ handleCloseSaveModal, defaultValues }) => {
  const [name, setName] = useState("");
  // const [type, setType] = useState("");
  // const [source, setSource] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Storage created successfully",
  });

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  // const { config } = useAppState();
  // const types = useMemo(() => config?.config?.storage?.type, [config]);

  const EditValidation = () => {
    if (defaultValues.name === name) {
      return true;
    } else {
      return false;
    }
  };
  const validation = () => {
    if (name) {
      return false;
    } else {
      return true;
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const { editStorage, createStorage } = useStorageAPI(handleCloseSaveModal);

  const handlePostStorages = () => {
    createStorage.mutate({
      name: name,
      type: "NoSQL",
      instance: "mongo.dataconcepts.io",
    });
  };
  const handleEditStorages = () => {
    editStorage.mutate({
      data: { name: name, type: "NoSQL", instance: "mongo.dataconcepts.io" },
      id: defaultValues._id,
    });
  };
  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          {defaultValues ? "Edit" : "Create"} Storage
        </Typography>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Name</Typography>
          <TextField
            placeholder={"Enter name"}
            s
            value={name}
            onChange={handleChangeName}
            variant="outlined"
          />
        </Box>
        {/* <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Type</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                value={type}
                onChange={handleChangeType}
                label="Type"
                displayEmpty
                renderValue={
                  type !== ""
                    ? undefined
                    : () => <Placeholder>Select Type</Placeholder>
                }
              >
                {types &&
                  Object.entries(types).map(([key, value]) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box> */}
        {/* <Box className={style.create_source_item}>
          <Typography variant={'subtitle2'}>Source</Typography>
          <Box className={style.text}>
            <FormControl fullWidth>
              <Select
                sx={{ "&.MuiFilledInput-input": { color: "grey" } }}
                value={source}
                onChange={handleChangeSource}
                label="Source"
                displayEmpty
                renderValue={
                  source !== ""
                    ? undefined
                    : () => <Placeholder>Select Source</Placeholder>
                }
              >
                {sources?.map((e) => <MenuItem key={e?._id} value={e?._id}>{e?.name}</MenuItem>)}
                <MenuItem value={"mongo.dataconcepts.io"}>
                  mongo.dataconcepts.io
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box> */}
        <Box className={style.container_main_buttons}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          {defaultValues ? (
            <EditButton
              handlePost={handleEditStorages}
              validation={EditValidation}
            />
          ) : (
            <SaveButton
              handlePost={handlePostStorages}
              validation={validation}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveStorageModal;
