import { useEffect } from 'react';

const useEnterKey = (callback) => {
  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === 'Enter') {
        callback();
      }
    };

    document.addEventListener('keydown', handleEnterPress);

    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [callback]);
}

export default useEnterKey;
