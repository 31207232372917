import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppState } from "../contexts/AppContext";
import { useLogout } from "./useLogout";
import { editGroups, getGroups, postGroups } from "../services/DataService";

export function useGroupsAPI(handleCloseSaveModal) {
  const { handleOpenAlert } = useAppState();
  const { logout } = useLogout();
  const queryClient = useQueryClient();
  const groupsData = useQuery(["getGroups"], getGroups);

  const postGroup = useMutation(postGroups, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Group created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const editGroup = useMutation(editGroups, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Group created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  return useMemo(
    () => ({
      postGroup,
      editGroup,
      groupsData,
    }),
    [postGroup, editGroup, groupsData]
  );
}
