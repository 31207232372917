import * as React from "react";
import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";

import HeaderMenuItem from "./HeaderMenuItem";
import SignOut from "../SignOut/SignOut";
import {ItemNavMenu} from "../../data/ItemNavMenu";
import "./style.css";
import {Typography} from "@mui/material";

const drawerWidth = 280;

const HeaderTitle = React.memo(({children}) => {
    const drawer = (
        <>
            <Box className="wrapperLogo">
                <Typography variant={'h3'} className="logo red">data</Typography>
                <Typography variant={'h3'} className="logo white">concepts</Typography>
            </Box>
            {ItemNavMenu.map((item) => (
                <HeaderMenuItem item={item} key={item.label}/>
            ))}

        </>
    );

    return (
        <Box sx={{display: "flex"}}>
            <Box className="wrapperNav" sx={{width: drawerWidth, flexShrink: 0}}>
                <Box variant="permanent" className="ItemContainer">
                    {drawer}
                    <SignOut/>
                </Box>

            </Box>
            <Box
                component="main"
                className="container-content"
                sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${drawerWidth}px)`,
                    position: 'relative'
                }}
            >
                <Outlet/>
            </Box>
        </Box>
    );
})

export default HeaderTitle;
