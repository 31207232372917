import React, { useState } from "react";
import { useMutation } from "react-query";

import { Box, Snackbar, Button } from "@mui/material";

import { resetCache } from "../../services/DataService";

import { Alert } from "../../utils/alert";

const SettingsPage = () => {
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Group created successfully",
  });

  const [didReset, setDidReset] = useState(false);

  const { isPending, mutate } = useMutation(resetCache, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Cache reset successfully");
      setDidReset(true);
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
  });

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  return (
    <Box sx={{ m: "20px" }}>
      <p className="info-title">Settings</p>
      <Box sx={{ display: "flex", alignItems: "center", marginY: "32px", justifyContent: "space-between" }}>
        <span style={{ fontSize: "18px" }}>Reset Cache</span>
        <Button onClick={mutate} disabled={isPending || didReset} variant="contained" color="warning">
          Reset Cache
        </Button>
      </Box>
      <hr />
      <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsPage;
