import React from "react";
import { Box, Typography } from "@mui/material";
import BackButton from "../../SaveModalButtons/BackButton";
import OkButton from "../../SaveModalButtons/OkButton";
import style from "./style.module.css";

const ModalBack = ({ handleClickBack, handleClickOk }) => {
  return (
    <Box className={style.modal}>
      <Typography variant={"h2"}>Changes won`t be saved</Typography>
      <Typography variant={'subtitle1'} className={style.sub_title}>
        Do you want to close it?
      </Typography>
      <Box sx={{ display: "flex", gap: "20px", margin: "30px 0 20px 0" }}>
        <BackButton handleCloseSaveModal={handleClickBack} />
        <OkButton handleClickOk={handleClickOk} />
      </Box>
    </Box>
  );
};
export default ModalBack;
