import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import {
  getWorkflowMonthStatic,
  getWorkflowWeekStatic,
} from "../../services/DataService";
import SourcesLogs from "../ApiKeys/SourcesLogs";
import WorkflowsLogs from "../WorkflowsPage/WorkflowsLogs";
import TabPanel from "../../components/Tab/TabPanel";
import PusherLogs from "./PusherLogs";
import {
  calculateTotalFromArray,
  calculateTotalFromArrayChart,
  calculateTotalProperty,
  processSteps,
} from "../../utils/functions";

import style from "./styles.module.css";
import AnalyticsStatistic from "./AnalyticsStatistic";

const AnalyticsPage = () => {
  const {
    sourcesData: { data: sources, isLoading, isError },
  } = useSourcesAPI();
  const [value, setValue] = useState(0);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [AllData, setAllData] = useState([]);

  const firstRun = useRef(true);
  const queryClient = useQueryClient();

  const StaticOnWeek = useMutation(getWorkflowWeekStatic, {
    onSuccess: (data) => {
      setWeekData(data);
    },
    onError: (e) => {
      console.log(e);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const StaticOnMonth = useMutation(getWorkflowMonthStatic, {
    onSuccess: (data) => {
      setMonthData(data);
    },
    onError: (e) => {
      console.log(e);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  useEffect(() => {
    if (firstRun.current) {
      StaticOnWeek.mutate();
      StaticOnMonth.mutate();
      firstRun.current = false;
    }
  }, [StaticOnWeek, StaticOnMonth]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (weekData?.length && monthData?.length) {
      const copyData = [];

      weekData.forEach((week) => {
        monthData.forEach((month) => {
          if (week._id === month._id) {
            const {
              WeekSteps,
              TodaySteps,
              WeekStepsChart,
              MonthStepsChart,
              MonthSteps,
            } = processSteps(week, month);

            const newData = {
              _id: month._id,
              name: week.name,
              stepCount: week.workflowStepsSize,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              AllTodayIn: calculateTotalProperty(TodaySteps, "totalIncome"),
              AllWeekIn: calculateTotalFromArray(week.data, "totalIncome"),
              AllTodayOut: calculateTotalProperty(TodaySteps, "totalOutcome"),
              AllWeekOut: calculateTotalFromArray(week.data, "totalOutcome"),
              AllMonthIn: calculateTotalFromArray(month.data, "totalIncome"),
              AllMonthOut: calculateTotalFromArray(month.data, "totalOutcome"),
              AllWeekInChart: calculateTotalFromArrayChart(
                week.data,
                "totalIncome"
              ),
              AllWeekOutChart: calculateTotalFromArrayChart(
                week.data,
                "totalOutcome"
              ),
              AllMonthInChart: calculateTotalFromArrayChart(
                month.data,
                "totalIncome"
              ),
              AllMonthOutChart: calculateTotalFromArrayChart(
                month.data,
                "totalOutcome"
              ),
              stepsToday: Object.values(TodaySteps),
              stepsWeek: Object.values(WeekSteps),
              stepsMonth: Object.values(MonthSteps),
              stepsWeekChart: Object.values(WeekStepsChart).map((e) => ({
                ...e,
                totalIncome: e.totalIncome,
                totalOutcome: e.totalOutcome,
              })),
              stepsMonthChart: Object.values(MonthStepsChart).map((e) => ({
                ...e,
                totalIncome: e.totalIncome,
                totalOutcome: e.totalOutcome,
              })),
            };

            copyData.push(newData);
          }
        });
      });

      setAllData(copyData);
    }
  }, [weekData, monthData]);

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <h1>Something wrong</h1>;
  }

  return (
    <>
      <Box className={style.tabs_box}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Statistic " />
          <Tab label="Workflow" />
          <Tab label="Sources" />
          <Tab label="Pusher" />
        </Tabs> 
      </Box>
      <TabPanel value={value} index={0}>
        <AnalyticsStatistic />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WorkflowsLogs workflows={AllData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SourcesLogs sources={sources.data} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PusherLogs pusher={sources.data}/>
      </TabPanel>
    </>
  );
};

export default AnalyticsPage;
