import React, {useState} from "react";

import {Box, CircularProgress, Modal, Snackbar} from "@mui/material";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";
import moment from "moment/moment";
import {Alert} from "../../utils/alert";
import Status from "../../components/Status/Status";
import { isEmpty, isObject } from "lodash";
import {useCustomModulesAPI} from "../../hooks/useCustomModulesAPI";
import {useStaticModulesAPI} from "../../hooks/useStaticModulesAPI";


const CustomModules = ({redirectIdCustomModule}) => {

    const {customModulesData: {data, isLoading, isError}, deleteCustomModules, editCustomModules} = useCustomModulesAPI()
    const {staticModulesData: {data: databases}} = useStaticModulesAPI()

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [alert, setAlert] = useState({
        isShow: false,
        type: "success",
        message: "Storage created successfully",
    });

    const handleCloseAlert = () => {setAlert(false)};

    const handleDeleteCustomModule = () => {
        handleCloseDeleteModal();
        deleteCustomModules.mutate(deleteId.id);
    };

    const handleOpenDeleteModal = (storage) => {
        setDeleteId({ id: storage._id, name: storage.name });
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleChangeActive = (id, active) => {
        editCustomModules.mutate({ data: { active: !active }, id: id });
    };

    const headers = [
        {
            lable: "Name",
            align: "left",
        },
        {
            lable: "Matching Database",
            align: "left",
        },
        {
            lable: "Attributes",
            align: "left",
        },
        {
            lable: "Conditions",
            align: "left",
        },
        {
            lable: "Status",
            align: "left",
        },
        {
            lable: "Created At",
            align: "left",
        },
        {
            lable: " ",
            align: "left",
        },
    ];

    const columns = [
        {
            accessor: (element) => element["name"],
            Cell: (event, storage) => {},
            align: "left",
        },
        {
            accessor: (element) => {
                if(databases?.length){
                    const database = databases.find(e => e._id===element.matchingDatabaseId);
                    if(database && database?.title){
                        return database?.title
                    }else {
                        return "-"
                    }
                } else {
                    return "-"
                }
            },
            Cell: (event, storage) => {},
            align: "left",
        },
        {
            accessor: (element) => isObject(element?.matchingConfig?.queryColumns) && !isEmpty(element?.matchingConfig?.queryColumns) ? Object.keys(element["matchingConfig"]?.queryColumns)?.length : '-',
            Cell: (event, storage) => {},
            align: "left",
        },
        {
            accessor: (element) => isObject(element?.matchingConfig?.conditions) && !isEmpty(element?.matchingConfig?.conditions) ? Object.keys(element?.matchingConfig?.conditions)?.length  : "-",
            Cell: (event, storage) => {},
            align: "left",
        },
        {
            accessor: (element) => <Status status={element.active} />,
            Cell: (event, customModule) => {
                event.stopPropagation();
                handleChangeActive(customModule._id, customModule.active);
            },
            align: "left",
        },
        {
            accessor: (element) => moment(element.createdAt).format('YYYY-MM-DD HH:MM'),
            Cell: (event, storage) => {},
            align: "left",
        },
    ];

    if (isLoading) {
        return (
            <Box className="wrapper-loading">
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return <h1>Something wrong</h1>;
    }

    return (
        <>
            <Box className="wrapper-source-list">
                <ListTable
                    headers={headers}
                    data={data}
                    redirectId={redirectIdCustomModule}
                    handleDelete={handleOpenDeleteModal}
                    columns={columns}
                />
            </Box>
            <Snackbar
                open={alert.isShow}
                autoHideDuration={3500}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alert.type}
                    sx={{ width: "100%" }}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
            <Modal
                open={showDeleteModal}
                onClose={handleCloseDeleteModal}
                sx={{ backdropFilter: "blur(14px)" }}
            >
                <DeleteModal
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    handleDelete={handleDeleteCustomModule}
                    text={"Custom Module"}
                    name={deleteId?.name}
                />
            </Modal>
        </>
    );
};

export default CustomModules;
