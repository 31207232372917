import React from 'react';
import {NavLink} from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import {useLogout} from "../../hooks/useLogout";

const SignOut = () => {

    const {logout} = useLogout()

    return (
        <div>
            <NavLink
                to={"/login"}
                exact
                className={"menu-item"}
                onClick={logout}
            >
                <div className="wrapper-icon"><ExitToAppIcon fontSize={"large"}/></div>
                <p className="menu-item-text">Sign Out</p>
            </NavLink>
        </div>
    );
};

export default SignOut;
