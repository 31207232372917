import { useState, useEffect, useRef } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TextField,
  Button,
  Typography,
  CircularProgress
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import style from "./styles.module.css";
import {
  getMasterDatabase,
  postMasterDatabaseSearch,
} from "../../services/DataService";
import { excludeMasterRecordsHeader } from "../../utils/constants";
import { dateFormater, dateFormatedFull } from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import {
  formatDate,
  formatDateTime,
  formatToDateTime,
} from "../../utils/functions";
import { useAppState } from "../../contexts/AppContext";
import useEnterKey from "../../hooks/useEnterKey";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { timeZoneChanger } from "../../utils/constants";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { filterRecord } from "../../utils/constants";


const MasterDatabasePage = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [cursor, setCursor] = useState(0);
  const [searchResults, setSearchResults] = useState(null);
  const [sortOrder, setSort] = useState("desc");
  const [inputData, setInputData] = useState("");
  const [total, setTotal] = useState(0);
  const [toggle, setToggle] = useState(false);
  const { handleOpenAlert } = useAppState();
  const [nameSort, setNameSort] = useState(filterRecord.OLD_RECORD);
  const [initialized, setInitialized] = useState(false);
  const [firstIdCursor, setFirstIdCursor] = useState([])
  const [lastIdCursor, setLastIdCursor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const today = new Date() 
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [dateTo, setDateTo] = useState(today);
  const [dateFrom, setDateFrom] = useState(yesterday);

  const fromFormatedDate = formatToDateTime(dateFrom);
  const toFormatedDate = formatToDateTime(dateTo);

  const queryClient = useQueryClient();

  const firstTime = useRef(true);

  const searchRecords = useMutation(postMasterDatabaseSearch, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Source search successfully");
      setSearchResults(data);
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("search");
    },
  });

  const handleChangeSort = () => {
    setSort((prevSortOrder) => (prevSortOrder === "desc" ? "asc" : "desc"));
    setNameSort((prevName) =>
      prevName === filterRecord.NEW_RECORD ? filterRecord.OLD_RECORD : filterRecord.NEW_RECORD
    );
  };

  const handleChangeSearch = (inputData) => {
    if (inputData !== "") {
      setToggle(true);
    }
    searchRecords.mutate({
      searchTerm: inputData,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      setCursor(lastIdCursor);
    } else {
      setCursor(firstIdCursor[newPage] - 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    if (sortOrder === "desc") {
      const totalPages = Math.ceil(total / newLimit);
      setCursor((totalPages - page - 1) * newLimit);
    } else {
      setCursor(page * newLimit);
    }
  };

  const redirectHash = (hash) => {
    window.open(`/master-records/${hash}`, '_blank');
  };

  const responseRaw = useQuery(
    [
      "getMasterDatabase",
      limit,
      cursor,
      sortOrder,
      fromFormatedDate,
      toFormatedDate,
    ],
    () =>
      getMasterDatabase(
        limit,
        cursor,
        sortOrder,
        fromFormatedDate,
        toFormatedDate
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const formatValue = (key, value) => {
    if (value === null) {
      return ''; 
    }
    if (key === "first_name" || key === "last_name") {
      return value[0].toUpperCase() + value.slice(1);
    }
    if (dateFormater.includes(key)) {
      return formatDate(value);
    }
    if (dateFormatedFull.includes(key)) {
      return formatDateTime(value);
    }
    return value;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputData(value);
    if (value === "") {
      setSearchResults(null);
      setToggle(false);
    }
  };

  useEffect(() => {
    if ( responseRaw?.data?.total) {
      setTotal(responseRaw.data.total);
      setInitialized(true);
    }
    setIsLoading(responseRaw.isLoading); // Встановлюємо isLoading з відповідного стану
  setIsError(responseRaw.isError);   
  }, [responseRaw?.data?.total, initialized,responseRaw.isLoading,responseRaw.isError]);

  useEffect(() => {
    if (responseRaw?.data?.result.length > 0) {
      setLastIdCursor(responseRaw?.data?.result[limit - 1]?.master_record?.id);
      setFirstIdCursor(prev => {
        const newCursors = [...prev];
        newCursors[page] = responseRaw?.data?.result[0]?.master_record?.id;
        return newCursors;
        
      });
    }
  }, [responseRaw?.data?.result, limit, page]);

  const enterKeyAction = () => handleChangeSearch(inputData);

  useEnterKey(enterKeyAction);

  const response = toggle ? searchResults : responseRaw?.data?.result;
  
  console.log(response,'data')
  
  useEffect(() => {
    setCursor(sortOrder === "desc" ? 999999999 : 0);

  }, [sortOrder]);

  if (isError) {
    return <h1>Something wrong</h1>;
  }
  
  // Перевіряємо стан isLoading і відображаємо спіннер поки дані завантажуються
  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Box className={style.container_database_page}>
        <Box className={style.container_header}>
          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <Box className={style.search_container}>
              <TextField
                placeholder={"Search by hash, email, phone"}
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
              />
              <Button
                onClick={() => handleChangeSearch(inputData)}
                sx={{ padding: 0 }}
                variant="search"
                startIcon={<SearchIcon sx={{ marginLeft: "10px" }} />}
              ></Button>
            </Box>
            <Box className={style.search_sort}>
              <span>Sort By: </span>
              <Button onClick={handleChangeSort} variant="sort">
                {nameSort}
              </Button>
            </Box>
          </Box>
          <Box className={style.box_date}>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Date From</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  placeholder="Date From"
                  value={
                    firstTime.current ? timeZoneChanger(dateFrom) : dateFrom
                  }
                  maxDate={timeZoneChanger(today)}
                  onChange={(newValue) => {
                    setDateFrom(newValue);
                    firstTime.current = false;
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>

            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Date To</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  placeholder="Date To"
                  value={firstTime.current ? timeZoneChanger(dateTo) : dateTo}
                  maxDate={timeZoneChanger(today)}
                  onChange={(newValue) => {
                    setDateTo(newValue);
                    firstTime.current = false;
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        {response && (
          <TableContainer variant="custom" component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ maxWidth: 1000 }}>
                <TableRow>
                  {response.length > 0 &&
                    Object.keys({
                      ...response[0],
                      ...response[0]?.master_record,
                    }).map(
                      (key) =>
                        !excludeMasterRecordsHeader.includes(key) && (
                          <TableCell variant="customStorage" key={key}>
                            {key.replace(/_/g, " ")}
                          </TableCell>
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {response.map((rowData) => {
                  const hasHistory =
                    rowData.master_record?.master_record_history?.length > 0;
                  const highlightStyle = hasHistory
                    ? { backgroundColor: "rgba(0,0,0,0.05)" }
                    : {};
                  return (
                    <TableRow
                      key={rowData.hash}
                      variant="custom"
                      style={highlightStyle}
                    >
                      {Object.entries({
                        ...rowData,
                        ...rowData.master_record,
                      }).map(([key, value]) => {
                        if (!excludeMasterRecordsHeader.includes(key)) {
                          return (
                            <TableCell
                              variant="custom-body"
                              key={key}
                              onClick={() => redirectHash(rowData.hash)}
                            >
                              {formatValue(key, value)}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {toggle && !response && <div>not found data</div>}
      </Box>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={toggle ? 1 : total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MasterDatabasePage;
