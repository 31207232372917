import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "../../assets/DeleteSource.svg";
import EditIcon from "../../assets/EditSource.svg";
import ExplanationTimezone from "../../components/Explanation/ExplanationConfig/ExplanationTimezone";

const ActionListTable = ({
  headers,
  data,
  handleDelete,
  redirectId,
  columns,
  tabValue,
  handleModuleChange,
  selectedModule,
}) => {
  const [moduleValues, setModuleValues] = useState([]);

  useEffect(() => {
    if (!moduleValues.length) {
      const uniqueModules = Array.from(
        new Set(data?.map((item) => item.module) || [])
      ).filter((module) => module && module.trim() !== "");
      setModuleValues(uniqueModules);
    }
  }, [data, moduleValues.length]);

  return (
    <TableContainer variant="custom" sx={{ minWidth: 650 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                key={i}
                variant="customBold"
                align={i === 0 ? "" : header.align}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {header.lable !== "Static Module" || tabValue !== 0 ? (
                    header.lable
                  ) : (
                    <Select
                      sx={{
                        marginLeft: -2,
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                      value={selectedModule}
                      defaultValue={header.lable}
                      onChange={handleModuleChange}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <Typography variant="staticModule">
                          {header.lable}
                        </Typography>
                      </MenuItem>
                      {moduleValues.map((module, index) => (
                        <MenuItem key={index} value={module}>
                          <Typography variant="staticModule">
                            {(module.replace(/_/g, " "))}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {header.icon && (
                    <Tooltip
                      title={<ExplanationTimezone />}
                      placement={"top"}
                      arrow
                    >
                      <InfoOutlinedIcon
                        sx={{ ml: 1 }}
                        color={"warning"}
                        fontSize={"small"}
                      />
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((element, i) => (
            <TableRow
              key={element._id}
              onClick={() => redirectId && redirectId(element)}
              variant="custom"
            >
              {columns.map((e, i) => (
                <TableCell
                  key={i}
                  variant={
                    typeof e.accessor(element) === "string" ? "custom" : ""
                  }
                  align={i === 0 ? "" : e.align}
                  onClick={(event) => e.Cell && e.Cell(event, element)}
                >
                  {e.accessor(element)}
                </TableCell>
              ))}
              {(handleDelete || redirectId) && (
                <TableCell align="center">
                  {redirectId && (
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectId(element);
                      }}
                    >
                      <img src={EditIcon} alt="" />
                    </IconButton>
                  )}
                  {handleDelete && (
                    <IconButton
                      aria-label="delete"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(element);
                      }}
                    >
                      <img src={DeleteIcon} alt="" />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActionListTable;
